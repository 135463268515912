import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import {
  getReport,
  getTransactionReportSearchData,
} from './../../stores/services/report.service';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import { getFunds } from './../../stores/services/funds.service';
import html2pdf from 'html2pdf.js';
import { txnDropDown , getReuiredFieldForDropDown} from './fields';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {isDateValid, isNumber, numberWithCommas} from '../../utils/customFunction';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
// import { CollisionsOverlap } from 'tsparticles/Options/Classes/Particles/Collisions/CollisionsOverlap';
import ReportHeader from './report-header';
import { sortAMCS } from '../../utils/customFunction';

const TransactionReportScreen = (props: any) => {

  const pdfExportComponent = React.useRef<PDFExport>(null);

  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [reportTxn, setReportTxn] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [fields, setFields] = useState({
    amc_code:'',
    fund_code:'',
    from_date: moment(date).format('YYYY-MM-DD'),
    to_date: moment(date).format('YYYY-MM-DD'),
    txn_type: '',
    status: '',
  }); 

  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [txn_type, setTxnType] = useState('');
  const [status, setStatus] = useState('');
  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');

  const [totalNetAmount, setTotalNetAmount] = useState('');
  const [totalGrossAmount, setTotalGrossAmount] = useState('');
  const [totalSaleLoad, setTotalSaleLoad] = useState('');
  const [totalRedemptionLoad, setTotalRedemptionLoad] = useState('');
  const [totalCGT, setTotalCGT] = useState('');
  const [totalUnits, setTotalUnits] = useState('');
  const [totalCurrentHolding, setTotalCurrentHolding] = useState('');
  const [totalZakatAmount, setTotalZakatAmount] = useState('');
  const [totalWHT_II, setTotalWH_IT] = useState('');
  const [totalWHT_SST, setTotalWH_SST] = useState('');
  const [totalTax_SST, setTotalTax_SST] = useState('');
  const [totalTotalAmount, setTotalAmount] = useState('');

  const [totalNetRightShareVolume, setNetRightShareVolume] = useState('');
  const [totalTaxITRightShareAmount, setTotalTaxITRightShareAmount] = useState('');
  const [totalRightShareGrossVolume, setTotalRightShareGrossVolume] = useState('');
  const [totalTotatNetBonusVolume, setTotalNetBonusVolume] = useState('');
  const [totalTotalTaxItBonusAmount, setTotalTaxItBonusAmount] = useState('');

  // const [dropdownList, setDropdownList] = useState<any>(txnDropDown);
  const [dropdownList, setDropdownList] = useState<any>([]);

  const formatData = (val) => {
    let words = val.split('_');
    return `${words[0]} ${words[1] ? words[1] : ''}`;
  };

  React.useEffect(()=>{
    const getAllAMC=async()=>{
      try{
     const amcResponse = await getAmc(email, '', '', '');
     let sortAMCSByName  = await sortAMCS(amcResponse.data.data);
     setAllAmc(sortAMCSByName);
      }catch(err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
      getAllAMC();
  },[])

  const [allFunds, setAllFunds] = useState<any>([]);
  // let [allDropdownColumns, setAllDropdownColumns] = useState<any>(txnDropDown);
  let [allDropdownColumns, setAllDropdownColumns] = useState<any>([]);
  let [headerColumns, setHeaderCoumns] = useState<any>([]);
  const [fundLoading, setFundLoading] = useState<boolean>(false);

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

    const renderAmcDropdown = () => {
      return allAmc.map((item: any, index: number) => {
      return (
          <option key={index} value={item.amc_code.replace('AMC_','')}>
          {item.name}-{item.amc_code.replace('AMC_','')}
          </option>
      );
      });
  };

      const getFunds=async(code:any)=>{
        try{
          allFunds.length=0;
          setAllFunds(allFunds);
            const response = await getFundByAmc(email, code);
            setAllFunds(response.data.data);
        }catch(err: any) {
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message)
          }

        }
      }

      const renderFundsDropDown=()=>{
        return allFunds.map((item: any, index: number) => {
            return (
                <option key={index} value={item.symbol_code.replace('FUND_','')}>
                {item.fund_name} - {item.symbol_code.replace('FUND_','')}
                </option>
            );
      });
      }

  const renderTxnTypeDropdown = () => {
    return txnTypes.map((item: any, index: number) => {
      return (
        <option key={index} value={item.api_name}>
          {item.dis_name}
        </option>
      );
    });
  };

  const renderDropdownValues = (selectedCol, transaction) => {
    let res = transaction[selectedCol.value];
    if (selectedCol.label.includes('date') || selectedCol.label.includes('Date')) {
      res = isDateValid(moment(transaction[selectedCol.value]).format('YYYY-MM-DD'));
    }
    if(selectedCol.value === 'gross_amount' || selectedCol.value === 'net_amount' || selectedCol.value === 'sale_load' || selectedCol.value === 'units' 
    || selectedCol.value==='refund_of_capital' || selectedCol.value==='current_holding' || selectedCol.value==='total_holding' || selectedCol.value==='remain_holding' || selectedCol.value === 'balance' || selectedCol.value==='face_value' ||
     selectedCol.value==='applicable_offer_price' || selectedCol.value==='offer_price' || selectedCol.value === 'redemption_load' || selectedCol.value === 'tax_cgt' || selectedCol.value ==='tax_wht_it'
     || selectedCol.value=='zakat_amount' || selectedCol.value==='joint_holder_income_tax' || selectedCol.value==='income_tax' || selectedCol.val==='dividend_rate' || selectedCol.value==='tax_sst'
     || selectedCol.value==='total_charges' || selectedCol.value==='tax_wht_it' || selectedCol.value==='tax_wht_sst' || selectedCol.value==='bonus_gross_volume' || selectedCol.value==='tax_it_bonus_amount' || selectedCol.value==='net_bonus_volume'
     || selectedCol.value==='right_share_gross_volume' || selectedCol.value==='tax_it_right_share_amount' || selectedCol.value==='net_right_share_volume'){
      res = numberWithCommas(transaction[selectedCol.value] || '0')
    }
    // if (selectedCol.value === 'txnr_type') {
    //   res = CapTxnType(transaction[selectedCol.value]);
    // }
    return res;
  };


  //code for search data for Reports
  const searchData = async () => {
    setLoading(true);
    try {
      const searchResponse = await getTransactionReportSearchData(email, fields);
      setResAmc(fields['amc_code']);
      setResFund(fields['fund_code']);
      setFromDate(fields['from_date']);
      setToDate(fields['to_date']);
      setTxnType(fields['txn_type']);
      setStatus(fields['status']);
      if(fields['txn_type']==='debtmarketinvestment'){
        let TransactionsBucket : any = []
        searchResponse.data.data.forEach((transaction:any, index:number)=>{
          let securities = JSON.parse(transaction.securities);
          
          securities.forEach(security => {
            let transactionToReturn = {
              ...transaction , issue_date : security.issue_date , security_type : security.security_type
            }
            TransactionsBucket.push(transactionToReturn)
          }) 
         
        });
        setReportTxn(TransactionsBucket);
      }else if(fields['txn_type']==='transferofunits'){
        let TransactionsBucket : any = []
        searchResponse.data.data.forEach((transaction:any, index:number)=>{
          let transfrees = JSON.parse(transaction.transfrees);
          let units = 0;
          transfrees.forEach(item => {
            let transactionToReturn = {
              ...transaction , transfrees_units :isNumber(item.transferees_unit_transfer),transfrees_folio_no: item.folio_no, transfrees_name:item.name
            }
            TransactionsBucket.push(transactionToReturn)
          }) 
         
        });
        setReportTxn(TransactionsBucket);
      }else{
         setReportTxn(searchResponse.data.data);
      } 
      let temp = Object.keys(searchResponse.data.data[0]).map((val) => {
        return { label: formatData(val), value: val };
      });
      // setAllDropdownColumns(temp);
      setDropdownList(headerColumns);
      setAllDropdownColumns(headerColumns);

      let data:any=[];
      let netamount=0, grossamount=0, saleload=0, cgt=0, redemptionload=0, units=0, current_holding=0, tax_wht_it=0,zakat_amount=0,tax_wht_sst=0, tax_sst=0, totalAmount=0, net_right_share_volume=0,
      tax_it_right_share_amount=0, right_share_gross_volume=0, tax_it_bonus_amount=0, net_bonus_volume=0;
      data=searchResponse.data.data;
      if(fields['txn_type']==='saleofunit' || fields['txn_type']==='conversionin'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        saleload+=parseFloat(item.sale_load || '0');
        units+=parseFloat(item.units || '0');
        current_holding+=parseFloat(item.current_holding || '0');
      });
    }else if(fields['txn_type']==='redemptionofunits' || fields['txn_type']==='conversionout'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        // saleload+=parseFloat(item.sale_load || '0');
        cgt+=parseFloat(item.tax_cgt || '0');
        redemptionload+=parseFloat(item.redemption_load || '0');
        units+=parseFloat(item.units || '0');
        current_holding+=parseFloat(item.current_holding || '0');
      });
    }else if(fields['txn_type']==='inflow' || fields['txn_type']==='outflow' || fields['txn_type']==='fundtransfer' || fields['txn_type']==='bankprofit' 
    || fields['txn_type']==='cashmargin' || fields['txn_type']==='marginreturn'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
      });
    }else if(fields['txn_type']==='funddividendpayout'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        units+=parseFloat(item.units || '0');
        current_holding+=parseFloat(item.current_holding || '0');
        tax_wht_it+=parseFloat(item.tax_wht_it || '0');
        zakat_amount+=parseFloat(item.zakat_amount || '0');
      });
    }else if(fields['txn_type']==='debtmarketinvestment'){
      data.map((item:any, index:number)=>{
        grossamount+=parseFloat(item.gross_amount || '0');
      });
    }else if(fields['txn_type']==='equityinvestment'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        tax_sst+=parseFloat(item.tax_sst || '0');
      });
    }else if(fields['txn_type']==='investmentinfund'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(isNumber(item.net_amount));
        grossamount+=parseFloat(isNumber(item.gross_amount));
      });
    }else if(fields['txn_type']==='cashdividend'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        tax_wht_it+=parseFloat(item.tax_wht_it || '0');
        net_right_share_volume+=parseFloat(item.net_right_share_volume || '0');
        tax_it_right_share_amount+=parseFloat(item.tax_it_right_share_amount || '0');
        right_share_gross_volume+=parseFloat(item.right_share_gross_volume || '0');
        net_bonus_volume+=parseFloat(item.net_bonus_volume || '0');
        tax_it_bonus_amount+=parseFloat(item.tax_it_bonus_amount || '0');
      });
    }else if(fields['txn_type']==='advisoryfee' || fields['txn_type']==='auditorsfee' || fields['txn_type']==='bankcharges' || fields['txn_type']==='managementfee' || fields['txn_type']==='trusteefee'
    || fields['txn_type']==='custodyfee' || fields['txn_type']==='shariahadvisoryfee' || fields['txn_type']==='cdcfee' || fields['txn_type']==='listingfee' || fields['txn_type']==='printingfee'
    || fields['txn_type']==='professionalfee' || fields['txn_type']==='ratingfee' || fields['txn_type']==='taxpayment' || fields['txn_type']==='secpfee' || fields['txn_type']==='brokagefee'
    || fields['txn_type']==='otherpayment'){
      data.map((item:any, index:number)=>{
        netamount+=parseFloat(item.net_amount || '0');
        grossamount+=parseFloat(item.gross_amount || '0');
        tax_sst+=parseFloat(item.tax_sst || '0');
        totalAmount+=parseFloat(item.total_charges || '0');
        tax_wht_it+=parseFloat(item.tax_wht_it || '0');
        tax_wht_sst+=parseFloat(item.tax_wht_sst || '0');
      });
    }
      setTotalNetAmount(netamount.toFixed(2));
      setTotalGrossAmount(grossamount.toFixed(2));
      setTotalSaleLoad(saleload.toFixed(2));
      setTotalRedemptionLoad(redemptionload.toFixed(2));
      setTotalCGT(cgt.toFixed(2));
      setTotalUnits(units.toFixed(4));
      setTotalCurrentHolding(current_holding.toFixed(4));
      setTotalWH_IT(tax_wht_it.toFixed(2));
      setTotalZakatAmount(zakat_amount.toFixed(2));
      setTotalTax_SST(tax_sst.toFixed(2));
      setTotalWH_IT(tax_wht_it.toFixed(2));
      setTotalWH_SST(tax_wht_sst.toFixed(2));
      setTotalAmount(totalAmount.toFixed(2));
      setNetRightShareVolume(net_right_share_volume.toFixed(2));
      setTotalTaxITRightShareAmount(tax_it_right_share_amount.toFixed(2));
      setTotalRightShareGrossVolume(right_share_gross_volume.toFixed(2));
      setTotalNetBonusVolume(net_bonus_volume.toFixed(2));
      setTotalTaxItBonusAmount(tax_it_bonus_amount.toFixed(2));
      setLoading(false);
    } catch (err:any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      setReportTxn([]);
      setLoading(false);
    }
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Transaction Report</h4>
            </div>
            <div className="card-body">
            <Row>
            <Col md="4">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                      setFields({
                        ...fields,
                        amc_code: e.target.value,
                      });
                        getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="4">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                        setFields({
                          ...fields,
                          fund_code: e.target.value,
                        });
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="4">
                <div className="form-group  ">
                  <label>Transaction Type</label>
                  <select
                    className="form-control"
                    value={fields['txn_type']}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        txn_type: e.target.value,
                      });
                      let fieldData = getReuiredFieldForDropDown(e.target.value);
                      setHeaderCoumns(fieldData);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select Type
                    </option>
                    {renderTxnTypeDropdown()}
                  </select>
                </div>
              </Col>
              <Col md="4">
                <div className="form-group  ">
                  <label>Transaction Status</label>
                  <select
                    className="form-control"
                    value={fields['status']}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select type
                    </option>
                    <option value="COMPLIANCE">Compliance</option>
                    <option value="INREVIEW">In Review</option>
                    <option value="SIGNATORY-A">Signatory A</option>
                    <option value="SIGNATORY-B">Signatory B</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="REJECTED">Rejected</option>
                  </select>
                </div>
              </Col>
            
              <Col md="4">
                <div className="form-group  ">
                  <label>Date From</label>
                  <input
                    type="date"
                    // defaultValue={moment(date).format('DD-MM-YYYY')}
                    value={fields['from_date']}
                    className="form-control"
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        from_date: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group  ">
                  <label>Date To</label>
                  <input
                    type="date"
                    // defaultValue={moment(date).format('DD-MM-YYYY')}
                    value={fields['to_date']}
                    className="form-control"
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        to_date: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            {/* End */}
          </div>
          </div>
          {reportTxn.length > 0 && (
          <Row>
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="mt-3 mr-3">
                        {reportTxn.length > 0 ? (
                          <MultiSelect
                            options={allDropdownColumns}
                            value={dropdownList}
                            onChange={setDropdownList}
                            labelledBy={'Select Columns'}
                            className="multi-select"
                            overrideStrings={{
                              selectAll: 'Select all Columns',
                              selectSomeItems: 'Select Columns',
                            }}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Col>
                  </Row>
          )}
          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-2"
              style={{ background: 'white', color: 'black' }}
            >
                <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} landscape={true} fileName={"Transaction-Report-"+date+".pdf"} ref={pdfExportComponent}>
              {reportTxn.length > 0 ? (
                <>
                <ReportHeader title="Transaction Report"/>
                 {/* <div className="title-row m-4 d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                 <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Transaction Report</h3>
                  </div> */}
                  <hr/>
                  <Row>

                  <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="6">
                          <span>{resAmc}</span>
                        </Col>
                      </Row>
                    </Col>

                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span style={{ fontWeight: 'bold' }}>
                            Fund:
                          </span>
                        </Col>
                        <Col md="6">
                          <span>{resFund}</span>
                        </Col>
                      </Row>
                    </Col>

                    {txn_type !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Transaction Type:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{CapTxnType(txn_type)}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {from_date!== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              From Date:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{moment(from_date).format('YYYY-MM-DD')}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {to_date !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span>{moment(to_date).format('YYYY-MM-DD')==='Invalid date' ? to_date : moment(to_date).format('YYYY-MM-DD')}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {status !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>Status:</span>
                          </Col>
                          <Col md="6">
                            <span>{status}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}

                <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span style={{ fontWeight: 'bold' }}>
                            Report Date:
                          </span>
                        </Col>
                        <Col md="6">
                          <span>{moment(date).format('YYYY-MM-DD')}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                          {dropdownList.map((item, index) => {
                            return (
                              <th key={index}>
                                <span
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {item.label.toString()}
                                </span>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                        {reportTxn.map((transaction: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                {dropdownList.map((item, i) => {
                                  return (
                                    <td key={i}>
                                      {renderDropdownValues(item, transaction)}
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                       
                      </tbody>
                      <tfoot>
                      <tr>
                        {/* <th> Total </th> */}
                        {dropdownList.map((item:any)=>{
                          if(item.value==='net_amount'){
                            return <td>{numberWithCommas(totalNetAmount)} </td>
                          }else if(item.value==='gross_amount'){
                            return <td>{numberWithCommas(totalGrossAmount)}</td>
                          }else if(item.value==='sale_load'){
                            return <td>{numberWithCommas(totalSaleLoad)}</td>
                          }else if(item.value==='tax_cgt'){
                            return <td>{numberWithCommas(totalCGT)}</td>
                          }else if(item.value==='redemption_load'){
                            return <td>{numberWithCommas(totalRedemptionLoad)}</td>
                          }else if(item.value==='units'){
                            return <td>{numberWithCommas(totalUnits)}</td>
                          }else if(item.value==='current_holding'){
                            return <td>{numberWithCommas(totalCurrentHolding)}</td>
                          }else if(item.value==='zakat_amount'){
                            return <td>{numberWithCommas(totalZakatAmount)}</td>
                          }else if(item.value==='tax_wht_it'){
                            return <td>{numberWithCommas(totalWHT_II)}</td>
                          }else if(item.value==='tax_wht_sst'){
                            return <td>{numberWithCommas(totalWHT_SST)}</td>
                          }else if(item.value==='tax_sst'){
                            return <td>{numberWithCommas(totalTax_SST)}</td>
                          }else if(item.value==='total_charges'){
                            return <td>{numberWithCommas(totalTotalAmount)}</td>
                          }else if(item.value==='tax_it_bonus_amount'){
                            return <td>{numberWithCommas(totalTotalTaxItBonusAmount)}</td>
                          }else if(item.value==='net_bonus_volume'){
                            return <td>{numberWithCommas(totalTotatNetBonusVolume)}</td>
                          }else if(item.value==='right_share_gross_volume'){
                            return <td>{numberWithCommas(totalRightShareGrossVolume)}</td>
                          }else if(item.value==='tax_it_right_share_amount'){
                            return <td>{numberWithCommas(totalTaxITRightShareAmount)}</td>
                          }else if(item.value==='net_right_share_volume'){
                            return <td>{numberWithCommas(totalNetRightShareVolume)}</td>
                          }else{
                            return <td> </td>
                          }
                        })}
                          </tr>
                        </tfoot>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
                  </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
                // printReport();
              }}
            >
              Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default TransactionReportScreen;
