import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from '../../../utils/customFunction';

const ViewSecurity = () => {
  const history = useHistory();	
  const [securityType, setSecurityType] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('')
  const [issueDate, setIssueDate] = useState('');
  const [maturityDate, setMaturityDate] = useState('')
  const [faceValue, setFaceValue] = useState('');
  const [price, setPrice] = useState('')

  const [file, setFile] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('securityObj') || '');
    setSecurityType(obj.security_type);
    setCode(obj.code.replace('SECURITY_',''));
    setName(obj.name);
    setIssueDate(obj.issue_date);
    setMaturityDate(obj.maturity_date);
    setFaceValue(obj.face_value);
    setPrice(obj.price);


    setFile(obj.companies);
  }, []);
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <div className="card mt-4">
          <div className="">
            <div className="card-header">
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/securities')
                  }}></i>View - Investment Securities</h4>
            <div className="title-row">
              <Link
                to="/admin/securities"
                className="t-3 ml-auto btn btn-primary btn-sm"
                replace
              >
                <i className="fa fa-eye mr-2"></i> View All
              </Link>
            </div>
            </div>
            <div className="card-body">
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Security Type</label>
                    <div className="form-control">{securityType}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Symbol</label>
                    <div className="form-control">{code}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Name</label>
                    <div className="form-control">{name}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Issue Date</label>
                    <div className="form-control">{moment(issueDate).format('DD-MM-YYYY')}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Maturity Date</label>
                    <div className="form-control">{moment(maturityDate).format('DD-MM-YYYY')}</div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Face Value</label>
                    <div className="form-control">{numberWithCommas(faceValue)}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Price</label>
                    <div className="form-control">{numberWithCommas(price)}</div>
                  </div>
                </Col>
              </Row>
            </div>
          
          </div>
        </div>
      </Container>
    
 </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewSecurity;
