import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { editFundSecurity, editSecurity } from '../../../stores/services/security.service';
import { useHistory, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";
import { getFunds } from '../../../stores/services/funds.service';

const codeRegex = new RegExp('^[A-Z]{1,5}$');
const EditFundSecurity = () => {
    const history = useHistory();
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [fund, setFund] = useState('');
    const [allFunds, setAllFunds] = useState<any>([]);


    // const [fundError, setFundError] = useState('');
    // const [codeError, setCodeError] = useState('');
    const [nameError, setNameError] = useState('');
    const [priceError, setPriceError] = useState('');
    // const [flag, setFlag] = useState(false);


    // useEffect(() => {
    //     const getAllfunds = async () => {
    //         try {
    //             setAllFunds([]);
    //             const response = await getFunds(sessionStorage.getItem('email') || '');
    //             let temp: any = '';
    //             temp = { fund_name: 'Other' };
    //             response.data.data.unshift(temp);
    //             setAllFunds(response.data.data);
    //         } catch (err: any) {
    //             if (err.response !== undefined) {
    //                 toast.error(err.response.data.message);
    //             } else {
    //                 toast.error(err.message)
    //             }
    //         }
    //     }
    //     getAllfunds();
    // }, [])

    const [Loading, setLoading] = useState(false);
    const updateSecurity = async () => {
        // if (!securityType) {	
        //   setSecurityTypeError('Required')	
        //   return;	
        // }	
        if (!name) {
            setNameError('Required')
            return;
        }
        setLoading(true)
        await editFundSecurity(sessionStorage.getItem('email') || '', name, code, price)
            .then((response) => {
                // toast.success(response.data.message);	
                // setTimeout(function () {	
                //   history.replace('/admin/securities');	
                // }, 3000);	
                if (response.data.status == 200) {
                    toast.success(response.data.message);
                    setTimeout(function () {
                        history.push('/admin/securities', { params: '3' })
                    }, 3000);
                } else if (response.data.status == 500) {
                    toast.error(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((err: any) => {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message)
                }
            })
        setLoading(false)
    }

    React.useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem('fundSecurityObj') || '');
        setFund(`${obj.code}-${obj.name}`)
        setCode(obj.code);
        setName(obj.name);
        setPrice(obj.price);

    }, []);

    const decimalValue = (value, len) => {
        let startingValue = value.split('.')[0];
        let decimalVal = value.split('.')[1];
        let temp = '';
        if (decimalVal !== undefined) {
            let decimalString = decimalVal.toString();
            if (decimalString.length <= len) {
                temp = startingValue + '.' + decimalString;
            } else {
                temp = startingValue + '.' + decimalString.substr(0, len);
            }
        } else {
            temp = startingValue;
        }
        return temp;
    };

    // const renderFundsDropdown = () => {
    //     return allFunds.map((item: any, index: number) => {
    //         return (
    //             <>
    //                 {
    //                     index === 0 ? (
    //                         <option key={index} value={item.fund_name}>
    //                             {item.fund_name}
    //                         </option>
    //                     ) : (
    //                         <option key={index} value={item.symbol_code.replace('FUND_', '')}>
    //                             {item.symbol_code.replace('FUND_', '')}-{item.fund_name}
    //                         </option>
    //                     )
    //                 }

    //             </>
    //         );
    //     });
    // };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card ">
                                <div className="">
                                    <div className="card-header title-row">
                                        <h4 className="card-title">
                                            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                                                history.push('/admin/securities', { params: '3' })
                                            }}></i>Edit - Investment Securities</h4>
                                        <button type="button" className="t-3 ml-auto btn btn-primary btn-sm" onClick={() => {
                                            history.push('/admin/securities', { params: '3' })
                                        }}><i className="fa fa-eye mr-2"></i>View All</button>
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Select Fund</label>
                                                    <div>
                                                        <div className="">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className={`form-control  w-100`}
                                                                value={fund}
                                                                onChange={(e) => {
                                                                    setFund(e.target.value);
                                                                    // setFError('');
                                                                }}
                                                                readOnly
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Code: <span className="color-amber">*</span></label>
                                                    <div>
                                                        <div className="">
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className={`form-control  w-100`}
                                                                value={code}
                                                                onChange={(e) => {
                                                                    setCode(e.target.value);
                                                                }}
                                                                readOnly
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Name: <span className="color-amber">*</span></label>
                                                    <div className="">
                                                        <input
                                                            className={`form-control  w-100 ${nameError ? ' required-border' : ''}`}
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                                setNameError('');
                                                            }} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <div>
                                                        <div className="">
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                inputMode="numeric"
                                                                className={`form-control text-right  w-100 ${priceError ? ' required-border' : ''}`}
                                                                value={decimalValue(price, 4)}
                                                                onValueChange={(e) => {
                                                                    setPrice(e.value);
                                                                    setPriceError('');
                                                                }} />


                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>


                                        </Row>
                                        <div className="">
                                            <button className="btn btn-primary" onClick={updateSecurity} disabled={Boolean(Loading)}>
                                                {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="login-txt"> Loading...</span></> : <span>Update</span>}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default EditFundSecurity;
