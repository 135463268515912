export const unitholderDropdow = [
  { label: 'IBAN', value: 'account_no' },
  { label: 'Bank account title', value: 'account_title' },
  { label: 'amc code', value: 'amc_code' },
  { label: 'CNIC ', value: 'cnic' },
  { label: 'bank name', value: 'bank_name' },
  { label: 'branch name', value: 'branch_name' },
  { label: 'address ', value: 'address' },
  { label: 'city ', value: 'city' },
  { label: 'created at', value: 'created_at' },
  { label: 'folio no', value: 'folio_no' },
  { label: 'Father / Spouse Name', value: 'father_spouse' },
  { label: 'Zakat Status', value: 'zakat_exempt' },
  { label: 'Tax Exemption Status', value: 'tax_exempt' },
  { label: 'TType', value: 'type' },
  { label: 'name ', value: 'name' },
  { label: 'status', value: 'status' },
  { label: 'Total Units', value: 'total_units' },
];

export const bankAccountDropdown = [
  { label: 'DCC Transaction Id', value: 'txn_id' },
  { label: 'fund code', value: 'fund_code' },
  { label: 'bank ', value: 'bank' },
  { label: 'branch ', value: 'branch' },
  { label: 'account number', value: 'account_number' },
  { label: 'Transaction Date', value: 'created_at' },
  { label: 'Transaction Mode', value: 'mode_of_payment' },
  { label: 'Credit Amount ', value: 'cr_amount' },
  { label: 'Debit Amount ', value: 'dr_amount' },
  { label: 'gross amount', value: 'gross_amount' },
  { label: 'net amount', value: 'net_amount' },
  { label: 'Balance', value: 'balance' },
];
export const bankAccountDropdownReport = [
  { label: 'DCC Transaction Id', value: 'txn_id' },
  { label: 'Transaction Type', value: 'txnr_type' },
  { label: 'fund code', value: 'fund_code' },
  { label: 'bank ', value: 'bank' },
  { label: 'branch ', value: 'branch' },
  { label: 'account number', value: 'account_number' },
  { label: 'Transaction Date', value: 'created_at' },
  { label: 'Transaction Mode', value: 'mode_of_payment' },
  { label: 'Debit Amount ', value: 'dr_amount' },
  { label: 'Credit Amount ', value: 'cr_amount' },
  // { label: 'gross amount', value: 'gross_amount' },
  // { label: 'net amount', value: 'net_amount' },
  { label: 'Balance', value: 'balance' },
];

export const txnDropDown = [
  { label: 'TXN ID', value: 'txn_id' },
  { label: 'account number', value: 'account_number' },
  { label: 'account title', value: 'account_title' },
  { label: 'amc code', value: 'amc_code' },
  { label: 'assign time', value: 'assign_time' },
  { label: 'bank ', value: 'bank' },
  { label: 'branch ', value: 'branch' },
  { label: 'Credit Amount ', value: 'cr_amount' },
  { label: 'Debit Amount ', value: 'dr_amount' },
  { label: 'counter account', value: 'counter_account_number' },
  { label: 'counter account Title', value: 'counter_account_title' },
  { label: 'counter account Title', value: 'counter_account_type' },
  { label: 'counter bank', value: 'counter_bank' },
  { label: 'counter branch', value: 'counter_branch' },
  { label: 'counter type', value: 'counter_type' },
  { label: 'created at', value: 'created_at' },
  { label: 'created by', value: 'created_by' },
  { label: 'credit date', value: 'credit_date' },
  { label: 'execution date', value: 'execution_date' },
  { label: 'fund account', value: 'fund_account' },
  { label: 'fund code', value: 'fund_code' },
  { label: 'gross amount', value: 'gross_amount' },
  { label: 'mode of', value: 'mode_of_payment' },
  { label: 'net amount', value: 'net_amount' },
  { label: 'payment type', value: 'payment_type' },
  { label: 'txn id', value: 'txn_id' },
  { label: 'txn status', value: 'txn_status' },
  { label: 'txnr type', value: 'txnr_type' },
  { label: 'assigned to', value: 'assigned_to' },
  { label: 'conversion date', value: 'conversion_date' },
  { label: 'executed date', value: 'executed_date' },
  { label: 'issue date', value: 'issue_date' },
  { label: 'maturity date', value: 'maturity_date' },
  { label: 'realized date', value: 'realized_date' },
  { label: 'sale date', value: 'sale_date' },
  { label: 'settlement by', value: 'settlement_by' },
  { label: 'settlement date', value: 'settlement_date' },
  { label: 'total charges', value: 'total_charges' },
  { label: 'current holding', value: 'current_holding' },
  { label: 'folio no', value: 'folio_no' },
  { label: 'instrument date', value: 'instrument_date' },
  { label: 'instrument no', value: 'instrument_no' },
  { label: 'made by', value: 'made_by' },
  { label: 'NAV ', value: 'nav' },
  { label: 'redemption date', value: 'redemption_date' },
  { label: 'unitholder name', value: 'unitholder_name' },
  { label: 'units ', value: 'units' },
  { label: 'tenor', value: 'tenor' },
  { label: 'issue date', value: 'issue_date' },
  { label: 'Face Value', value: 'face_value' },
  { label: 'yield', value: 'yield' },
  { label: 'price', value: 'price' },
  { label: 'last coupon date', value: 'last_coupon_date' },
  { label: 'next coupon date', value: 'next_coupon_date' },
];

export const allTxnJSon = [
  {
    type: 'saleofunit',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'folio no', value: 'folio_no' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'unitholder', value: 'unitholder_name' },
      { label: 'Sale Date', value: 'sale_date' },
      { label: 'Fund Acc', value: 'fund_account' },
      { label: 'Fund A/C Title', value: 'account_title' },
      { label: 'Fund A/C No.', value: 'account_number' },
      { label: 'NAV ', value: 'nav' },
      { label: 'Offer Price', value: 'offer_price' },
      { label: 'Discount on Load', value: 'percentage_of_discount' },
      { label: 'Load Per Unit', value: 'load_per_unit' },
      { label: 'Applicable Offer Price', value: 'applicable_offer_price' },
      { label: 'Drawer bank', value: 'counter_bank' },
      // { label: 'Drawer branch', value: 'counter_branch' },
      { label: 'Drawer IBAN', value: 'counter_account_number' },
      { label: 'Received Amount', value: 'gross_amount' },
      { label: 'Sale Load', value: 'sale_load' },
      { label: 'Net Sale amount', value: 'net_amount' },
      { label: 'Units Sold', value: 'units' },
      { label: 'Current Holding', value: 'current_holding' },
      { label: 'Outstanding Units', value: 'total_holding' },
      { label: 'Created By', value: 'created_by' },
      // { label: 'Balance', value: 'balance' },
    ],
  },
  {
    type: 'redemptionofunits',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'folio no', value: 'folio_no' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'unitholder name', value: 'unitholder_name' },
      { label: 'Redemption Date', value: 'sale_date' },
      { label: 'Fund Acc', value: 'fund_account' },
      { label: 'Fund A/C Title', value: 'account_title' },
      { label: 'Fund A/C No.', value: 'account_number' },
      { label: 'NAV', value: 'nav' },
      { label: 'Redemption Price', value: 'offer_price' },
      { label: 'Discount on Load', value: 'percentage_of_discount' },
      { label: 'Load Per Unit', value: 'load_per_unit' },
      { label: 'Applicable Redemption Price', value: 'applicable_offer_price' },
      { label: 'Drawer bank', value: 'counter_bank' },
      // { label: 'Drawer branch', value: 'counter_branch' },
      { label: 'Drawer IBAN', value: 'counter_account_number' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Redemption Load', value: 'redemption_load' },
      { label: 'CGT', value: 'tax_cgt' },
      { label: 'Net Amount', value: 'net_amount' },
      { label: 'Units Redeem', value: 'units' },
      { label: 'Current Holding', value: 'current_holding' },
      { label: 'Outstanding Units', value: 'total_holding' },
      { label: 'Created By', value: 'created_by' },
      // { label: 'Balance', value: 'balance' },,
    ],
  },
  {
    type: 'conversionout',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'folio no', value: 'folio_no' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'unitholder name', value: 'unitholder_name' },
      { label: 'Redemption Date', value: 'sale_date' },
      { label: 'Fund Acc', value: 'fund_account' },
      { label: 'Fund A/C Title', value: 'account_title' },
      { label: 'Fund A/C No.', value: 'account_number' },
      { label: 'NAV ', value: 'nav' },
      { label: 'Redemption Price', value: 'offer_price' },
      { label: 'Discount on Load', value: 'percentage_of_discount' },
      { label: 'Load Per Unit', value: 'load_per_unit' },
      { label: 'Applicable Redemption Price', value: 'applicable_offer_price' },
      { label: 'Drawer bank', value: 'counter_bank' },
      // { label: 'Drawer branch', value: 'counter_branch' },
      { label: 'Drawer IBAN', value: 'counter_account_number' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Redemption Load', value: 'redemption_load' },
      { label: 'CGT', value: 'tax_cgt' },
      { label: 'Net Amount', value: 'net_amount' },
      { label: 'Units Redeem', value: 'units' },
      { label: 'Current Holding', value: 'current_holding' },
      { label: 'Outstanding Units', value: 'total_holding' },
      { label: 'Created By', value: 'created_by' },
      // { label: 'Balance', value: 'balance' },
      
    ],
  },
  {
    type: 'conversionin',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'folio no', value: 'folio_no' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'unitholder name', value: 'unitholder_name' },
      { label: 'Sale Date', value: 'sale_date' },
      { label: 'Fund Acc', value: 'fund_account' },
      { label: 'Fund A/C Title', value: 'account_title' },
      { label: 'Fund A/C No.', value: 'account_number' },
      { label: 'NAV ', value: 'nav' },
      { label: 'Offer Price', value: 'offer_price' },
      { label: 'Discount on Load', value: 'percentage_of_discount' },
      { label: 'Load Per Unit', value: 'load_per_unit' },
      { label: 'Applicable Offer Price', value: 'applicable_offer_price' },
      { label: 'Drawer bank', value: 'counter_bank' },
      // { label: 'Drawer branch', value: 'counter_branch' },
      { label: 'Drawer IBAN', value: 'counter_account_number' },
      { label: 'Received Amount', value: 'gross_amount' },
      { label: 'Sale Load', value: 'sale_load' },
      { label: 'Net Sale amount', value: 'net_amount' },
      { label: 'Units Sold', value: 'units' },
      { label: 'Current Holding', value: 'current_holding' },
      { label: 'Outstanding Units', value: 'total_holding' },
      { label: 'Created By', value: 'created_by' },
      // { label: 'Balance', value: 'balance' },
    ],
  },
  {
    type: 'unitconversion',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Amc unitholder folio no', value: 'folio_no' },
      { label: 'instruction date', value: 'instruction_date' },
      { label: 'From fund code', value: 'fund_code' },
      { label: 'Transaction Date', value: 'created_at' },
      { label: 'from fund bank ', value: 'bank' },
      { label: 'from fund branch ', value: 'branch' },
      { label: 'from fund IBAN', value: 'account_number' },
      { label: 'to fund bank', value: 'counter_bank' },
      { label: 'unitholder name', value: 'unitholder_name' },
      { label: 'to fund branch', value: 'counter_branch' },
      { label: 'to fund Account', value: 'counter_account_number' },
      { label: 'to fund A/C Title', value: 'counter_account_title' },
      { label: 'redemption date', value: 'redemption_date' },
      { label: 'Redemption Price', value: 'nav' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'NAV Date', value: 'created_at' },
      { label: 'Redemption Issued', value: 'units' },
      { label: 'total charges', value: 'total_charges' },
      { label: 'Net Payment amount', value: 'net_amount' },
      { label: 'Payment date', value: 'executed_date' },
      { label: 'instrument Type', value: 'instrument_type' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Units Issued', value: 'units' },
    ],
  },
  {
    type: 'fundtransfer',
    required: [
      { label: 'DCC Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'from fund bank ', value: 'bank' },
      { label: 'from fund branch ', value: 'branch' },
      { label: 'from fund IBAN', value: 'account_number' },
      { label: 'to bank', value: 'counter_bank' },
      { label: 'to branch', value: 'counter_branch' },
      { label: 'to fund Account', value: 'counter_account_number' },
      // { label: 'instrument date', value: 'instrument_date' },
      // { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Transfer amount', value: 'net_amount' },
    ],
  },
  {
    type: 'inflow',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'From bank', value: 'counter_bank' },
      { label: 'From branch', value: 'counter_branch' },
      { label: 'From fund Account', value: 'counter_account_number' },
      { label: 'To fund bank ', value: 'bank' },
      { label: 'To fund branch ', value: 'branch' },
      { label: 'To fund IBAN', value: 'account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Transfer amount', value: 'net_amount' },
    ],
  },
  {
    type: 'outflow',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'From fund bank ', value: 'bank' },
      { label: 'From fund branch ', value: 'branch' },
      { label: 'From fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To branch', value: 'counter_branch' },
      { label: 'To fund Account', value: 'counter_account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Transfer amount', value: 'net_amount' },
    ],
  },
  {
    type: 'funddividendpayout',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Folio No.', value: 'folio_no' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To branch', value: 'counter_branch' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Reinvest', value: 'reinvest' },
      { label: 'Refund Capital', value: 'refund_of_capital' },
      { label: 'Current Holding', value: 'current_holding' },
      { label: 'Outstanding Units', value: 'units' },
      { label: 'Dividend Rate', value: 'dividend_rate' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Income Tax', value: 'income_tax' },
      { label: 'Joint Holder Income Tax', value: 'joint_holder_income_tax' },
      { label: 'Zakat Amount', value: 'zakat_amount' },
      { label: 'WHT IT', value: 'tax_wht_it' },
      { label: 'Net amount', value: 'net_amount' },
    ],
  },
  {
    type: 'debtmarketinvestment',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Execution Date', value: 'execution_date' },
      { label: 'From/To bank ', value: 'bank' },
      { label: 'From/To branch ', value: 'branch' },
      { label: 'From/To IBAN', value: 'account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of Transaction', value: 'mode_of_payment' },
      { label: 'From/To bank', value: 'counter_bank' },
      { label: 'From/To Account', value: 'counter_account_number' },
      { label: 'From/To Account Title', value: 'counter_account_title' },
      { label: 'security type', value: 'security_type' },
      { label: 'issue date', value: 'issue_date' },
      { label: 'Settlement Amount', value: 'gross_amount' },
    ],
  },
  {
    type: 'bankprofit',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'equityinvestment',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'From bank ', value: 'bank' },
      { label: 'From branch ', value: 'branch' },
      { label: 'From fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To fund Account', value: 'counter_account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Brokerage Commission', value: 'broker_commission' },
      { label: 'SST on Commission', value: 'tax_sst' },
      { label: 'Net amount', value: 'net_amount' },
    ],
  },
  {
    type: 'cashdividend',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'To bank ', value: 'bank' },
      { label: 'To branch ', value: 'branch' },
      { label: 'To IBAN', value: 'account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Announcement Date', value: 'announcement_date' },
      { label: 'Volume', value: 'volume' },
      { label: 'Par Value', value: 'par_value' },
      { label: 'Dividend (%)', value: 'dividend_percentage' },
      { label: 'Dividend Credit Date', value: 'credit_date' },
      { label: 'Gross Amount (Rs.)', value: 'gross_amount' },
      { label: 'Tax on Dividend (%)', value: 'tax_on_dvidend' },
      { label: 'Tax Amount on Dividend (Rs.)', value: 'tax_wht_it' },
      { label: 'Net Amount (Rs.)', value: 'net_amount' },
      { label: 'Bonus (%)', value: 'bonus_percentage' },
      { label: 'Gross Bonus Share (No.)', value: 'bonus_gross_volume' },  
      { label: 'Tax on Bonus (%)', value: 'tax_on_bonus' },
      { label: 'Tax Bonus Share (No.)', value: 'tax_it_bonus_amount' },
      { label: 'Net Bonus Share (No.)', value: 'net_bonus_volume' },
      { label: 'Right Share (%)', value: 'right_share_percentage' },
      { label: 'Gross Right Share (No.)', value: 'right_share_gross_volume' },
      { label: 'Tax on Right (%)', value: 'tax_on_right' },
      { label: 'Tax on Right Share (No.)', value: 'tax_it_right_share_amount' },
      { label: 'Net Right Share (No.)', value: 'net_right_share_volume' },
    ],
  },
  {
    type: 'cashmargin',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'From fund bank ', value: 'bank' },
      { label: 'From fund branch ', value: 'branch' },
      { label: 'From fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To branch', value: 'counter_branch' },
      { label: 'To fund Account', value: 'counter_account_number' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Net Amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'marginreturn',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'To bank ', value: 'bank' },
      { label: 'To branch ', value: 'branch' },
      { label: 'To IBAN', value: 'account_number' },
      { label: 'From bank', value: 'counter_bank' },
      { label: 'From branch', value: 'counter_branch' },
      { label: 'From fund Account', value: 'counter_account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Net Amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'advisoryfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'auditorsfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'bankcharges',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'managementfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'trusteefee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'custodyfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'shariahadvisoryfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'cdcfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'listingfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'printingfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'professionalfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'ratingfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'taxpayment',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'secpfee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'brokagefee',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'otherpayment',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'AMC', value: 'amc_code' },
      { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To Account', value: 'counter_account_number' },
      { label: 'To Account Title', value: 'counter_account_title' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'SST', value: 'tax_sst' },
      { label: 'Total Amount', value: 'total_charges' },
      { label: 'WH IT', value: 'tax_wht_it' },
      { label: 'WH ST', value: 'tax_wht_sst' },
      { label: 'Net amount', value: 'net_amount' },
      { label: 'Detail', value: 'detail' },
    ],
  },
  {
    type: 'Payment',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'instruction date', value: 'instruction_date' },
      { label: 'Transaction type', value: 'txnr_type' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Transaction Date', value: 'created_at' },
      { label: 'Beneficiary bank', value: 'counter_bank' },
      { label: 'Beneficiary branch', value: 'counter_branch' },
      { label: 'Beneficiary Account', value: 'counter_account_number' },
      { label: 'Beneficiary Account Title', value: 'counter_account_title' },
      { label: 'gross amount', value: 'gross_amount' },
      { label: 'net amount', value: 'net_amount' },
      { label: 'total charges', value: 'total_charges' },
    ],
  },
  {
    type: 'Receipt',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'fund code', value: 'fund_code' },
      { label: 'instruction date', value: 'instruction_date' },
      { label: 'Transaction type', value: 'txnr_type' },
      { label: 'fund bank ', value: 'bank' },
      { label: 'fund branch ', value: 'branch' },
      { label: 'fund IBAN', value: 'account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'instrument amount', value: 'net_amount' },
      { label: 'Transaction Date', value: 'created_at' },
      { label: 'Drawer bank', value: 'counter_bank' },
      { label: 'Drawer branch', value: 'counter_branch' },
      { label: 'Drawer Account', value: 'counter_account_number' },
      { label: 'Drawer Account Title', value: 'counter_account_title' },
      { label: 'Received amount', value: 'gross_amount' },
      { label: 'total charges', value: 'total_charges' },
    ],
  },

  {
    type: 'moneymarketdetailreport',
    required: [
      { label: 'Transaction ID', value: 'txn_id' },
      // { label: 'AMC', value: 'amc_code' },
      // { label: 'Fund Name', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_date' },
      // { label: 'security type', value: 'security_type' },
      { label: 'investment type', value: 'investment_type' },
      { label: 'type', value: 'type' },
      { label: 'symbol', value: 'symbol' },
      { label: 'name', value: 'name' },
      { label: 'issue date', value: 'issue_date' },
      { label: 'maturity date', value: 'maturity_date' },
      { label: 'Face value', value: 'money_market_face_value' },
      { label: 'price', value: 'price' },
      { label: 'units', value: 'units' },
      { label: 'yield', value: 'yield' },
      { label: 'coupon rate', value: 'coupon_rate' },
      { label: 'next coupon date', value: 'next_coupon_date' },
      { label: 'last coupon date', value: 'last_coupon_date' },
      { label: 'primary dealer', value: 'primary_dealer' },
      { label: 'fund ips account', value: 'fund_ips_account' },
      { label: 'counter party ips account', value: 'counter_party_ips_account' },
      { label: 'broker name', value: 'broker_name' },
      { label: 'settlement amount', value: 'settlement_amount' },
      { label: 'total face value', value: 'total_face_value' },
      { label: 'accrued amount', value: 'accrued_amount' },
      { label: 'brokerage', value: 'brokerage' },
      { label: 'premium discount', value: 'premium_discount' },
      { label: 'accrued days', value: 'accrued_days' },
      { label: 'counter party name', value: 'counter_party_name' },
      { label: 'detail', value: 'detail' },
      { label: 'principal amount', value: 'principal_amount' },
      { label: 'unredeem value', value: 'unredeem_value' },
      { label: 'coupon payment', value: 'coupon_payment' },
      { label: 'days of maturity', value: 'days_of_maturity' },
    ],
  },
  {
    type: 'equityinvestmentdetailreport',
    required: [
      { label: 'Txn ID', value: 'txn_id' },
      // { label: 'fund code', value: 'fund_code' },
      { label: 'Trade date', value: 'created_at' },
      { label: 'Sett. date', value: 'settlement_date' },
      // { label: 'type', value: 'type' },
      { label: 'invest. type', value: 'investment_type' },
      { label: 'market', value: 'market' },
      { label: 'symbol', value: 'symbol' },
      { label: 'quantity', value: 'quantity' },
      { label: 'avg rate', value: 'avg_rate' },
      // { label: 'broker', value: 'broker' },
      { label: 'comm rate', value: 'commission_rate' },
      { label: 'SST comm.', value: 'sst_on_commission' },
      { label: 'net rate', value: 'net_rate' },
      { label: 'gross amount', value: 'gross_amount' },
      { label: 'comm chrg', value: 'commission_charges' },
      { label: 'net amount', value: 'net_amount' },
      
    ],
  },
  {
    type: 'investmentinfund',
    required: [
      { label: 'TXN ID', value: 'txn_id' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Fund Code', value: 'fund_code' },
      { label: 'Creation Date', value: 'created_at' },
      { label: 'Execution date', value: 'execution_date' },
      { label: 'From bank ', value: 'bank' },
      { label: 'From branch ', value: 'branch' },
      { label: 'From fund IBAN', value: 'account_number' },
      { label: 'To bank', value: 'counter_bank' },
      { label: 'To fund Account', value: 'counter_account_number' },
      { label: 'instrument date', value: 'instrument_date' },
      { label: 'instrument Number', value: 'instrument_no' },
      { label: 'mode of payment', value: 'mode_of_payment' },
      { label: 'Gross Amount', value: 'gross_amount' },
      { label: 'Net amount', value: 'net_amount' },
    ],
  },
  {
    type: 'transferofunits',
    required: [
      { label: 'Txn', value: 'txn_id' },
      { label:'AMC Code',value: 'amc_code'},
      { label:'Fund Code',value: 'fund_code'},
      { label:'Folio No',value: 'folio_no'},
      { label:'Name',value: 'unitholder_name'},
      // { label:'Current Holding',value: 'current_holding'},
      { label:'Remain Holding',value: 'remain_holding'},
      { label:'Transfrees Folio No',value: 'transfrees_folio_no'},
      { label:'Transfrees Name',value: 'transfrees_name'},
      { label:'Transfer of Units',value: 'transfrees_units'},
    ],
  },

];

export const getReuiredFieldForDropDown = (type) => {
  let tx;
  allTxnJSon.map((item, index) => {
    if (item.type === type) {
      tx = item.required;
    }
  });
  return tx;
};
