import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import {
    inWords
} from '../../stores/services/template.service';
import { isNumber, numberWithCommas } from '../../utils/customFunction';
import Config from '../../config';

export const mmMaturityOfSecurityLetter = (transaction: any, securities: any, transType:any, amtWords: any, transNetAmount: any, transGrossAmount: any) => {
    
    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q="qrcodemmMaturityOfSecurity";
    let id=`${Config.q_r_url}/admin/verify-transaction/`+transaction.txn_id;   
    let txnId = transaction.txn_id;
    let branchName = '';
    let branchCity = '';
//     if(transaction.branch != undefined){
//         branchName = transaction.branch.split(',')[0];
//         branchCity = transaction.branch.split(',')[1];
//         branchName = branchCity === undefined ? branchName+',' : branchName;
//         branchCity = branchCity === undefined ? '' : branchCity+',';
// }
if(transaction.branch != undefined){
    if(transaction.branch.includes('–')){
        branchName = transaction.branch.split('–')[0];
        branchCity = transaction.branch.split('–')[1];
        branchName = branchCity === undefined ? branchName.trim()+',' : branchName.trim()+',';
        branchCity = branchCity === undefined ? '' : branchCity.trim()+'.';
    }else{
        branchName = transaction.branch+',';
    }
}

    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 50,
             height : 50
         });
           qrcode.makeCode(id);
     }
    
    } , 500)

    let securityTypeData = securities.map(u => u.security_type).join('|'); 

    function dateDiff(date, endDate) {
        date = date.split('-');
        var today = new Date(endDate);
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
            months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
            years = years - 1;
            months = months + 12;
        }
        // days
        days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
        //
        return {
            years: years,
            months: months,
            days: days
        };
    }

    const getWordedDiff = (dateDiff) => {
        let phrase = '';
        if (dateDiff.years) {
            phrase += dateDiff.years + ' Year,';
        }
        if (dateDiff.months) {
            phrase += dateDiff.months + ' Month,';
        }
        if (dateDiff.days) {
            phrase += dateDiff.days + ' Day.';
        }
        return phrase;
    }

    // String.prototype.removeDuplicate = function () {
    //     return Array.from(new Set(this.split('|'))).toString().replace(',', '');
    // }

    // securityTypeData = securityTypeData.removeDuplicate();

    function removeDuplicate(secType) {
        return Array.from(new Set(secType.split('|'))).toString().replace(',', '');
    }

    securityTypeData = removeDuplicate(securityTypeData);

    let totalAmt = 0;

    // const numberWithCommas = (amount: any) => {
    //     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }

    const getData = (items) => {
        let rows = '';
        for (let i = 0;i < items.length; i++ ) {
            totalAmt += parseFloat(isNumber(items[i].total_face_value) || '0');
            rows += ` <tr>
            <td style="border: 1px solid #333">${moment(items[i].issue_date).format('LL')}</td>
            <td style="border: 1px solid #333">${moment(items[i].maturity_date).format('LL')}</td>
            <td style="border: 1px solid #333">${numberWithCommas(parseFloat(isNumber(items[i].total_face_value)).toFixed(2))}</td>
            <td style="border: 1px solid #333">${numberWithCommas(parseFloat(isNumber(items[i].price)).toFixed(4))}</td>
            <td style="border: 1px solid #333">${items[i].primary_dealer}</td> 
        </tr>`
        }
       rows += `<tr>
        <td style="border: 1px solid #333" colspan="2"> <b> Total Maturity Amount </b> </td>
        <td style="border: 1px solid #333"> <b> ${numberWithCommas(totalAmt)} </b> </td>
        </tr>`
    return rows;
    }
 

    return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <div> Sub : <b style="border-bottom:1px solid black"> Maturity of ${securityTypeData} ${transaction.fund_account} .</b></div>
            <br />
            <div>Dear Sir/Madam</div>    
            <br />
            <div>Please note that <b> ${transaction.fund_account} </b> has Maturity of <b>${securityTypeData}</b> as per details mention below.</div>
            <table style="width: 100%; border: 1px solid #333;">
          
            <tr>
                <th style="border: 1px solid #333;">  ${securityTypeData} Issue Date    </th>
                <th style="border: 1px solid #333;"> Maturity Date    </th>
                <th style="border: 1px solid #333;">  Face Value    </th>
                <th style="border: 1px solid #333;"> SBP Price   </th>
                <th style="border: 1px solid #333;"> Primary Dealer   </th> 
            </tr>
 
                ${transType ==='debtmarketinvestment' ?  getData(securities) : '' }

            </table>
            <br />
            <div style="width: 100%;"> 
                You are requested to kindly mature the <b> ${securityTypeData}</b> and credit the maturity amount of <b> PKR ${numberWithCommas(totalAmt)}   </b>
                into our bank account number <b> ${transaction.account_number}</b>  
                titled <b> ${transaction.account_title} </b>  maintained with <b> ${transaction.bank}  ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch} </b>.
            </div>
            <br />
            <div >
                This is to inform you that deduction of withholding tax is not applied on the profit of above transaction, as mutual funds are exempted from withholding tax as per Clause (47B)
                of Part IV of Second Schedule of Income Tax Ordinance 2001.
            </div> 
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `
}