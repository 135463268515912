import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { getAllRoles, getAssignTransaction } from "../../stores/services/role.service";
import { addUser, registerUser } from "../../stores/services/user.service";
import { toast, ToastContainer } from 'react-toastify';
import { getFeatures } from '../../stores/services/role.service';
import CheckboxTree from 'react-checkbox-tree';

const AddUser = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const txnTpes = sessionStorage.getItem('txnTpe') || '';
  const txnTypeCatagor = sessionStorage.getItem('txnTypeCatagory') || '';
  const [Loading, setLoading] = useState(false);
  const [user_name, setUserName] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [role, setRole] = useState('-1');
  const [roles, setRoles] = useState([]);
  let [nameError, setNameError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [invalidEmailError, setInvalidEmailError] = useState(false);
  let [roleError, setRoleError] = useState(false);

  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');
  const [base64SpicemenImg, setBase64SpicemenImg] = useState<any | null>(null);
  const myRef1 = useRef<HTMLInputElement>(null);

  const [roleAssignedFeatures, setRoleAssignedFeatures] = useState<any>([]);
  const [features, setFeatures] = useState([]);
  const [roleTxnAssign, setRoleTxnAssign] = useState<any>([]);
  const [txnAssignCategory, setTxnAssignCategory] = useState([]);
  let [checked, setChecked] = useState<any>([]);
  let [expanded, setExpanded] = useState<any>([-1]);
  let [nodes, setNodes] = useState<any>([]);

  let [txnChecked, setTxnChecked] = useState<any>([]);
  let [txnExpanded, setTxnExpanded] = useState<any>([-1]);
  let [txnNodes, setTxnNodes] = useState<any>([]);
  let [deniedTxn, setDeniedTxn] = useState<any>([]);

  React.useEffect(() => {
    getAllRoles(sessionStorage.getItem('email') || '')
      .then((response) => {
        // Filter Roles for Trustee user	
        let filterRoles = response.data.data.filter((user) => {
          if (
            (user.role_name !== 'ROLE_TXN_CREATOR' && user.role_name !== 'ROLE_TXN-CREATOR' && user.role_name !== 'ROLE_TXN CREATOR ' && user.role_name !== 'ROLE_TXN CREATOR') &&
            (user.role_name !== 'ROLE_AUTHORIZER-B' && user.role_name !== 'ROLE_AUTHORIZER_B' && user.role_name !== 'ROLE_AUTHORIZER B' && user.role_name !== 'ROLE_AUTHORIZOR B') &&
            (user.role_name !== 'ROLE_AUTHORIZER-A' && user.role_name !== 'ROLE_AUTHORIZER_A' && user.role_name !== 'ROLE_AUTHORIZER A' && user.role_name !== 'ROLE_AUTHORIZOR A')
          ) {
            return user;
          }

        });
        setRoles(filterRoles);
      })

      .catch((err) => {
      });

    // get all features
    getFeatures(sessionStorage.getItem('email') || '')
      .then((response) => {
        setFeatures(response.data.features);
        response.data.features.unshift({
          id: -1,
          feature: 'Trustee',
          parent_id: null,
        });
        for (let index = 0; index < response.data.features.length; index++) {
          if (response.data.features[index].parent_id === 0) {
            response.data.features[index].parent_id = -1;
          }
          response.data.features[index].value = response.data.features[index].id;
          response.data.features[index].label = response.data.features[index].feature;
        }
        var data = response.data.features;
        var root: any;
        const idMapping = data.reduce((acc: any, el: any, i: any) => {
          acc[el.id] = i;
          return acc;
        }, {});
        data.forEach((el: any) => {
          // Handle the root element
          if (el.parent_id === null) {
            root = el;
            return;
          }
          // Use our mapping to locate the parent element in our data array
          const parentEl = data[idMapping[el.parent_id]];
          // Add our current el to its parent's `children` array
          parentEl.children = [...(parentEl.children || []), el];
        });
        setNodes([root]);
      })
      .catch((err) => {
      });
    const assignTxnToRole = async () => {
      try {
        let temp: any = []
        const response = await getAssignTransaction(email);
        setTxnAssignCategory(response.data.txn_assign_category);
        response.data.txn_assign_category.unshift({
          id: -1,
          feature: 'Trustee',
          parent_id: null,
        });
        for (let index = 0; index < response.data.txn_assign_category.length; index++) {

          if (response.data.txn_assign_category[index].parent_id === 0) {
            response.data.txn_assign_category[index].parent_id = -1;
          }
          temp.push(response.data.txn_assign_category[index].id)
          response.data.txn_assign_category[index].value = response.data.txn_assign_category[index].id;
          response.data.txn_assign_category[index].label = response.data.txn_assign_category[index].feature;
        }

        setTxnChecked([...temp])
        var data = response.data.txn_assign_category;
        var root: any;
        const idMapping = data.reduce((acc: any, el: any, i: any) => {
          acc[el.id] = i;
          return acc;
        }, {});
        data.forEach((el: any) => {
          // Handle the root element
          if (el.parent_id === null) {
            root = el;
            return;
          }
          // Use our mapping to locate the parent element in our data array
          const parentEl = data[idMapping[el.parent_id]];
          // Add our current el to its parent's `children` array
          parentEl.children = [...(parentEl.children || []), el];
        });
        setTxnNodes([root]);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    assignTxnToRole();
  }, []);
  const ValidateEmail = (email: string) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const upload = (e) => {
    let file = e?.target.files[0];
    let filesize = parseInt(file.size);
    if (filesize <= 50000) {
      let type = file.name.substring(file.name.lastIndexOf('.') + 1);

      if (type === 'png' || type === 'jpg' || type === 'jpeg') {
        //code here
        setFile(file);
        setFileName(file.name)
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          setBase64SpicemenImg(reader.result);
        };
      } else {
        toast.error('Invalid Format')
      }
    } else {
      toast.error("File size should be less than 50kb");
    }

  };

  let [count, setCount] = useState(0);
  useEffect(() => {
    if (count === 1) {
      registerTheUser();
    }
  }, [count])

  const registerTheUser = async () => {

    try {
      setNameError(false);
      setEmailError(false);
      setRoleError(false);
      setInvalidEmailError(false);
      // setLoading(true);	
      if (!user_name || user_name.trim() === '') {
        toast.error('Name is Required');
        setLoading(false);
        return;
      }
      if (!user_email || user_email.trim() === '') {
        toast.error('Email is Required');
        setLoading(false);
        return;
      }
      if (!ValidateEmail(user_email)) {
        toast.error('Email is Invalid');
        setLoading(false);
        return;
      }
      if (role === '-1') {
        toast.error('Role Selection is Required');
        setLoading(false);
        return;
      }
      setLoading(true);
      const response = await registerUser(email, user_email);
      console.log('ressss', response.data.length)
      if (response.data.length === 0 || response.data.length===undefined) {
        setLoading(true);
        setCount(1);
      }
      if (response.data.length !== 0) {
        if (response.data.status == 200) {

          await addUserBtn();
        } else {
          toast.error(response.data.message);
        }
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setLoading(false)
  };




  const addUserBtn = async () => {
    let additionalaccess: any = [];

    additionalaccess = checked.filter(val => !roleAssignedFeatures.includes(parseFloat(val)));

    const additional_selected_features: any = [];
    for (let index = 0; index < additionalaccess.length; index++) {
      const feature = features.find((x: any) => x.id === parseInt(additionalaccess[index]));
      if (feature) {
        additional_selected_features.push(feature);
      }
    }
    let denied_selected_features: any = [];
    let deniedaccess = roleAssignedFeatures.filter(val => !checked.includes(val.toString()));

    if (roleAssignedFeatures.sort().join(',') !== checked.sort().join(',')) {
      for (let index = 0; index < deniedaccess.length; index++) {
        const feature = features.find((x: any) => x.id === parseInt(deniedaccess[index]));
        if (feature) {
          denied_selected_features.push(feature);
        }
      }
    }
    txnChecked = txnChecked.map(function (e) { return e.toString() });
    //let deniedTxn = txnAssignCategory.filter((val: any) => !txnChecked.includes(val));
    const deniedTxn = await txnAssignCategory.filter((val: any) => !txnChecked.includes(val.id.toString()));
    // deniedTxn.shift();
    try {
      const response = await addUser(
        sessionStorage.getItem('email') || '',
        user_name,
        role.replace('ROLE_', ''),
        'description',
        user_email,
        JSON.stringify(additional_selected_features),
        JSON.stringify(denied_selected_features),
        JSON.stringify(deniedTxn),
        base64SpicemenImg == null ? '' : base64SpicemenImg.toString()
      );
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setTimeout(function () {
          history.replace('/admin/user-management');
          setLoading(false);	
        }, 3000);
      } else {
        setLoading(false);	
        toast.error(response.data.message);
      }
    } catch (err: any) {
      setLoading(false);	
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Request Failed!');
      }
    }
    // await addUser(
    //   sessionStorage.getItem('email') || '',
    //   user_name,
    //   role.replace('ROLE_', ''),
    //   'description',
    //   user_email,
    //   JSON.stringify(additional_selected_features),
    //   JSON.stringify(denied_selected_features),
    //   JSON.stringify(deniedTxn),
    //   base64SpicemenImg == null ? '' : base64SpicemenImg.toString()
    // )
    //   .then((response) => {
    //     c=1;
    //     // if (Object.keys(response.data).length === 0) {
    //     //   setLoading(true);
    //     //   setCount(1);
    //     // }
    //     // if (Object.keys(response.data).length !== 0) {
    //       if (response.data.status === 200) {
    //         toast.success(response.data.message);
    //         setTimeout(function () {
    //           history.replace('/admin/user-management');
    //         }, 3000);
    //       } else {
    //         toast.error(response.data.message);
    //       }
    //     // }
    //     // setLoading(false);	
    //   })

    //   .catch((err) => {
    //     c=1;
    //     // setLoading(false)
    //     if (err.response !== undefined) {
    //       toast.error(err.response.data.message);
    //     } else {
    //       toast.error('Request Failed!');
    //     }
    //   });
    // setLoading(false);		
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="card-title">Add User</h4>
                </div>
                <div className="card-body">
                  <div className="title-row">
                    <h3 className="mb-1">User Info</h3>
                    <Link to="/admin/user-management" className="t-3 btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>
                  </div>
                  <p className="t-3 mb-2">Please enter the following information to add a new admin or user for trustee system</p>
                  <div className="">
                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label>Name</label>
                          <input className="form-control" value={user_name} onChange={(e) => {
                            setUserName(e.target.value);
                          }} />
                        </div>
                        {
                          nameError === true ?
                            <p className="error-labels">Name is Required.</p>
                            : ''
                        }
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>Email</label>
                          <input className="form-control" value={user_email} onChange={(e) => {
                            let value = e.target.value;
                            setUserEmail(value.toLowerCase());
                          }} />
                        </div>
                        {
                          emailError === true ?
                            <p className="error-labels">Email is Required.</p>
                            : ''
                        }
                        {
                          invalidEmailError === true ?
                            <p className="error-labels">Email is Invalid.</p>
                            : ''
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label>Select Role</label>
                          <select className="form-control" value={role} onChange={(e) => {
                            setRole(e.target.value);
                            const rolefeature: any = roles.filter((item: any) => {
                              return item.role_name.replace('ROLE_', '') === e.target.value;
                            })

                            // let features = rolefeature[0].features;
                            const result = JSON.parse(rolefeature[0].features).map((a: any) => {
                              return a.id;
                            });

                            setRoleAssignedFeatures(result);
                            //setTxnAssignCategory(result);
                            setChecked(result)
                          }}>
                            <option value="-1">Select Role</option>
                            {
                              roles.map((role: any) => {

                                return (
                                  <option value={role.role_name.replace('ROLE_', '')}>{role.role_name.replace('ROLE_', '')}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                        {
                          roleError === true ?
                            <p className="error-labels">Role is Required.</p>
                            : ''
                        }
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label>Signature Specimen <small className="color-amber">(PNG or JPG of upto 50KB)</small></label>
                          <div
                            className="multi-input pointer"
                            onClick={() => myRef1?.current?.click()}
                          >
                            <div className="form-group">
                              <div className="form-control">
                                {fileName === '' ? 'Upload File' : fileName}
                              </div>
                              <input
                                type="file"
                                ref={myRef1}
                                style={{ display: 'none' }}
                                multiple={false}
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  upload(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {base64SpicemenImg ? (
                          <img src={base64SpicemenImg} className="rounded" alt="spicemen" width="100px" height="100px" />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <div className="line"> </div>{" "}
                    <Row>
                      <Col md='6'>
                        <Row className="mt-3 ml-2">
                          <Col md='12'>
                            <h3 className="mb-1"> Assign Additional Features </h3>{" "}
                            <p className="t-3 mb-2"> Features </p>
                          </Col>
                          <CheckboxTree
                            nodes={nodes}
                            checked={checked}
                            expanded={expanded}
                            onCheck={(checked) => {
                              setChecked(checked);
                            }}
                            onExpand={(expanded) => {
                              setExpanded(expanded)
                            }}
                            iconsClass="fa5"
                          />
                        </Row>
                      </Col>
                      {(role === "TXN_CREATOR" || role === "ADMIN" || role === "CONCERNED_OFFICER") &&
                        <Col md='6'>
                          <Row className="mt-3 ml-2">
                            <Col md='12'>
                              <h3 className="mb-1"> Assign Transaction Types </h3>{" "}
                              <p className="t-3 mb-2"> Transaction Types </p>
                            </Col>
                            <CheckboxTree
                              nodes={txnNodes}
                              checked={txnChecked}
                              expanded={txnExpanded}

                              onCheck={(txnChecked, node) => {
                                setTxnChecked(txnChecked);
                              }}
                              onExpand={(txnExpanded) => {
                                setTxnExpanded(txnExpanded)
                              }}
                              iconsClass="fa5"
                            />
                          </Row>
                        </Col>
                      }
                    </Row>
                  </div>
                  <div className="mt-3">
                    <button className="btn btn-primary" onClick={registerTheUser} disabled={Boolean(Loading)}>
                      {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="login-txt"> Loading...</span></> : <span>Add User</span>}
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
};
export default AddUser;