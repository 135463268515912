import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAmc } from '../../../stores/services/amc.service';
import { isNumber, numberWithCommas, sortAMCS } from '../../../utils/customFunction';
import { getFundByAmc } from '../../../stores/services/funds.service';
import { getAllSecurities, getAllPsx, getAllActiveFundSecurity } from '../../../stores/services/security.service';
import { BsTable } from 'react-icons/bs';
import { getAccountByFund } from '../../../stores/services/account.service';
import { fundInvestmentSmry } from '../../../stores/services/summary.service';
import { MenuItem } from '@material-ui/core';

const FundInvestmentSummary = () => {

  const email = sessionStorage.getItem('email') || '';
  const [fundInstmntLoader, setFundInstmntLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [fundInvestmentSummary, setFundInvestmentSummary] = useState<any>([])

  const [totalData, setTotalData] = useState<any>([]);

  const [Amc, setAmc] = useState('');
  const [allAmc, setAllAmc] = useState<any>([]);
  const [fund, setFund] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);

  const [fundErr, setFundErr] = useState('');

  const [totalfund, setTotalFund] = useState('');
  const [totalSMA, setTotalSMA] = useState('');
  const [totalCashInBank, setTotalCashInBank] = useState('');
  const [totalFaceValue, setTotalFaceValue] = useState('');
  const [totalCashInStocks, setTotalCashInMoneyStocks] = useState('');
  const [totalCashInInvementInFundMARKET, setTotalCashInInvementInFundMARKET] = useState('');
  const [getTotalAmount, setGetTotalAmount] = useState('');

  const [moneyMarketData, setMoneyMarketData] = useState<any>([]);
  const [mMData, setMMData] = useState<any>([]);
  const [totalMMAmount, setTotalMMAmount] = useState<any>(0);

  const [stockMarket, setStockMarket] = useState<any>([]);
  const [fundInvestmentMarket, setFundInvestmentMarket] = useState<any>([]);
  const [totalFaceVal, setTotalFaceVal] = useState<any>(0);

  const [psxEquity, setPsxEquity] = useState<any>([]);
  const [investmentInFundRecord, setInvestmentInFundRecord] = useState<any>([]);
  const [totalequityAmount, setTotalEquityAmount] = useState<any>(0);

  const [bankAccounts, setBankAccounts] = useState<any>([]);
  const [bankTotalAmount, setBankTotalAmount] = useState<any>(0);


  useEffect(() => {
    async function getAllFundInvetment() {
      setFundInstmntLoader(true);
      try {
        const response = await fundInvestmentSmry(email);
        const resData = response.data.data.map((resitem: any) => {
          if (resitem.cash_in_bank === '' || resitem.cash_in_bank === 'NaN' || resitem.cash_in_bank === NaN || resitem.cash_in_bank === undefined) {
            resitem.cash_in_bank = '0'
          }
          if (resitem.total_face_value === null || resitem.total_face_value === '' || resitem.total_face_value === 'NaN' || resitem.total_face_value === NaN || resitem.total_face_value === undefined) {
            resitem.total_face_value = '0'
          }
          if (resitem.cash_in_stocks === null || resitem.cash_in_stocks === '' || resitem.cash_in_stocks === 'NaN' || resitem.cash_in_stocks === NaN || resitem.cash_in_stocks === undefined) {
            resitem.cash_in_stocks = '0'
          }
          if (resitem.cash_in_fundInvestment === null || resitem.cash_in_fundInvestment === '' || resitem.cash_in_fundInvestment === 'NaN' || resitem.cash_in_fundInvestment === NaN || resitem.cash_in_fundInvestment === undefined) {
            resitem.cash_in_fundInvestment = '0'
          }
          return {
            ...resitem, totalAmount: (+parseFloat(resitem.cash_in_bank) + +parseFloat(resitem.total_face_value) + +parseFloat(resitem.cash_in_stocks) + +parseFloat(resitem.cash_in_fundInvestment))
          }
        })
        setFundInvestmentSummary(resData);
        let total_fund: any = 0, total_SMA: any = 0, total_Cash_In_Bank: any = 0, total_Cash_In_MoneyMarket: any = 0, total_Cash_In_Stoks: any = 0, total_amount: any = 0, total_cash_in_fundInvestment: any = 0;
        resData.map((item: any, index: number) => {
          if (item.normal_fund_count === '' || item.normal_fund_count === 'NaN' || item.normal_fund_count === NaN || item.normal_fund_count === undefined) {
            item.normal_fund_count = '0'
          }
          if (item.sma_count === '' || item.sma_count === 'NaN' || item.sma_count === NaN || item.sma_count === undefined) {
            item.sma_count = '0'
          }
          if (item.cash_in_bank === '' || item.cash_in_bank === 'NaN' || item.cash_in_bank === NaN || item.cash_in_bank === undefined) {
            item.cash_in_bank = '0'
          }
          if (item.total_face_value === '' || item.total_face_value === 'NaN' || item.total_face_value === NaN || item.total_face_value === undefined) {
            item.total_face_value = '0'
          }
          if (item.cash_in_stocks === '' || item.cash_in_stocks === 'NaN' || item.cash_in_stocks === NaN || item.cash_in_stocks === undefined) {
            item.cash_in_stocks = '0'
          }
          if (item.cash_in_fundInvestment === null || item.cash_in_fundInvestment === '' || item.cash_in_fundInvestment === 'NaN' || item.cash_in_fundInvestment === NaN || item.cash_in_fundInvestment === undefined) {
            item.cash_in_fundInvestment = '0'
          }
          if (item.totalAmount === '' || item.totalAmount === 'NaN' || item.totalAmount === NaN || item.totalAmount === undefined) {
            item.totalAmount = '0'
          }
          total_fund += parseFloat(item.normal_fund_count || '0');
          total_SMA += parseFloat(item.sma_count || '0');
          total_Cash_In_Bank += parseFloat(item.cash_in_bank || '0');
          total_Cash_In_MoneyMarket += parseFloat(item.total_face_value || '0');
          total_Cash_In_Stoks += parseFloat(item.cash_in_stocks || '0');
          total_cash_in_fundInvestment += parseFloat(item.cash_in_fundInvestment || '0');
          total_amount += parseFloat(item.totalAmount || '0');
        });

        setTotalFund(total_fund)
        setTotalSMA(total_SMA)
        setTotalCashInBank(total_Cash_In_Bank)
        setTotalFaceValue(total_Cash_In_MoneyMarket)
        setTotalCashInMoneyStocks(total_Cash_In_Stoks)
        setTotalCashInInvementInFundMARKET(total_cash_in_fundInvestment)
        setGetTotalAmount(total_amount);

      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setFundInstmntLoader(false);
    }

    getAllFundInvetment();
  }, [])

  useEffect(() => {
    const getAllAmc = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAmc();
  }, [])

  useEffect(() => {
    const getMoneyMarketSecurities = async () => {
      try {
        const response = await getAllSecurities(email, '', '')
        setMoneyMarketData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    getMoneyMarketSecurities();
  }, [])

  useEffect(() => {
    const getEquities = async () => {
      try {
        const response = await getAllPsx(email)
        setPsxEquity(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    getEquities();
  }, [])

  useEffect(() => {
    const getActiveFundSecurity = async () => {
      try {
        const response = await getAllActiveFundSecurity(email)
        setInvestmentInFundRecord(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    getActiveFundSecurity();
  }, [])

  const getAccounts = async (fundData) => {
    try {
      const response = await getAccountByFund(email, fundData)
      let bank_total_amount: any = 0;
      const resData = response.data.data.map((bankItem: any) => {
        if (bankItem.balance_amount === '' || bankItem.balance_amount === 'NaN' || bankItem.balance_amount === NaN || bankItem.balance_amount === undefined) {
          bankItem.balance_amount = '0'
        }
        bank_total_amount += parseFloat(bankItem.balance_amount || '0');
        return bankItem;
      })
      setBankTotalAmount(bank_total_amount);
      setBankAccounts(resData);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  };
  // const totalAmount = () =>{

  //   let totalAmnt = bankAccounts.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.balance_amount), 0)
  //   return totalAmnt;
  // }

  const renderAmcDropDown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replaceAll('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  }

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);

      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const renderFundDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  const searchData = async () => {
    try {
      setSearchLoading(true)
      if (fund.length === 0) {
        setTimeout(() => {
          setSearchLoading(false)
        }, 2000);
      } else {
        setLoading(true);
        await getAccounts(fund);
        const sMData = allFunds.filter((item: any) => {
          return item.symbol_code === fund;
        });
        setStockMarket(JSON.parse(sMData[0].stock_market))
        if (JSON.parse(sMData[0].stock_market).length > 0) {
          await equityData(JSON.parse(sMData[0].stock_market))
        }
        const iFData = allFunds.filter((item: any) => {
          return item.symbol_code === fund;
        });
        if (iFData[0].fund_market) {
          setFundInvestmentMarket(JSON.parse(iFData[0].fund_market))
          if (JSON.parse(iFData[0].fund_market).length > 0) {
            await investmentInFundData(JSON.parse(iFData[0].fund_market))
          }
        }

        const mmData = allFunds.filter((item: any) => {
          return item.symbol_code === fund;
        });
        setMMData(JSON.parse(mmData[0].money_market));
        if (JSON.parse(mmData[0].money_market).length > 0) {
          await moneyMData(JSON.parse(mmData[0].money_market))
        }
        setSearchLoading(false)
        setLoading(false);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
      setSearchLoading(false)
    }


  }


  // Equity 
  const equityData = (stockMrkt) => {
    const purchaseRate = stockMrkt.map((equityItem: any) => {

      let matchData = psxEquity.find(item => item.symbol === equityItem.code);
      if (matchData !== undefined) {
        return {
          ...equityItem, current_rate: isNumber(matchData.close_rate), totalAmount: (+parseFloat(isNumber(equityItem.unit)) * +parseFloat(isNumber(matchData.close_rate)))
        }
      } else {
        return {
          ...equityItem, current_rate: '0', totalAmount: (+parseFloat(equityItem.unit) * 0)
        }
      }
    })
    setStockMarket(purchaseRate);
  }
  const totalEquityAmount = () => {
    let totalAmnt = stockMarket.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(isNumber(currentValue.totalAmount)), 0)
    return totalAmnt;
  }
  // Investment IN FUND 
  const investmentInFundData = (invstInFundMrkt) => {
    const purchaseRateinvstInFund = invstInFundMrkt.map((invstInItem: any) => {
      let matchData = investmentInFundRecord.find(item => item.code === invstInItem.code);
      if (matchData !== undefined) {
        return {
          ...invstInItem, current_rate: isNumber(matchData.price), totalAmount: (+parseFloat(isNumber(invstInItem.units)) * +parseFloat(isNumber(matchData.price)))
        }
      } else {
        return {
          ...invstInItem, current_rate: '0', totalAmount: (+parseFloat(isNumber(invstInItem.units)) * 0)
        }
      }
    })
    setFundInvestmentMarket(purchaseRateinvstInFund);
  }
  const totalInvestmentInFundAmount = () => {
    let totalAmnt = fundInvestmentMarket.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.totalAmount), 0)
    return totalAmnt;
  }
  // Money Market
  const moneyMData = (moneyMrkt) => {
    let total_face_val: any = 0;
    const getRate = moneyMrkt.map((moneyMItem: any) => {

      if (moneyMItem.face_value === '' || moneyMItem.face_value === 'NaN' || moneyMItem.face_value === NaN || moneyMItem.face_value === undefined) {
        moneyMItem.face_value = '0'
      }


      if (moneyMItem.price === '' || moneyMItem.price === 'NaN' || moneyMItem.price === NaN || moneyMItem.price === undefined) {
        moneyMItem.price = '0'
      }
      if (moneyMItem.units === '' || moneyMItem.units === 'NaN' || moneyMItem.units === NaN || moneyMItem.units === undefined) {
        moneyMItem.units = '0'
      }

      total_face_val += parseFloat(moneyMItem.face_value);

      let matchData = moneyMarketData.find(item => item.code.replace('SECURITY_', '') === moneyMItem.code);
      if (matchData !== undefined) {
        return {
          ...moneyMItem, sprice: matchData.price
        }
      } else {
        return {
          ...moneyMItem, sprice: '0'

        }
      }

    })
    setTotalFaceVal(total_face_val);
    setMMData(getRate);
  }

  return (
    <>
      <div className="content">
        <ToastContainer limit={1} />
        <Container fluid>
          <Row>
            <Col md="12">

              <div className="card ">
                <div className="card-header">
                  <h4 className="card-title">Fund Investment Summary</h4>
                </div>
                <div className="card-body">
                  {!fundInstmntLoader ? (
                    <div className='table-responsive'>
                      <table className="table" id="myTable" >
                        <thead>
                          <tr>
                            <th>Company</th>
                            <th>Funds</th>
                            <th>SMA</th>
                            <th className='text-right'>Cash in Bank</th>
                            <th className='text-right'>Stocks</th>
                            <th className='text-right'>Money Market</th>
                            <th className='text-right'>Mutual Funds</th>
                            <th className='text-right'>Total</th>
                          </tr>
                        </thead>
                        <tbody id="myUL">
                          {
                            fundInvestmentSummary.map((fundInstSmry: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{fundInstSmry.name}</td>
                                  <td>{fundInstSmry.normal_fund_count}</td>
                                  <td>{fundInstSmry.sma_count}</td>
                                  <td className='text-right'>{numberWithCommas(parseFloat(fundInstSmry.cash_in_bank).toFixed(2))}</td>
                                  <td className='text-right'>{numberWithCommas(parseFloat(fundInstSmry.cash_in_stocks).toFixed(2))}</td>
                                  <td className='text-right'>{numberWithCommas(parseFloat(fundInstSmry.total_face_value).toFixed(2))}</td>
                                  <td className='text-right'>{numberWithCommas(parseFloat(fundInstSmry.cash_in_fundInvestment).toFixed(2))}</td>
                                  <td className='text-right'>{numberWithCommas(parseFloat(fundInstSmry.totalAmount).toFixed(2))}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                        <tfoot>

                          <tr>
                            <th>Total</th>
                            <th >{totalfund}</th>
                            <th >{totalSMA}</th>
                            <th className='text-right'>{numberWithCommas(parseFloat(totalCashInBank).toFixed(2))}</th>
                            <th className='text-right'>{numberWithCommas(parseFloat(totalCashInStocks).toFixed(2))}</th>
                            <th className='text-right'>{numberWithCommas(parseFloat(totalFaceValue).toFixed(2))}</th>
                            <th className='text-right'>{numberWithCommas(parseFloat(totalCashInInvementInFundMARKET).toFixed(2))}</th>
                            <th className='text-right'>{numberWithCommas(parseFloat(getTotalAmount).toFixed(2))}</th>
                          </tr>

                        </tfoot>
                        {/* totalData */}
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center my-5">
                      <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md="12">
              <Row>
                <Col md="3">
                  <div className="form-group d-inline-flex">
                    <div className="text-nowrap mr-2 mt-2">Select AMC</div>
                    <select
                      className={`form-control  w-100 ${fundErr ? 'required-border' : ''
                        }`}
                      onChange={(e) => {
                        setAllFunds([]);
                        setFund([]);
                        setAmc(e.target.value);
                        getFunds(e.target.value);
                        setFundErr('');
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select AMC
                      </option>
                      {renderAmcDropDown()}
                    </select>
                  </div>
                </Col>

                <Col md="3">
                  <div className="form-group d-inline-flex">
                    <div className="text-nowrap mr-2 mt-2">Select Fund</div>
                    <select
                      className="form-control"
                      onChange={async (e) => {
                        setFund(e.target.value)
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Fund
                      </option>
                      {renderFundDropDown()}
                    </select>
                  </div>
                </Col>
                <Col md='3'>
                  <div className="form-group d-inline-flex">
                    <button
                      className="btn btn-primary mr-2 mt-0"
                      onClick={() => {
                        searchData();
                      }}
                      disabled={Boolean(searchLoading)}
                    >
                      {searchLoading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Search</span>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
            {!loading ? (
              <>
                <Col md="6">

                  <div className="card ">
                    <div className="card-header">
                      <h4 className="card-title">Bank Accounts</h4>
                    </div>
                    <div className="card-body">
                      {bankAccounts.length > 0 ? (
                        <div className='table-responsive'>
                          <table className="table " id="myTable" >
                            <thead>
                              <tr>
                                <th>Bank Accounts</th>
                                <th className='text-right'>Amount</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">
                              {
                                bankAccounts.map((bankAcc: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>{bankAcc.account_title}</td>
                                      <td className='text-right'>{numberWithCommas(bankAcc.balance_amount === 'NaN' ? '0' : bankAcc.balance_amount)}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th className='text-right'>{numberWithCommas(bankTotalAmount.toFixed(2))}</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty m-0">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="col-12"></div>
                </Col>
                <Col md="6">

                  <div className="card ">
                    <div className="card-header">
                      <h4 className="card-title">Equity Portfolio</h4>
                    </div>
                    <div className="card-body">
                      {stockMarket.length > 0 ? (
                        <div className='table-responsive'>
                          <table className="table " id="myTable" >
                            <thead>
                              <tr>
                                <th>Company</th>
                                <th className='text-right'>Quantity</th>
                                <th className='text-right'>Purchase Rate</th>
                                <th className='text-right'>Current Rate</th>
                                <th className='text-right'>Amount</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">
                              {
                                stockMarket.map((stockMrkt: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>{stockMrkt.code}</td>
                                      <td className='text-right'>{numberWithCommas(stockMrkt.unit)}</td>
                                      <td className='text-right'>{numberWithCommas(stockMrkt.close_rate)}</td>
                                      <td className='text-right'>{numberWithCommas(stockMrkt.current_rate)}</td>
                                      <td className='text-right'>{numberWithCommas(stockMrkt.totalAmount.toFixed(2))}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className='text-right'>{numberWithCommas(totalEquityAmount().toFixed(2))}</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty m-0">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="col-12"></div>
                </Col>
                <Col md="6">

                  <div className="card ">
                    <div className="card-header">
                      <h4 className="card-title">Money Market</h4>
                    </div>
                    <div className="card-body">
                      {mMData.length > 0 ? (
                        <div className='table-responsive'>
                          <table className="table " id="myTable" >
                            <thead>
                              <tr>
                                <th>Money Market</th>
                                <th>Period</th>
                                <th className='text-right'>Price</th>
                                <th className='text-right'>MM Price</th>
                                <th className='text-right'>MM Units</th>
                                <th className='text-right'>Total Face Value</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">
                              {
                                mMData.map((moneyMarket: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>{moneyMarket.name}</td>
                                      <td>{moneyMarket.issue_date} - {moneyMarket.maturity_date}</td>
                                      <td className='text-right'>{numberWithCommas(parseFloat(moneyMarket.sprice).toFixed(2))}</td>
                                      <td className='text-right'>{numberWithCommas(moneyMarket.price)}</td>
                                      <td className='text-right'>{numberWithCommas(moneyMarket.units)}</td>
                                      <td className='text-right'>{numberWithCommas(parseFloat(moneyMarket.face_value).toFixed(2))}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className='text-right'>{numberWithCommas(totalFaceVal.toFixed(2))}</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty m-0">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )}
                    </div>


                  </div>

                  <div className="col-12"></div>
                </Col>
                <Col md="6">

                  <div className="card ">
                    <div className="card-header">
                      <h4 className="card-title">Mutual Funds Portfolio</h4>
                    </div>
                    <div className="card-body">
                      {fundInvestmentMarket.length > 0 ? (
                        <div className='table-responsive'>
                          <table className="table " id="myTable" >
                            <thead>
                              <tr  >
                                <th> Symbol / Code </th>
                                <th className="text-right">No of Units</th>
                                <th className="text-right">Purchase Price</th>
                                <th className="text-right">Current Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">
                              {
                                fundInvestmentMarket.map((fundInvestmentMRKT: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>{fundInvestmentMRKT.code}</td>
                                      <td className='text-right'>{numberWithCommas(fundInvestmentMRKT.units)}</td>
                                      <td className='text-right'>{numberWithCommas(fundInvestmentMRKT.price)}</td>
                                      <td className='text-right'>{numberWithCommas(fundInvestmentMRKT.current_rate)}</td>
                                      <td className='text-right'>{numberWithCommas(fundInvestmentMRKT.totalAmount.toFixed(2))}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className='text-right'>{numberWithCommas(totalInvestmentInFundAmount().toFixed(2))}</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty m-0">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="col-12"></div>
                </Col>
              </>
            ) : (
              <Col>
                <div className="d-flex justify-content-center my-5">
                  <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                </div>
              </Col>
            )}

          </Row>
        </Container>
      </div>
    </>
  )
};
export default FundInvestmentSummary;

