import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { useHistory } from 'react-router';
import {inWords, getAllBankTemplates} from './../../stores/services/template.service';
import {getCollectivePayment} from './../../stores/services/collective-payment.service'
import { RiPrinterLine } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {toProperCase, numberWithCommas} from './../../utils/customFunction';

const CollectivePayment = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [collectivePaymentData, setCollectivePaymentData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [displayChequeModal, setDisplayChequeModal] = useState(false);
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');	
  // render Model for adding Fund	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
  const [tempLoading, setTempLoading] = useState(false);

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           setAllAmc(amcResponse.data.data);
            }catch(err:any){
              if(err.response !== undefined ) {
                toast.error(err.response.data.message);	
              }else {
                toast.error("Request Failed!")
              }
            }
          }
        
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_','')}>
                {item.name}-{item.amc_code.replace('AMC_','')}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(error){

            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }


  //code for search data for Reports

  const searchData = async () => {  
    try {
      setLoading(true);
      const response = await getCollectivePayment(email, Amc, fund, fromDate,toDate);
      if(response.data.data.length===0){
        toast.error(response.data.message)
      }else{
        setCollectivePaymentData(response.data.data);
      }
      
      setLoading(false);
    } catch (error:any) {
        setLoading(false);
        if(error.response!==undefined){
          toast.error(error.response.data.message);
        }else{
          toast.error('Request Failed!');
        }
    }
  }

  const getTemplates = async (bankName) => {	
    try {	
      const response = await getAllBankTemplates(email, bankName);	
      setAllTemplates(response.data.data);	
      if (response.data.data.length < 1) {	
        setTempLoading(true);	
      } else {	
        setTempLoading(false);	
      }	
    } catch (error) {}	
  };

  const printChequeValidation=()=>{
    let check=true;
    if(selectedTemplate===''){
      check=false;
    }else if(title===''){
      check=false;
    }else if(amount===''){
      check=false;
    }
    return check;
  };

  const printContent = () => {	
    const isValid = printChequeValidation();
    if(isValid){
     //for cheque	
     let tempData = [...selectedTemplate];	
     let ledgerType = toProperCase(data.LedgerType.replace('_',' '));	
     tempData[0].label = moment(data.instrument_date).format('DD-MM-YYYY');		
     tempData[1].label = title;		
     tempData[2].label = amount;	
     tempData[3].label = inWords(amount);
    //  tempData[5].label = `${txn_name} (${data.txn_id})`;	
     tempData[5].label = `${ledgerType} (${data.collective_payment_id})`;	
     sessionStorage.setItem('template', JSON.stringify(tempData));	
     history.replace('/admin/print-screen/pending');	
    }
   };	

  const renderTemplateDropdown = () => {	
    return allTemplates.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.template_detail}>	
          {item.template_name}	
        </option>	
      );	
    });	
  };

  const renderModaForPrintChequePopup = () => {	
    switch (displayChequeModal) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            enforceFocus={false}
            onHide={() => { setDisplayChequeModal(false) }}	
          >  	
            <div className="modal-header ">	
                <button	
                  aria-hidden={true}	
                  className="close"	
                  data-dismiss="modal"	
                  type="button"	
                  onClick={() => {	
                    setDisplayChequeModal(false);		
                  }}	
                >	
                  <i className="tim-icons icon-simple-remove" />	
                </button>	
                <h6 className="title title-up">  Select Template</h6>	
              </div>	
            <div className="modal-body">	
              <div className="row">	
              <div className="col-md-12">	
                <div className="form-group">	
                  <label>Select Template</label>	
                  <ReactTooltip textColor='white' backgroundColor='black' effect="float" />	
                  <select className="form-control"	
                    onChange={(e) => {	
                      let x = JSON.parse(e.target.value)	
                      setSelectedTemplate(x);	
                    }}>	
                    <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Template'}</option>	
                    {renderTemplateDropdown()}	
                  </select>	
                </div>	
              
                </div> 	
                
                <div className="col-md-12">	
                  <button className="btn btn-primary btn-block mt-3" onClick={() => {	
                    printContent();	
                  }} disabled={Boolean(disableAddBtn)}>	
                    {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                      <span className="login-txt"> Loading...</span></> : <span>Print</span>}	
                  </button>	
                  
              </div>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Collective Payment
              
              <button
                className="btn btn-info btn-sm float-right"
                onClick={() => {
                  history.replace('/admin/collective-tax-payment')
                }}
              >
                + Payment
              </button>
              </h4>
            
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setAmc(e.target.value);
                        getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setFund(e.target.value)
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group  ">
                  <label>Date From</label>
                  <input
                    type="date"
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromDate(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Date To</label>
                  <input
                    type="date"
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToDate(date);
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

        <Row>
            <Col>
          <div className="card mt-3"> 
          <div className="card-header">
              <div className="card-title">Collective Payment</div>
          </div>
            <div className="card-body">
             {collectivePaymentData.length>0 ? (
               <>
                <div className='table-responsive'>
                <table className="table">
                    <thead>
                        <tr>
                            <th>CP Id  </th>
                            <th>Payment Date</th>
                            <th>Ledger Type</th>
                            <th>Description</th>
                            <th className="text-right">Amount</th>
                            <th>Beneficiary</th>
                            <th>Instrument</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                      {collectivePaymentData.map((item:any, index:number)=>{
                        return (
                          <>
                          <tr key={index}> 
                            <td>{item.id}</td>
                            <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
                            {/* <td>{toProperCase(item.LedgerType.replace('_',' '))}</td> */}
                            <td>{item.LedgerType ? toProperCase(item.LedgerType.replace('_',' ')):toProperCase(item.ledger_type.replace('_',' '))}</td>
                            <td>{item.description}</td>
                            <td className="text-right">{numberWithCommas(item.total_amount)}</td>
                            <td>{item.account_title}-{item.account_number}, {item.bank_code + '-'+ item.branch}  
                            </td>
                            <td>{item.instrument_type} ({item.instrument_no}) {item.instrument_date == '' ? '' : moment(item.instrument_date).format('DD-MM-YYYY')}</td>
                            <td>
                            <ReactTooltip	
                                  textColor="white"	
                                  backgroundColor="black"	
                                  effect="float"	
                                />
                             <RiPrinterLine	
                                      data-tip={'Print Cheque'}	
                                      className={'mr-1'}	
                                      onClick={() => {	
                                        getTemplates(item.bank);	
                                        setData(item);
                                        setTitle(item.account_title);
                                        setAmount(item.total_amount);
                                        sessionStorage.setItem('cheque', JSON.stringify(item));
                                        setDisplayChequeModal(true);	
                                      }}
                                      	
                        /></td>
                        </tr>
                        </>
                        );
                      })}
                        
                    </tbody>
                </table>
                
            </div>
            </>
            ):(
              <></>
            )}
          </div>
         </div>
         </Col>

        </Row>

        </div>
      </Container>
      </Col>
        </Row>
      </div>
      {renderModaForPrintChequePopup()}
    </>
  );
};

export default CollectivePayment;
