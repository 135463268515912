import React from 'react';
import { useState } from 'react';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import { numberWithCommas } from '../../../utils/customFunction';

const ViewClient = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [amcName, setAmcName] = useState('');
  const [name, setName] = useState('');
  const [folioNo, setFolioNo] = useState('');
  const [cnic, setCnic] = useState('');
  const [cnicExpiry, setCnicExpiry] = useState('');
  const [ntn, setNtn] = useState('');
  const [mainFolioNo, setMainFolioNo] = useState('');
  const [taxStatus, setTaxStatus] = useState('');
  //
  const [clientEmail, setClientEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  // const [filer, setFiler] = useState(false)	;
  // const updateFiler = () => setFiler(!filer)
  const [province, setProvince] = useState('');
  //
  const [accTitle, setAccTitle] = useState('');
  const [accNo, setAccNo] = useState('');
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  //
  const [blnUpdate, setBlnUpdate] = useState<any>();
  const [reinvestCheck, setReinvestCheck] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  // const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);

  const [father, setFather] = useState('');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(false);
  const [type, setType] = useState('');
  // guardian
  const [guardian_name, setGuardian_name] = useState('');
  const [guardian_relation, setGuardian_relation] = useState('');
  const [guardian_cnic, setGuardian_cnic] = useState('');
  // nominee
  const [nominee_name, setNomineeNname] = useState('');
  const [nominee_relation, setNomineeRelation] = useState('');
  const [nominee_cnic, setNomineeCnic] = useState('');
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState('');
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState('');
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState('');
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState('');
  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState('');
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState('');
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState('');
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState('');
  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState('');
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState('');
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState('');
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState('');
  const [portfolioManagement, setPortfolioManagement] = useState('');

  const [cdc_IAS, setCDCIAS] = useState('');
  const [cdc_subAccount, setCDCSubAccount] = useState('');
  const [cdc_participantID, setCDCParticipantID] = useState('');

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('clientObj') || '');
    const fundData = JSON.parse(sessionStorage.getItem('fund_data') || '');	
    // if (obj.balance_unit) {	
    //   setTotalFunds(JSON.parse(obj.balance_unit));	
    // }	
    if (obj.balance_unit) {	
      let data = JSON.parse(obj.balance_unit).map((item,index) => {
        let fName;
         fundData.map((elem)=>{
        	  if(item.fund_code===elem.symbol_code){
        	    fName = elem.fund_name;
        	  }
        	})
          return{...item,fundName:fName}
      })
      setTotalFunds(data)	
    }	
    setAccTitle(obj.account_title);
    setAccNo(obj.account_no);
    setPhone(obj.phone);
    setType(obj.type);
    setFather(obj.father_spouse);
    if (obj.tax_exempt === 'true') {
      setTax_exempt(true);
    } else {
      setTax_exempt(false);
    }
    if (obj.zakat_exempt === 'true') {
      setZakat_exempt(true);
    } else {
      setZakat_exempt(false);
    }
    setCnicExpiry(obj.cnic_expiry);
    setClientEmail(obj.email);
    setMainFolioNo(obj.main_folio_no);
    setBankName(obj.bank_name);
    getBranchByName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    setBlnUpdate(obj.balance_unit);
    setFolioNo(obj.folio_no.replace('UNITHOLDER_', ''));
    setCnic(obj.cnic);
    setName(obj.name);
    setNtn(obj.ntn);
    setAddress(obj.address);
    setMobile(obj.mobile);
    // getAllUnitHoldersX(obj.email, obj.account_no);
    // guardian Name
    setGuardian_name(obj.guardian_name);
    setGuardian_relation(obj.guardian_relation);
    setGuardian_cnic(obj.guardian_cnic);
    setNomineeNname(obj.nominee_name);
    setNomineeRelation(obj.nominee_relation);
    setNomineeCnic(obj.nominee_cnic);
    setJointHolderOneName(obj.joint_h_1_name);
    setJointHolderOneFather(obj.joint_h_1_father_spouse);
    setJointHolderOneCnic(obj.joint_h_1_cnic);
    setJointHolderTwoName(obj.joint_h_2_name);
    setJointHolderTwoFather(obj.joint_h_2_father_spouse);
    setJointHolderTwoCnic(obj.joint_h_2_cnic);
    setJointHolderThreeName(obj.joint_h_3_name);
    setJointHolderThreeFather(obj.joint_h_3_father_spouse);
    setJointHolderThreeCnic(obj.joint_h_3_cnic);
    setPortfolioManagement(obj.portfolio_management);
    obj.reinvest === 'true' ? setReinvestCheck(true) : setReinvestCheck(false);
    setCity(obj.city);
    // setFiler(obj.filer);
    // obj.filer === 'true' ? setFiler(true) : setFiler(false);
    setProvince(obj.province);
    setTaxStatus(obj.tax_status);
    setJointHolderOneTaxStatus(obj.joint_h1_tax_status);
    setJointHolderTwoTaxStatus(obj.joint_h2_tax_status);
    setJointHolderThreeTaxStatus(obj.joint_h3_tax_status);
    setCDCIAS(obj.cdc_ias);
    setCDCSubAccount(obj.cdc_sub_account);
    setCDCParticipantID(obj.cdc_participant_id);

  }, []);

  //error getting hooks
  const [accTitleError, setAccTitleError] = useState('');
  const [accNoError, setAccNoError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  // const [branchNameError, setBranchNameError] = useState('');
  // const [amcNameError, setAmcError] = useState('');
  const [folioNoError, setFolioNoError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [clientEmailError, setClientEmailError] = useState('');
  const [cnicExpiryError, setCnicExpiryError] = useState('');
  const [cnicError, setCnicError] = useState('');
  const [ntnError, setNtnError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [addressError, setAddressError] = useState('');
  // const [cityError, setCityError] = useState('');
  // const [provinceError, setProvinceError] = useState('');	
  const [nameError, setNameError] = useState('');
  //for dropdown data
  const [allbranchesData, setAllBranchesData] = useState<any>([]);


  const [allFunds, setAllFunds] = useState<any>([]);
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [totalFunds, setTotalFunds] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  // guardian

  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (err: any) {
    }
    setBranchLoading(false);
  };


  //render Fund table data
  const renderFundDataInTable = () => {
    return totalFunds.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>
            {`${items.fundName} (${items.fund_code})`}
          </td>
          <td className='text-right'>{items.fund_unit?numberWithCommas(parseFloat(items.fund_unit.toString().replaceAll(',','') || 0).toFixed(4)):parseFloat("0")}</td>
        </tr>
      );
    });
  };
  const borderRadiusStyle = { borderRadius: 2 };
  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/unit-holders')
                  }}></i>View - Unit Holder</h4>
                <Link
                  to="/admin/unit-holders"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Registration
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>AMC Name</label>
                        <div className="  disable">
                          <input
                            type="text"
                            className="form-control "
                            style={{ opacity: '1' }}
                            value={amcName}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Folio No</label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${folioNoError ? 'required-border' : ''
                              }`}
                            value={folioNo}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>Investor Type</label>
                            <div>
                              <input
                                readOnly
                                type="text"
                                className={'form-control  w-100'}
                                value={type}

                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <label>Portfolio Management</label>
                            <div>
                              <input
                                readOnly
                                type="text"
                                className={'form-control  w-100'}
                                value={portfolioManagement}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>


                      <div className="form-group d-none">
                        <label>Main Folio No (Optional)</label>
                        <div className=" ">
                          <input
                            type="text"
                            className="form-control"
                            value={mainFolioNo}
                            onChange={(e) => {
                              setMainFolioNo(e.target.value.toUpperCase());
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <div
                          className="form-control">
                          {taxStatus}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-4">

                        <div className="form-group d-flex">
                          <ToggleButton
                            value={tax_exempt}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={() => {
                              if (tax_exempt) {
                                setTax_exempt(false);
                              } else {
                                setTax_exempt(true);
                              }
                            }}
                          />
                          <label className="mt-2 ml-2">Tax Exempt</label>
                        </div>

                        <div className="form-group d-flex">
                          <ToggleButton
                            value={zakat_exempt}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={() => {
                              if (zakat_exempt) {
                                setZakat_exempt(false);
                              } else {
                                setZakat_exempt(true);
                              }
                            }}
                          />
                          <label className="mt-2 ml-2">Zakat Exempt</label>
                        </div>

                        <div className="form-group ">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                                checked={reinvestCheck} />
                              <span className="form-check-sign" />
                              Fund Dividend Reinvest
                            </Label>
                          </FormGroup>
                        </div>

                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Personal Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Client Name</label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={name}
                          />
                        </div>
                      </div>
                      {type === 'Individual' && (
                        <div className="form-group">
                          <label>Father/Spouse Name</label>
                          <div>
                            <input readOnly
                              type="text"
                              className={'form-control  w-100'}
                              value={father}
                              onChange={(e) => {
                                setFather(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {type === 'Individual' && (
                        <Row>
                          <Col md="8">
                            <div className="form-group  ">
                              <label>CNIC</label>
                              <div className=" ">
                                {/* <input
                              type="text"
                              className={`form-control  w-100 ${
                                cnicError ? 'required-border' : ''
                              }`}
                              value={cnic}
                            /> */}
                                <InputMask
                                  className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                    }`}
                                  mask="99999-9999999-9"
                                  value={cnic}></InputMask>
                              </div>
                            </div>

                          </Col>
                          <Col md="4">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div className=" ">
                                <input
                                  type="date"
                                  className={`form-control  w-100 ${cnicExpiryError ? 'required-border' : ''
                                    }`}
                                  value={cnicExpiry}
                                  disabled
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      )}
                      {type === 'Corporate' && (
                        <div className="form-group  ">
                          <label>NTN</label>
                          <div className=" ">
                            <input
                              type="number"
                              className={`form-control  w-100 ${ntnError ? 'required-border' : ''
                                }`}
                              value={ntn}
                            />
                          </div>
                        </div>
                      )}

                      {/* <FormGroup check>
                            	<Label check>
                          <input		
                            type="checkbox"		
                            // className={`form-control  w-100 ${		
                            //   ntnError ? 'required-border' : ''		
                            // }`}		
                            checked={filer}	
                            readOnly		
                          />
                          <span className="form-check-sign" />
                          Income Tax Return Filer
                          </Label>
                          </FormGroup>		 */}

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Contact
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Email</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${clientEmailError ? 'required-border' : ''
                              }`}
                            value={clientEmail}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Mobile</label>
                            <div className=" ">
                              {/* <input
                            type="number"
                            className={`form-control  w-100 ${
                              mobileError ? 'required-border' : ''
                            }`}
                            value={mobile}
                          /> */}

                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${mobileError ? 'required-border' : ''
                                  }`}
                                country={'pk'}
                                placeholder="+92 123-1234567"
                                value={mobile}
                              />

                            </div>
                          </div>
                        </Col>
                        <Col md="6">

                          <div className="form-group  ">
                            <label>Phone</label>
                            <div className=" ">
                              {/* <input
                        type="number"
                        className={`form-control  w-100 ${
                          phoneError ? 'required-border' : ''
                        }`}
                        value={phone}
                      /> */}

                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${phoneError ? 'required-border' : ''
                                  }`}
                                country={'pk'}
                                placeholder="+92 123-1234567"
                                value={phone}
                              />

                            </div>
                          </div>


                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Address</label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${addressError ? 'required-border' : ''
                              }`}
                            value={address}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col>
                          <div className="form-group  ">
                            <label>City</label>
                            <div className=" ">
                              <select className="form-control" value={city}>
                                <option value={city} defaultChecked hidden>
                                  {city}
                                </option>
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col>
                          <div className="form-group  ">
                            <label>Territories</label>
                            <div className=" ">
                              <select className="form-control" value={province}>
                                <option value={province} defaultChecked hidden>
                                  {province}
                                </option>
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Bank Account Detail
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Account Title</label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${accTitleError ? 'required-border' : ''
                              }`}
                            value={accTitle}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Account No</label>
                        <div className="  ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${accNoError ? 'required-border' : ''
                              }`}
                            value={accNo}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Bank Name</label>
                            <div className="  ">
                              <select className="form-control" value={bankname}>
                                <option value={bankname} defaultChecked hidden>
                                  {bankname}
                                </option>
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Branch Name</label>

                            <input type="text" className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                              }`}
                              value={branchName}
                            />

                            {/* <div className="" id="x">
                          {branchLoading ? (
                            <div className="form-control">
                              <div className="ml-2">Branch Loading</div>
                              <img
                                src="assets/spin-loader.svg"
                                className="ml-auto pb-2 center"
                                alt=""
                                width={40}
                                height={70}
                              />
                            </div>
                          ) : (
                            <select
                              className={`form-control  w-100 ${
                                branchNameError ? 'required-border' : ''
                              }`}
                              value={branchName}
                            >
                              <option value="" defaultChecked hidden>
                                {branchCheckFlag
                                  ? 'No Branch Found'
                                  : 'Select Branch Name'}
                              </option>
                              {renderBranchsDropdown()}
                            </select>
                          )}
                        </div> */}
                          </div>
                        </Col>
                      </Row>


                    </CardBody>
                  </Card>
                </Col>

                {/*add new cards  */}
                {type === 'Individual' && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Guardian Details
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="form-group">
                          <label>Guardian  Name   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={guardian_name}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian Relation   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={guardian_relation}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian CNIC   </label>
                          <div  >
                            {/* <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={allUnitHolders.guardian_cnic}		
                       	
                      />	 */}
                            <InputMask
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              mask="99999-9999999-9"
                              value={guardian_cnic}></InputMask>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}

                {type === 'Individual' && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Nominee Details
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <label>Nominee   Name   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={nominee_name}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee  Relation   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={nominee_relation}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee  CNIC   </label>
                          <div  >
                            {/* <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={allUnitHolders.nominee_cnic}		 
                      />		 */}
                            <InputMask
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              mask="99999-9999999-9"
                              value={nominee_cnic}></InputMask>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 1
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>   Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_1_name}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_1_father_spouse}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  CNIC   </label>
                        <div  >
                          {/* <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={allUnitHolders.joint_h_1_cnic}	 	
                      />		 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_1_cnic}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <div
                          className="form-control">
                          {joint_h_1_taxStatus}
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 2
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>   Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_2_name}
                          />

                        </div>
                      </div>
                      <div className="form-group">
                        <label>   Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_2_father_spouse}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  CNIC   </label>
                        <div  >
                          {/* <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={allUnitHolders.joint_h_2_cnic}		 
                      />		 	 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_2_cnic}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <div
                          className="form-control">
                          {joint_h_2_taxStatus}
                        </div>
                      </div>


                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 3
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>   Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_3_name}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_3_father_spouse}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  CNIC   </label>
                        <div  >
                          {/* <input		
                        type="text"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={allUnitHolders.joint_h_3_cnic}		 
                      />		 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_3_cnic}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <div
                          className="form-control">
                          {joint_h_3_taxStatus}
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        CDC
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Investment Account Service</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_IAS}

                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CDC Subaccount </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_subAccount}

                          />

                        </div>
                      </div>
                      <div className="form-group">
                        <label>CDC participant ID</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_participantID}

                          />


                        </div>
                      </div>


                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Funds Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      {totalFunds.length > 0 && (
                        // <div className="input-size-pos w-50 ml-auto mb-3 mx-3">
                        <div className="">
                          <table className="table">
                            <thead>
                              <tr>
                                <th >Fund Name</th>
                                <th className='text-right'>Fund Unit</th>
                              </tr>
                            </thead>
                            <tbody>{renderFundDataInTable()}</tbody>
                          </table>
                        </div>
                      )}

                    </CardBody>
                  </Card>
                </Col>


              </Row>

            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewClient;
