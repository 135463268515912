import React from "react";
import { useState, useRef } from "react";
import { Container, Col, Row } from "reactstrap";
import { Modal } from "react-bootstrap";
import { uploadMultipleUnitHolding } from "../../../stores/services/unit-holder.service";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import xls from "xlsx";
import { resetWarningCache } from "prop-types";
import { getFundByAmc } from "../../../stores/services/funds.service";
import { getAmcByCode } from "../../../stores/services/amc.service";

const csv = require("csvtojson/v2");
//const xlsx = require('xlsx-to-json');

const MultiUploadUnitHolding = () => {
  const email = sessionStorage.getItem("email") || "";
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileData, setfileData] = useState<any>([]);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [folio_no, setFolio_no] = useState("");

  const [viewApproveHoldingPop, setViewApproveHoldingPop] = useState(false);

  const renderModalAddInvestmentInFund = () => {
    switch (viewApproveHoldingPop) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
             enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewApproveHoldingPop(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approve Holding Update</h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          // defaultValue={underStand}
                          // className={
                          //   'form-control w-100 '
                          // }
                          onChange={(e) => {
                            setFlag(!flag);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Are you sure you want to change holdings.{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
                {flag && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={async () => {
                          if (file) {
                            try {
                              setLoading(true);
                              const res = await uploadMultipleUnitHolding(
                                email,
                                fileData,
                                "yes"
                              );
                              if (fileData.length <= 0) {
                                toast.error("No data Found!");
                              } else if (res.data.status == 200) {
                                toast.success(
                                  "Unit Holding uploaded successfully"
                                );
                                setViewApproveHoldingPop(false);
                                setFile("");
                                setFileName("");
                                fileData.length = 0;
                                setfileData(fileData);
                                setFileDataCount(0);
                              } else if (res.data.status == 400) {
                                setViewApproveHoldingPop(true);
                                setLoading(false);
                              } else {
                                toast.error(res.data.message);
                              }
                            } catch (err: any) {
                              setLoading(false);
                              if (err.response !== undefined) {
                                toast.error(err.response.data.message);
                              } else {
                                toast.error(err.message);
                              }
                            }
                            setLoading(false);
                          } else {
                            toast.error("Kindly Upload File");
                          }
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Save</span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const Uploadunitholding = async () => {
    if (file) {
      try {
        setLoading(true);
        const res = await uploadMultipleUnitHolding(email, fileData, "no");
        if (fileData.length <= 0) {
          toast.error("No data Found!");
        } else if (res.data.status == 200) {
          toast.success("Unit Holding uploaded successfully");
          setFile("");
          setFileName("");
          fileData.length = 0;
          setfileData(fileData);
          setFileDataCount(0);
        } else if (res.data.status == 400) {
          setViewApproveHoldingPop(true);
        } else {
          toast.error(res.data.message);
        }
      } catch (err: any) {
        setLoading(false);
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      setLoading(false);
    } else {
      toast.error("Kindly Upload File");
    }
  };

  const upload = async (e: any) => {
    fileData.length = 0;
    setFileError("");
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf(".") + 1);
    if (type === "xlsx" || type === "csv") {
      setFile(file);
      setFileName(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          defval: "",
          dateNF: "YYYY-MM-DD",
        });
        /* Update state */

        //  let jsonarray:any = [];
        if (data) {
          let emptyFundCodeCheck = false;
          let emptyFolioCheck = false;

          for (let i = 0; i < data.length; i++) {
            if (!data[i]["FundCode"] || data[i]["FundCode"].trim() === "") {
              emptyFundCodeCheck = true;
            }
            if (!data[i]["FolioNo"] || data[i]["FolioNo"].trim() === "") {
              emptyFolioCheck = true;
            }
          }

          var result: any = data.reduce((unique: any, o: any) => {
            //if (unique[0].AMCCode.trim().toUpperCase() !== 'REMOVE') {
            if (
              !unique.some(
                (obj) =>
                  obj.FolioNo === o.FolioNo && obj.FundCode === o.FundCode
              )
            ) {
              unique.push(o);
            }
            // }
            return unique;
          }, []);
          // if(data[0].AMCCode.trim().toUpperCase() === 'REMOVE' && data.length != result.length+1)
          // {
          //   toast.error('Duplication Found');
          //   setFileDataCount(0);
          // }

          let flag = false;
          for (let i = 1; i < data.length; i++) {
            if (
              (data[0].AMCCode.trim().toUpperCase() === "REMOVE"
                ? data[1].AMCCode
                : data[0].AMCCode) !== data[i].AMCCode
            ) {
              flag = true;
              break;
            }
          }

          if (flag === true) {
            toast.error("Amc code should be same.");
          } else if (emptyFolioCheck) {
            toast.error("Please Enter FolioNo");
          } else if (emptyFundCodeCheck) {
            toast.error("Please Enter FundCode");
          } else if (data.length !== result.length) {
            toast.error("Duplication Found");
            setFileDataCount(0);
          } else {
            let matchFundLength: any = [];
            const getFundTotalFundByAmc = async () => {
              try {
                const response = await getFundByAmc(
                  email,
                  result[0].AMCCode.trim().toUpperCase() === "REMOVE"
                    ? result[1].AMCCode
                    : result[0].AMCCode
                );
                const CheckAMCExist = await getAmcByCode(
                  email,
                  result[0].AMCCode.trim().toUpperCase() === "REMOVE"
                    ? result[1].AMCCode
                    : result[0].AMCCode
                );
                for (let i = 0; i < response.data.data.length; i++) {
                  result.find((item, k) => {
                    if (item.FundCode === response.data.data[i].symbol_code) {
                      matchFundLength.push(item.FundCode);
                      result[k].FundName = response.data.data[i].fund_name;
                    }
                  });
                }

                if (CheckAMCExist.data.status != 200) {
                  toast.error("Please Enter Valid AMC Code");
                  setFileDataCount(0);
                } else if (
                  result[0].AMCCode.trim().toUpperCase() === "REMOVE" &&
                  result.length != matchFundLength.length + 1
                ) {
                  toast.error("Fund not exist in AMC");
                  setFileDataCount(0);
                } else if (
                  result[0].AMCCode.trim().toUpperCase() !== "REMOVE" &&
                  matchFundLength.length !== result.length
                ) {
                  toast.error("Fund not exist in AMC");
                  setFileDataCount(0);
                } else {
                  setfileData(result);
                  setFileDataCount(result.length);
                }
              } catch (err: any) {
                if (err.response !== undefined) {
                  toast.error(err.response.data.message);
                } else {
                  toast.error("Request Failed!");
                }
              }
            };
            getFundTotalFundByAmc();
          }
        }
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } else {
      toast.error("Please Upload Correct Format of File");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="d-flex align-items-center"></div>

                <div className="">
                  <div className="card-header">
                    <h4 className="card-title">
                      Upload Multiple Unit Holding
                      <a
                        className="float-right"
                        href="./../../Uploader-Sample/Upload-Unit-Holding.xlsx"
                        download
                      >
                        Download Sample
                      </a>
                    </h4>
                  </div>
                  <div className="card-body">
                    {/* <div className="ml-3 mr-3"> */}
                    <div className="row">
                      <div className="col-md-4">
                        <label>Choose File</label>

                        <div onClick={() => myRef1?.current?.click()}>
                          <div className="form-group">
                            <div
                              className="form-control"
                              data-tip="Upload Unit Holding File"
                            >
                              {fileName === "" ? "Upload File" : fileName}
                            </div>
                            <input
                              type="file"
                              ref={myRef1}
                              style={{ display: "none" }}
                              multiple={false}
                              data-tip="Upload File"
                              onChange={(e) => {
                                upload(e);
                              }}
                              onClick={(e) => {
                                e.currentTarget.value = "";
                              }}
                            />
                            {fileError ? (
                              <p className="error-labels error-message2">
                                {fileError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {fileUploadingFlag && (
                          <i className="fa fa-spinner fa-spin fa-2x"></i>
                        )}
                      </div>
                      <div className="col-md">
                        <Link
                          to="/admin/unit-holders"
                          className="t-3 ml-auto btn btn-primary btn-sm float-right"
                          replace
                        >
                          <i className="fa fa-eye mr-2"></i> View All
                        </Link>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="table-responsive">
                      <JsonToTable json={fileData} />
                    </div>
                    Total Rows:{" "}
                    <span className="text-primary mt-3">{fileDataCount} </span>
                    <div className="">
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          Uploadunitholding();
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Save</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {renderModalAddInvestmentInFund()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MultiUploadUnitHolding;
