import React, { useState, useEffect} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { ImCheckmark } from 'react-icons/im';
import moment from 'moment';	
import ViewTransaction from '../transactions/view-beta-txn';	
import { searchTransactionsCompleted } from '../../stores/services/transactions.service';	
import { getAmc } from '../../stores/services/amc.service';	
import { getFundByAmc } from '../../stores/services/funds.service';	
import { BsTable } from 'react-icons/bs';	
import createDOMPurify from 'dompurify';
import ReactExport from "react-export-excel";
import { getBrokerByType } from '../../stores/services/broker.service';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const DOMPurify = createDOMPurify(window);

const TaxReport = () => {
  const email = sessionStorage.getItem('email') || '';

  let [transactions, setTransactions] = useState<any>([]);	
  let [status, setStatus] = useState('-1');	
  let [searchValue, setSearchValue] = useState('');	
  let [activePage, setActivePage] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	

  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [Amc , setAmc] = useState('');
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds , setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState('');

  const [companyType, setCompanyType] = useState('');

  const [allBroker , setAllBroker] = useState<any>([]);

  const [togglePagination, setTogglePagination] = useState(false);

  

  useEffect(() => {
      const brokerType = async () =>{
        try {
          const brokerResponse = await getBrokerByType(email, 'all');
          setAllBroker(brokerResponse.data.data);
        } catch (err: any) {
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message)
          }
        }
      }
      brokerType();
  }, [])
  
	

  useEffect(()=>{
    const getAMC = async () => {
        try{
            const amcResponse = await getAmc(email, '', '', '');
            setAllAmc(amcResponse.data.data);
             }catch(err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }
             }
    }
    getAMC();  
},[]);

 	

const renderAmcDropdown = () =>{
  return allAmc.map((item: any, index: string) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_','')}>
          {item.name}
        </option>
      );
    });
}

const getFunds=async(code:any)=>{
  try{
    allFunds.length=0;
    setAllFunds(allFunds);
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
  }catch(err: any) {
    if (err.response !== undefined) {
      toast.error(err.response.data.message);
    } else {
      toast.error(err.message)
    }
  }
}

// Fund Type Drop Down
const renderFundsDropDown = () =>{
  return allFunds.map((item:any , index)=>{
      return (
          <option key={index} value={item.symbol_code}>
              {item.fund_name} - {item.symbol_code}
          </option>
      );
  })
}

// Fund Type Drop Down
const renderCompanyTypeDropDown = () =>{
  return allBroker.map((item:any , index)=>{
      return (
          <option key={index} value={item.company_name}>
              {item.company_name} - {item.company_code}
          </option>
      );
  })
}


const searchTransactionsByAMCAndFund  = async(amcCodeVar= '', fundCodeVar ='',statusVar = '') => {
  setTogglePagination(true);	
  return new Promise(async(resolve, reject) => {
    setResLoading(true);
    setSearchLoading(true);
    await searchTransactionsCompleted(	
      sessionStorage.getItem('email') || '',
        amcCodeVar,
        fundCodeVar,
        statusVar === '-1' ? '' : statusVar,
    )	
      .then((response) => {	
        if(response.data.data.length>0){
        let completeTransaction = response.data.data.map((item:any,index:number)=>{
          let matchData = allBroker.find(elem=> {
            
            if(elem.company_code === item.counter_account_type)
            {
              return elem
            }
          });
          if(matchData!==undefined){
            return {
             ...item, paymentSection:matchData.payment_section, taxPayerNtn:matchData.ntn,taxPayerCnic:matchData.cnic,taxPayerRegisteredName:matchData.registered_name,taxPayerCity:matchData.city,taxPayerAddress:matchData.address,taxPayerCategory:matchData.category,taxPayerNatureOfBussiness:matchData.nature_of_bussiness
            }
          }
            
        })
        setTransactions(completeTransaction.filter(item => item !== undefined));
      }else{
        setTransactions([]);
      }
        setResLoading(false);	
        setSearchLoading(false);	

        resolve('Success');	

      })	
      .catch((err) => {	
        setResLoading(false);
        setSearchLoading(false);		
        reject('Error');	
      });	
      setResLoading(false);
      setSearchLoading(false);	
  });	
};

 
  const [setLoading] = useState(false);	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };
  	
  	

  // const  numberWithCommas = (amount:any) => {
  //   return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }


	
  let [changePass, setChangePAssword] = useState(false);	
  const renderContent = () => {	
    const borderRadiusStyle = { borderRadius: 2 };	
    if (!changePass) {	
      return (	
        <div>	
         {!view ? (	
            <div className="card-body">	
              <div className="">
                <Row>
                  <Col md="2">
                  <div className="form-group ml-2 mr-2"> 
                  <label >Select AMC</label>
                      <select
                          className={"form-control"}
                          value={Amc}
                          onChange={(e) => {
                          setAmc(e.target.value);
                          getFunds(e.target.value);
                          }}
                          >
                          <option value="" defaultChecked hidden>
                          Select AMC
                          </option>
                          {renderAmcDropdown()}
                      </select>
                    </div>
                  </Col>

                  <Col md="2">
                  <div className="form-group ml-2 mr-2">
                  <label >Select Fund</label>
                      <select
                          className={"form-control"}
                          value={fund}
                          onChange={(e) => {
                              setFund(e.target.value);
                          }}>
                          <option value="" defaultChecked hidden>
                          Select Fund
                          </option>
                          {renderFundsDropDown()}
                      </select>
                  </div>
                  </Col>

                  
                     
                  <Col md="4">
                  <div className=" inline-box">
                  <button	
                    className="btn btn-primary mt-4"
                    disabled={Boolean(searchLoading)}	
                    onClick={() => {		
                        searchTransactionsByAMCAndFund(Amc, fund,'COMPLETED')
                    }}	
                  >	
                      {searchLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Searching...</span>
                    </>
                    ) : (
                    'Search'
                    )}	
                  </button>	
                  </div>
                  </Col>
                {/* </div>	 */}

                </Row>
              {/* </div>	 */}
              </div>	

              
              <div className="">
                {resLoading ? (
                  <div className="d-flex justify-content-center my-5">	
                    <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                  </div>
                ):(
                transactions.length > 0 ? (	
                  <div className='table-responsive'>
                  <table className="table mt-2 ">	
                  <thead>	
                      <tr>	
                        <th className='ml-4'>Date</th>
                        <th>Payment Section</th>
                        <th>NTN</th>	
                        <th>CNIC</th>	
                        <th>Registered Name</th>
                        <th>City</th>
                        <th>Natrue Of Business</th>	
                        <th>Category</th>	
                        <th>Taxable Amount</th>	
                        <th>Tax Amount</th>	
                        <th>TXN Status</th>
                        {/* <ExcelColumn label="Payment Section" value="paymentSection"/>
                                    <ExcelColumn label="TaxPayer_NTN" value="taxPayerNtn"/>
                                    <ExcelColumn label="TaxPayer_CNIC" value="taxPayerCnic"/>
                                    <ExcelColumn label="TaxPayer_Name" value="taxPayerRegisteredName"/>
                                    <ExcelColumn label="TaxPayer_City" value="taxPayerCity"/>
                                    <ExcelColumn label="TaxPayer_Address" value="taxPayerAddress"/>
                                    <ExcelColumn label="TaxPayer_Status" value="taxPayerCategory"/>
                                    <ExcelColumn label="TaxPayer_Business_Name" value="taxPayerNatureOfBussiness"/>
                                    <ExcelColumn label="Taxable_Amount" value="gross_amount"/>
                                    <ExcelColumn label="Tax_Amount" value="tax_sst"/>	 */}
                      </tr>	
                    </thead>	
                    <tbody>	
                      {transactions.map((transaction: any, index: number) => {	
                        return (	
                          <tr key={index}>	
                          <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br/>
                          </td>

                          <td>{transaction.paymentSection} <br/>
                          </td>

                          <td>{transaction.taxPayerNtn} <br/>
                          </td>

                          <td>{transaction.taxPayerCnic} <br/>
                          </td>

                          <td>{transaction.taxPayerRegisteredName} <br/>
                          </td>

                          <td>{transaction.taxPayerCity} <br/>
                          </td>

                          <td>{transaction.taxPayerNatureOfBussiness} <br/>
                          </td>

                          <td>{transaction.taxPayerCategory} <br/>
                          </td>
                          
                          <td>{transaction.gross_amount} <br/>
                          </td>

                          <td>{transaction.tax_sst} <br/>
                          </td>

                            		
                            <td>{transaction.txn_status}</td>	
                            <td className=''>
                            </td> 
                            	
                          </tr>	
                        );	
                      })}	
                    </tbody>
                  </table>
                  <>
                    <ExcelFile element={
                    <button type="button" data-tip={'Download Excel file'} className="btn btn-primary pl-4 pr-4 text-center"
                    ><span>Tax IT <i className="fa fa-download icon"></i></span></button>
                    } filename="ABL_IDSF_(Mar_,_2021)_FBR">
                      <ExcelSheet data={transactions} name="Sheet1">
                        <ExcelColumn label="Payment Section" value="paymentSection"/>
                        <ExcelColumn label="TaxPayer_NTN" value="taxPayerNtn"/>
                        <ExcelColumn label="TaxPayer_CNIC" value="taxPayerCnic"/>
                        <ExcelColumn label="TaxPayer_Name" value="taxPayerRegisteredName"/>
                        <ExcelColumn label="TaxPayer_City" value="taxPayerCity"/>
                        <ExcelColumn label="TaxPayer_Address" value="taxPayerAddress"/>
                        <ExcelColumn label="TaxPayer_Status" value="taxPayerCategory"/>
                        <ExcelColumn label="TaxPayer_Business_Name" value="taxPayerNatureOfBussiness"/>
                        <ExcelColumn label="Taxable_Amount" value="gross_amount"/>
                        <ExcelColumn label="Tax_Amount" value="tax_sst"/>
                      </ExcelSheet>
                        </ExcelFile>
                   </>
                  </div>
                ) : (	
                  <div className="table-empty">	
                    <BsTable className="icon" />	
                    <p className="text-center empty-text">No record found</p>	
                  </div>	
                )
                )}	

              </div>	
            </div>	
        ) : (	
          <div className="">	
            	
            <h4>		
                  <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {		
                    setView(false);		
                  }}></i>		
                  View Transaction   
 
                </h4>		
            <ViewTransaction data={data} />	
            {/* <div className="form-holder">	
                {data ? <Row>{renderData()}</Row> : ''}	
              </div> */}	

            <div className="line"></div>	  
            
           
          </div>	
        )}		
        </div>	
      );	
    } else {	
      return (	
        <div>	
        {!view ? (	
           <div className="card-body">	
             <div className="">
               <Row>
                 <Col md="2">
                 <div className="form-group ml-2 mr-2"> 
                 <label >Select AMC</label>
                     <select
                         className={"form-control"}
                         value={Amc}
                         onChange={(e) => {
                         setAmc(e.target.value);
                         getFunds(e.target.value);
                         }}
                         >
                         <option value="" defaultChecked hidden>
                         Select AMC
                         </option>
                         {renderAmcDropdown()}
                     </select>
                   </div>
                 </Col>

                 <Col md="2">
                 <div className="form-group ml-2 mr-2">
                 <label >Select Fund</label>
                     <select
                         className={"form-control"}
                         value={fund}
                         onChange={(e) => {
                             setFund(e.target.value);
                         }}>
                         <option value="" defaultChecked hidden>
                         Select Fund
                         </option>
                         {renderFundsDropDown()}
                     </select>
                 </div>
                 </Col>

                 <Col md="4">
                 <div className=" inline-box">
                 <button	
                   className="btn btn-primary mt-4"
                   disabled={Boolean(searchLoading)}	
                   onClick={() => {		
                       searchTransactionsByAMCAndFund(Amc, fund,'COMPLETED')
                   }}	
                 >	
                     {searchLoading ? (
                   <>
                     <span
                       className="spinner-border login-txt spinner-border-sm"
                       role="status"
                       aria-hidden="true"
                     ></span>
                     <span className="login-txt"> Searching...</span>
                   </>
                   ) : (
                   'Search'
                   )}	
                 </button>	
                 </div>
                 </Col>
               {/* </div>	 */}

               </Row>
             {/* </div>	 */}
             </div>	

             
             <div className="">
               {resLoading ? (
                 <div className="d-flex justify-content-center my-5">	
                   <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                 </div>
               ):(
               transactions.length > 0 ? (	
                 <div className='table-responsive'>
                 <table className="table mt-2 ">
                 <thead>	
                      <tr>	
                        <th>Date</th>
                        <th>NTN</th>		
                        <th>Nature Of Business Name</th>
                        <th>Type</th>
                        <th>No Of Invoices</th>
                        <th>Sales Tax Charger</th>	
                        <th>Sales Tax Detucted</th>	
                        <th>TXN Status</th>	
                      </tr>	
                    </thead>	
                    <tbody>	
                      {transactions.map((transaction: any, index: number) => {
                        return (	
                          <tr key={index}>	
                          <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br/>
                          </td>

                          <td>{transaction.taxPayerNtn} <br/>
                          </td>

                          <td>{transaction.taxPayerRegisteredName} <br/>
                          </td>

                          <td>{transaction.taxPayerNatureOfBussiness} <br/>
                          </td>

                          
                          <td> <br/>
                          </td>

                          <td>{transaction.tax_sst} <br/>
                          </td>

                            		
                            <td>{transaction.tax_wht_sst}</td>	

                            <td>{transaction.txn_status}</td>

                           <td className="">
                           </td> 
                             
                         </tr>	
                       );	
                     })}	
                   </tbody>	
                 </table>
                 <>
                  <ExcelFile element={
                  <button type="button" data-tip={'Download Excel file'} className="btn btn-primary pl-4 pr-4 text-center"
                    >Tax SST <i className="fa fa-download icon"></i></button>
                  } filename="SSt_HBL_IDEF_Nov,_2019">
                    <ExcelSheet data={transactions} name="Sheet1">
                      <ExcelColumn label="TaxPayer_NTN" value="taxPayerNtn"/>
                      <ExcelColumn label="TaxPayer_Name" value="taxPayerRegisteredName"/>
                      <ExcelColumn label="Type" value="taxPayerNatureOfBussiness"/>
                      <ExcelColumn label="No_Of_Invoices" value=""/>
                      <ExcelColumn label="SalesTax_Charged" value="tax_sst"/>
                      <ExcelColumn label="SalesTax_Deducted" value="tax_wht_sst"/>
                    </ExcelSheet>
                      </ExcelFile>
                    </>
                 </div>
               ) : (	
                 <div className="table-empty">	
                   <BsTable className="icon" />	
                   <p className="text-center empty-text">No record found</p>	
                 </div>	
               )
               )}	

               
             </div>	
           </div>	
       ) : (	
         <div className="">	
             
           <h4>		
                 <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {		
                   setView(false);		
                 }}></i>		
                 View Transaction   

               </h4>		
           <ViewTransaction data={data} />	
           {/* <div className="form-holder">	
               {data ? <Row>{renderData()}</Row> : ''}	
             </div> */}	

           <div className="line"></div>	  
           
          
         </div>	
       )}		
       </div>
      );	
    }	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card">	
          <div className="d-flex align-items-center"></div>	
            <div className="">	
              <div className="card-header">	
              <h4 className="card-title">Tax Report</h4>	
              </div>	
              <div className="card-body">	
              <div className="w-100 d-flex">	
                <div	
                  onClick={() => {	
                    setChangePAssword(false);	
                  }}	
                  className={`card border shadow box-report user-header mr-2 w-50`}	
                >	
                  {!changePass && (	
                    <ImCheckmark className="tick-noti tick-img" />	
                  )}	
                	
                  <span className="card-title">Tax IT</span>	
                </div>	
                <div	
                  onClick={() => {	
                    setChangePAssword(true);
                    // fetchAllPsxFiles();	
                  }}	
                  className={`card border shadow box-report user-header w-50`}	
                >	
                  {changePass && <ImCheckmark className="tick-noti tick-img" />}	
                  <span className="card-title">Tax SST</span>	
                </div>	
              </div>	
              {renderContent()}	
              </div>	
              {/* <div className="line"></div> */}	
            </div>
        </div>	
      </Container>
      </Col>
        </Row>
      </div>	
    </>	
  );	
};	
export default TaxReport;
