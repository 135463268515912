import React from 'react';
import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Container, Col, Row } from 'reactstrap';
import {
  updateUnitHolderStatus,
  getUnitHoldersByAmcAndFolioNo,
  getAllActiveDeactiveUnitHoldersByAmcAndFolioNo
} from '../../../stores/services/unit-holder.service';
import { getAmc } from '../../../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import { BsTable } from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { sortAMCS } from '../../../utils/customFunction';
import { getFundByAmc } from '../../../stores/services/funds.service';

const ClientList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const myRef1 = useRef<HTMLInputElement>(null);
  const [isSearchActive, setSearchActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [fundData, setFundData] = useState<any>([]);
  const [pageSize, setPageSize] = useState('10');
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);

  const [allAmc, setAllAmc] = useState<any>([]);
  const [Amc, setAmc] = useState('');

  const [amcError, setAmcError] = useState('');

  React.useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])

  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [clientStatus, setClientStatus] = useState('');
  const [code, setCode] = useState('');
  const [clientLoading, setClientLoading] = useState(false);
  const updateStatus = async () => {
    setClientLoading(true);
    let status = '';
    if (clientStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateUnitHolderStatus(email, code, status);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setStatusSetPopup(false);
        searchUnitHolderData();
      } else {
        toast.error(response.data.message);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setClientLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(()=>{
    const getUnitHolderList = async () => {
      let folioNo = '';
      if (searchValue === '') {
        folioNo = '';
      } else {
        folioNo = Amc + '-' + searchValue;
      }
      
      try {
        setLoading(true);
        const response = await getAllActiveDeactiveUnitHoldersByAmcAndFolioNo(email, Amc, folioNo,activePage,pageSize);
        if (response.data.status === 200) {
          let pageArr = paginationArr;
          data.length=0
          setData([])
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10
            
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          setData([...response.data.data])
        } else {
          toast.error(response.data.message)
        }
        


      } catch (err: any) {
        
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
    {data.length>0 && getUnitHolderList()}

  },[activePage])
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  // function myFunction(e: any) {
  //   res.length = 0;
  //   setRes(res);
  //   var filter, td, i;
  //   filter = e.target.value;
  //   for (i = 0; i < data.length; i++) {
  //     td = data[i].folio_no;
  //     if (td) {
  //       if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
  //         res.push(data[i]);
  //         setRes(res);
  //       } else {
  //       }
  //     }
  //   }
  // }
  const [errorData, setErrorData] = useState<any>([]);

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const searchUnitHolderData = async () => {
    if (Amc === '') {
      setAmcError('required-border');
    } else {
      let folioNo = '';
      if (searchValue === '') {
        folioNo = '';
      } else {
        folioNo = Amc + '-' + searchValue;
      }
      setLoading(true);
      try {
        setActivePage('')
        const fundResponse = await getFundByAmc(email, Amc);
        const response = await getAllActiveDeactiveUnitHoldersByAmcAndFolioNo(email, Amc, folioNo,activePage,pageSize);
        setFundData(fundResponse.data.data)
        if (response.data.status === 200) {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          setData(response.data.data)
        } else {
          toast.error(response.data.message)
          data.length=0
          setData([])
        }
        
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
  }

  const renderUpdateBankStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            enforceFocus={false}
            show={true}
          >
            <div className="modal-header">
              <button aria-hidden="true" className="close" onClick={() => {
                setStatusSetPopup(false);
              }} data-dismiss="modal" type="button">
                <i className="tim-icons icon-simple-remove"></i></button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {clientStatus === 'active' ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{' '}
                    {clientStatus === 'active' ? 'Deactivate' : 'Activate'} this
                    Client?
                  </div>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary "
                        disabled={Boolean(clientLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {clientLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          'Yes'
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            enforceFocus={false}
            show={true}
          >

            <div className="modal-header">
              <button aria-hidden="true" className="close"
                onClick={() => {
                  setErrorBoolean(false);
                }} data-dismiss="modal" type="button">
                <i className="tim-icons icon-simple-remove"></i></button>
            </div>

            <div className="modal-body">
              <div>
                <Row>
                  <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div>
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.folio_no.replace("UNITHOLDER_", "")}</td>
          <td>{items.name}</td>
          <td>{items.cnic}</td>
          <td>{items.city}</td>
          <td>{items.email}</td>
          <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
          <td className="captilize">{items.status}</td>
          <td className="pointer">
            <div
              className="mr-3">
              {items.status !== 'active' ? (
                <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setClientStatus(items.status);
                    setCode(items.folio_no.replace("UNITHOLDER_", ""));
                  }}
                >
                  <i className="tim-icons icon-simple-remove"> </i>
                </button>
              ) : (
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setClientStatus(items.status);
                    setCode(items.folio_no.replace("UNITHOLDER_", ""));
                  }}>
                  <i className="fa fa-check"></i>
                </button>
              )}

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('clientObj', JSON.stringify(items));
                sessionStorage.setItem('fund_data', JSON.stringify(fundData));
                history.replace('/admin/view-client');
              }}>
                <i className="fa fa-eye"></i>
              </button>

              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                sessionStorage.setItem('clientObj', JSON.stringify(items));
                sessionStorage.setItem('fund_data', JSON.stringify(fundData));
                history.replace(`/admin/edit-client`);
              }}>
                <i className="tim-icons icon-pencil"></i>
              </button>

            </div>


          </td>
        </tr>
      );
    });
  };
  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="d-flex align-items-center"></div>

                <div className="">
                  <div className="card-header">
                    <h4 className="card-title">Client Listing</h4>
                  </div>
                  <div className="card-body">

                    
                    <div className="">
                      <Row>
                        <Col md="2">
                          <div className="form-group  ">
                            <label>Select AMC</label>
                            <select
                              className={"form-control " + amcError}
                              onChange={(e) => {
                                
                                setAmc(e.target.value);
                                setAmcError('');
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                Select AMC
                              </option>
                              {renderAmcDropdown()}
                            </select>
                          </div>
                        </Col>
                        <Col md="2">
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          <label>Enter Folio No.</label>
                          <div className="form-group">
                            <input
                              type="search"
                              id="myInput"
                              placeholder="Enter Folio Number"
                              data-tip="Folio No"
                              className="form-control"
                              value={searchValue}
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                                // myFunction(e);	
                                // handleSearchValue(e);	
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="2" >
                          <div className=" ">
                            <button className="btn btn-primary mt-4 mr-3"
                              disabled={Boolean(loading)}
                              onClick={searchUnitHolderData}>
                              {loading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Searching...</span>
                                </>
                              ) : (
                                <span> <i className="fa fa-search"></i>Search</span>
                              )}
                            </button>

                          </div>

                        </Col>
                        <Col>
                          <div className="float-right">
                            <button
                              className="btn btn-info btn-sm mt-a ml-4"
                              onClick={() => {
                                history.replace(`/admin/view-file`)
                              }}
                            >
                              <i className="fa fa-upload mr-2"></i>Upload
                            </button>
                            <button
                              className="btn btn-primary btn-sm mt-a ml-4"
                              onClick={() => {
                                history.replace(`/admin/upload-multiple-unitholding`)
                              }}
                            >
                              <span>  <i className="fa fa-upload mr-2"></i>Upload Multiple Unit Holding</span>
                            </button>
                            <button
                              className="btn btn-primary btn-sm ml-4 "
                              onClick={() => {
                                history.replace('/admin/setup-clients');
                              }}
                            >
                              <i className="fa fa-plus mr-2"></i> Add New
                            </button>
                          </div>
                        </Col>
                      </Row>

                      {/* </div> */}

                      {/* </div> */}


                    </div>
                    {res.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Folio No</th>
                              <th>Name</th>
                              <th>CNIC</th>
                              <th>City</th>
                              <th>Email</th>
                              <th>Registration Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{renderData()}</tbody>
                        </table>
                        {!isSearchActive && (
                          <div
                            className="row p-3 pointer"
                            style={{
                              float: 'right',
                            }}
                          >
                            {/* PREVIOUS BUTTON */}
                            {activePage !== paginationArr[0] ? (
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                                onClick={() => {
                                  let pageArr = paginationArr;
                                  let index = pageArr.findIndex((x) => x === activePage);
                                  if (index !== -1) {
                                    setActivePage(paginationArr[index - 1]);
                                  }
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                            {/* NEXT BUTTON */}
                            
                            {activePage !== paginationArr[paginationArr.length - 1] ? (
                              <i
                                className="fa ml-3 fa-arrow-right"
                                aria-hidden="true"
                                onClick={() => {
                                  let pageArr = paginationArr;
                                  let index = pageArr.findIndex((x) => x === activePage);
                                  if (index !== -1) {
                                    setActivePage(paginationArr[index + 1]);
                                  }
                                }}
                              ></i>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">No record found</p>
                      </div>
                    )}
                  </div>
                </div>
                {renderUpdateBankStatusPopup()}
                {renderErrorPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ClientList;