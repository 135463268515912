import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
/**
 *
 * @param email
 **/



const addStaff = async (
    email,
    name,
    father_name,
    staff_email,
    designation,
    mobile,
    phone,
    blood_group,
    cnic,
    staff_code,
    created_at,
    signature_specimen,
    status,
    picture,
) => {
    const url = `${Config.baseUrl}/staff/`;
    try {
        const result: any = await axios.post(
            url,
            {
                email,
                name,
                father_name,
                staff_email,
                designation,
                phone,
                mobile,
                blood_group,
                cnic,
                staff_code,
                created_at,
                signature_specimen,
                status,
                picture,
            },
            {
                headers: {
                    Authorization: sessionStorage.getItem('token') || '',
                },
            }
        );
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await addStaff(email,
                    name,
                    father_name,
                    staff_email,
                    designation,
                    phone,
                    mobile,
                    blood_group,
                    cnic,
                    staff_code,
                    created_at,
                    signature_specimen,
                    status,
                    picture,)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};
const updateStaff = async (
    email,
    name,
    father_name,
    staff_email,
    designation,
    mobile,
    phone,
    blood_group,
    cnic,
    staff_code,
    created_at,
    signature_specimen,
    status,
    picture,
) => {
    const url = `${Config.baseUrl}/staff/update`;
    try {
        const result: any = await axios.post(
            url,
            {
                email,
                name,
                father_name,
                staff_email,
                designation,
                phone,
                mobile,
                blood_group,
                cnic,
                staff_code,
                created_at,
                signature_specimen,
                status,
                picture,
            },
            {
                headers: {
                    Authorization: sessionStorage.getItem('token') || '',
                },
            }
        );
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await updateStaff(email,
                    name,
                    father_name,
                    staff_email,
                    designation,
                    phone,
                    mobile,
                    blood_group,
                    cnic,
                    staff_code,
                    created_at,
                    signature_specimen,
                    status,
                    picture,)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

const getAllStaff = async (
    email: string,
    page_number: string = '',
    page_size: string = ''
) => {
    const url = `${Config.baseUrl}/staff/paginate?email=${email}&page_number=${page_number}&page_size=${page_size}`;
    try {
        const result: any = await axios.get(url, {
            headers: {
                Authorization: sessionStorage.getItem('token') || '',
            },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await getAllStaff(email, page_number, page_size)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

const updateStaffStatus = async (
    email: string,
    staff_code: string,
    status: string
) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/staff/status`;
    try {
        const result: any = await axios.put(
            url,
            { email, staff_code, status },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await updateStaffStatus(email, staff_code, status)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

const searchAPI = async (email: string, search_value: string) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/staff/by-staff-name?email=${email}&name=${search_value}`;
    try {
        const result: any = await axios.get(url, {
            headers: {
                Authorization: token,
            },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await searchAPI(email, search_value)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};
const getAllStaffOption = async (email: string) => {
    const token = sessionStorage.getItem('token') || '';
    const url = `${Config.baseUrl}/staff?email=${email}`;
    try {
        const result: any = await axios.get(url, {
            headers: {
                Authorization: token,
            },
        });
        return result;
    } catch (err: any) {
        if (err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) {
            let responseToHandle = err.response.data
            let result = await RefreshTokenHandler.handleIt(responseToHandle)
            if (result.status) {
                return await getAllStaffOption(email)
            } else {
                throw err;
                // return result
            }
        } else {
            throw err
        }
    }
};

export {
    addStaff,
    getAllStaff,
    updateStaffStatus,
    updateStaff,
    searchAPI,
    getAllStaffOption
};
