import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { getAmc } from '../../../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  getFundByAmc,
  UpdateNavOfAllFunds,
  addDailyNav,
  getDailyNavOfAllFunds
} from '../../../stores/services/funds.service';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTable } from 'react-icons/bs';
import moment from 'moment';
import { convertToObject } from 'typescript';

const NavComponent = () => {
  let date = new Date();
  const history = useHistory();

  const email = sessionStorage.getItem('email') || '';
  const role = sessionStorage.getItem('role') || '';
  const [Loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [amcName, setAmcName] = useState('');
  const [amcNameError, setAmcError] = useState('');
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [NAVdate, setNAVDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [allDailyNav, setAllDailyNav] = useState<any>([]);
  const [navUpdate, setNavUpdate]=useState(false)


  React.useEffect(() => {
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };
    fetchAmc();
  }, [navUpdate]);

  // it just remove fund_ from Symbol_code 
  const extractKey = (symbolCode: any) => symbolCode.includes('FUND_') ? symbolCode.split('_')[1] : symbolCode;

  const getDailyNav = async () => {
    setAccFundLoading(true);
    allDailyNav.length = 0;
    setAllDailyNav(allDailyNav);
    try {
      const response = await getDailyNavOfAllFunds(email, amcName, NAVdate);
      let fooArray: any = Object.entries(response.data);
     

      let foo = fooArray.flat().map((item: any,index) => {
        if (typeof (item) !== 'string') {
          let total_asset: any = false;
          let flag_total_fund_u: any = false;
          let flag_nav: any = false;
          let flag_offer_p: any = false;
          let flag_redemption_p: any = false;
          if (role === 'ADMIN') {
            if (item.sma !== true) {
              return item;
            }
          } else {
            if (item.total_net_asset > 0) {
              total_asset = true;
            } else {
              total_asset = false
            }

            if (item.total_fund_units > 0) {
              flag_total_fund_u = true
            } else {
              flag_total_fund_u = false
            }
            if (item.nav > 0) {
              flag_nav = true
            } else {
              flag_nav = false
            }
            if (item.redemption_price > 0) {
              flag_redemption_p = true
            } else {
              flag_redemption_p = false
            }
            if (item.offer_price > 0) {
              flag_offer_p = true
            } else {
              flag_offer_p = false
            }

            if (item.sma !== true) {
              return { ...item, flag_total_asset: total_asset, flag_nav: flag_nav, flag_total_fund_units: flag_total_fund_u, flag_offer_price: flag_offer_p, flag_redemption_price: flag_redemption_p }
            }
          }
        }
      })
      setAllDailyNav(foo.filter(x => x !== undefined));
      // setAllDailyNav(dailynav => ([...dailynav, ...allDailyNav]));
      setAccFundLoading(false);
    } catch (err: any) {
      setAccFundLoading(false);
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setAccFundLoading(false);
  };


  const [code, setCode] = useState('');
  const [codeB, setCodeB] = useState(false);
  //render dropdown for amc data
  const renderAmcDropdown = () => {
    return amcdata.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name} ({item.amc_code.replace('AMC_', '')})
        </option>
      );
    });
  };

  const AddNavValue = async () => {
    setUpdateLoading(true);
    try {
      const response = await addDailyNav(email, allDailyNav);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        allDailyNav.length = 0;
        setAllDailyNav(allDailyNav);
        setAmcName('')
        setNavUpdate(!navUpdate)

      } else {
        toast.error(response.data.message)
      }
      setUpdateLoading(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
      setUpdateLoading(false);
    }
  };

  const decimalValue = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const renderData = () => {
    
    return allDailyNav.map((items: any, index: any) => {
      
     
      if(items.verified )
      {
        if(items.verified.toString().toLowerCase()==="true" || items.verified==true)
        {
          items.verified=true
        }else 
        {
          items.verified=false
        }
       
      }else{
        items.verified=false
      }
      return (
        <tr key={index}>
          <td>{items.fund}</td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_net_asset || 0}
                readOnly={items.flag_total_asset}
                id={`txt-tna-${index}`}
                onChange={(e) => {
                  items.total_net_asset = decimalValue(e.target.value, 2);
                  items.nav = (isFinite(items.total_net_asset / items.total_fund_units) ? items.total_net_asset / items.total_fund_units : 0).toFixed(4) || 0;
                  setCode(e.target.value);

                }}
              />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_fund_units || 0}
                readOnly={items.flag_total_fund_units}
                id={`txt-tfu-${index}`}
                onChange={(e) => {
                  items.total_fund_units = decimalValue(e.target.value, 4);
                  items.nav = (isFinite(items.total_net_asset / items.total_fund_units) ? items.total_net_asset / items.total_fund_units : 0).toFixed(4) || 0;
                  setCode(e.target.value);
                }}

              />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.nav || 0}
                // value={items.nav || 0}
                readOnly={items.flag_nav}
                onChange={(e) => {
                  var a = decimalValue(e.target.value, 4);
                  items.nav = a.replace("-","");
                  setCode(e.target.value.replace("-",""));
                }}
              />
            </div>

          </td>
          <td>
            <input type="number" className="form-control"
              value={items.offer_price || 0}
              readOnly={items.flag_offer_price}
              onChange={(e) => {
                var a = decimalValue(e.target.value, 4);
                items.offer_price = a || 0;
                setCode(e.target.value);
              }}
            />
          </td>
          <td>
            <input type="number" className="form-control"
              value={items.redemption_price || 0}
              readOnly={items.flag_redemption_price}
              onChange={(e) => {
                var a = decimalValue(e.target.value, 4);
                items.redemption_price = a || 0;
                setCode(e.target.value);
              }}
            />
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.net_assets_before_fee || 0}
                //readOnly={items.net_assets_before_fee}
                id={`txt-tna-${index}`}
                onChange={(e) => {
                  items.net_assets_before_fee = decimalValue(e.target.value, 4);
                  setCode(e.target.value);

                }}
              />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.trustee_fee || 0}
                //readOnly={items.trustee_fee}
                id={`txt-tna-${index}`}
                onChange={(e) => {
                  items.trustee_fee = decimalValue(e.target.value, 4);
                  setCode(e.target.value);
                }}
              />
            </div>
          </td>
          <td>
            <div className="form-group">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={items.verified==="true" ||items.verified===true? true:false }
                  onChange={(e) => {
                    items.verified=e.target.checked.toString()
                    setCode(e.target.checked.toString()+index.toString());
                  }}
                /> 
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Change NAV of Funds</h4>
                </div>
                {!Loading ? (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Select AMC</label>
                          <select
                            className="form-control"
                            data-show-subtext="true"
                            data-live-search="true"
                            onChange={(e) => {
                              setAmcError('');
                              setAmcName(e.target.value);
                              //getfundByAmcCode(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select An AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                        {accFundLoading && (
                          <i className="fa fa-spinner fa-spin fa-2x ml-2 mt-4" ></i>
                        )}
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>NAV Date:</label>
                          <input type="date" className="form-control"
                            value={NAVdate}
                            onChange={(e) => {
                              setNAVDate(e.target.value);

                            }} />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <label ></label>
                        <button className="btn btn-primary btn-block"
                          disabled={Boolean(accFundLoading)}
                          onClick={() => getDailyNav()}>Load</button>
                      </div>
                      
                      <div className=" col-md-2   justify-content-right">
                        <label ></label>
                        <button className="btn btn-primary btn-block"
                          
                          onClick={() => history.replace("/admin/setup-net-upload-assets")}>Bulk Upload</button>
                      </div>

                    </div>
                    {allDailyNav.length > 0 ? (
                      <table className="table" id="myTable">
                        <thead>
                          <tr>
                            <th>Fund</th>
                            <th>Total Net Assets</th>
                            <th>Total Fund Units Issued</th>
                            <th>Daily NAV</th>
                            <th>Offer Price</th>
                            <th>Redemption Price</th>
                            <th>Net Assets Before Fee</th>
                            <th>Trustee Fee</th>
                            <th>Verified</th>
                          </tr>
                        </thead>
                        <tbody id="myUL">{renderData()}</tbody>
                      </table>
                    ) : accFundLoading ? (
                      ''
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        {amcName === '' ? (
                          <p className="text-center empty-text">Select AMC</p>
                        ) : (
                          <p className="text-center empty-text">Empty</p>
                        )}
                      </div>
                    )}

                    {allDailyNav.length > 0 && (
                      <div className="">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            AddNavValue();
                          }}
                          disabled={Boolean(updateLoading)}
                        >
                          {updateLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-lg"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center my-5">
                    <i className="fa fa-spinner fa-spin fa-3x" ></i>
                  </div>
                )}

              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NavComponent;