
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAccountByFund } from '../../stores/services/account.service';
import { liabilityReceivableReport } from '../../stores/services/report.service';
import { numberWithCommas, sortAMCS } from '../../utils/customFunction';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';

const LiabilityReceivableReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [responseData, setResponseDate] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [fundAccountNo, setFundAccountNo] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resFundAccountNo, setResFundAccountNo] = useState('');
  const [resAccountTitle, setResAccountTitle] = useState('');
  const [resBank, setResBank] = useState('');
  const [resBranch, setResBranch] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [totalRealizedDebit, setTotalRealizedDebit] = useState('');
  const [totalRealizedCredit, setTotalRealizedCredit] = useState('');
  const [totalPostedDebit, setTotalPostedDebit] = useState('');
  const [totalPostedCredit, setTotalPostedCredit] = useState('');
  const [totalNet, setTotalNet] = useState('');
  const [totalDebitDiff, setTotalDebitDiff] = useState('');
  const [totalCreditDiff, setTotalCreditDiff] = useState('');

  const [amcErr, setAmcErr] = useState('');
  const [fundErr, setFundErr] = useState('');
  const [fundAccountNoErr, setFundAccountNoErr] = useState('');

  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  const getFundAccounts = async (fund_code) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getAccountByFund(email, fund_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  }


  const validate = () => {
    var count = 0;
    if (Amc === '') {
      setAmcErr('required-border');
      count++;
    }
    if (fund === '') {
      setFundErr('required-border');
      count++;
    }
    if (fundAccountNo === '') {
      setFundAccountNoErr('required-border');
      count++;
    }
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  }

  //code for search data for Reports

  const searchData = async () => {
    try {
      setLoading(true);
      let isValid = validate();
      if (isValid) {
        responseData.length = 0;
        setResponseDate(responseData);
        const response = await liabilityReceivableReport(email, fundAccountNo, fromDate, toDate);
        setResAmc(Amc);
        setResFund(fund);
        setResFundAccountNo(fundAccountNo)
        setResAccountTitle(accountTitle);
        setResBank(bank);
        setResBranch(branch);
        setResFromDate(fromDate);
        setResToDate(toDate);
        let realizeddebit = 0, realizedcredit = 0, posteddebit = 0, postedcredit = 0, net = 0;
        response.data.data.map((item: any, index: number) => {
          if (item.realized === 'true') {
            item['realized_debit_amount'] = item.debit_amount;
            item['realized_credit_amount'] = item.credit_amount;
          } else {
            item['realized_debit_amount'] = '0';
            item['realized_credit_amount'] = '0';
          }
          responseData.push(item);
        })

        responseData.map((item: any, index: number) => {
          realizeddebit += parseFloat(item.realized_debit_amount || '0');
          realizedcredit += parseFloat(item.realized_credit_amount || '0');
          posteddebit += parseFloat(item.debit_amount || '0');
          postedcredit += parseFloat(item.credit_amount || '0');
        })

        setTotalRealizedDebit(realizeddebit.toFixed(2));
        setTotalRealizedCredit(realizedcredit.toFixed(2));
        setTotalPostedDebit(posteddebit.toFixed(2));
        setTotalPostedCredit(postedcredit.toFixed(2));
        let debitDiff = posteddebit - realizeddebit;
        let creditDiff = postedcredit - realizedcredit;
        setTotalDebitDiff(debitDiff.toFixed(2));
        setTotalCreditDiff(creditDiff.toFixed(2));
        net = debitDiff - creditDiff;
        setTotalNet(net.toFixed(2));
      }
      setLoading(false);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
      setLoading(false);
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Liability {'&'} Receivable Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className={"form-control " + amcErr}
                            onChange={(e) => {
                              setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFunds(e.target.value);
                              setAmcErr('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className={"form-control " + fundErr}
                            onChange={(e) => {
                              setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFundAccounts(e.target.value);
                              setFundErr('');
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund Account</label>
                          <select
                            className={"form-control " + fundAccountNoErr}
                            onChange={(e) => {
                              setFundAccountNo(e.target.value);
                              var AccountTitle = e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setAccountTitle(AccountTitle.split('(')[1].replace(')', ''));
                              setFundAccountNoErr('');
                              let filterData = allFundAccounts.filter((item: any, index: number) => {
                                return e.target.value == item.account_no;
                              });
                              setBank(filterData[0].bank_name);
                              setBranch(filterData[0].branch_name);
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund Account
                            </option>
                            {renderFundAccountsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Liabilty-and-Receivable-Report-" + date + ".pdf"} ref={pdfExportComponent}>
                        {responseData.length > 0 ? (
                          <>
                            <ReportHeader title="Liability & Receivable Report" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Liability {'&'} Receivable Report</h3>
                  </div> */}
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Account Title:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAccountTitle}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Account No:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFundAccountNo}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Bank:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resBank} {'-'} {resBranch}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  From Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  To Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resFund}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account Title:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resAccountTitle}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account No:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resFundAccountNo}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Bank:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resBank} {'-'} {resBranch}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>


                            </Row> */}

                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr>
                                    <th colSpan={3}></th>
                                    <th colSpan={2} className="text-center">Posted</th>
                                    <th colSpan={2} className="text-center">Realized</th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th>Txn ID </th>
                                    <th>Posting Date</th>
                                    <th>Description </th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit</th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit</th>
                                    <th>Realized Date </th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {responseData.map((item: any, index: string) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{item.txn_id}</td>
                                          <td>{item.created_at !== '' ? moment(item.created_at).format('DD-MM-YYYY') : ''}</td>

                                          <td>{item.description.split('-')[0]} {'-'} {CapTxnType(item.description.split('-')[1])}  </td>
                                          <td className="text-right">{numberWithCommas(item.debit_amount)} </td>
                                          <td className="text-right">{numberWithCommas(item.credit_amount)} </td>
                                          <td className="text-right">{numberWithCommas(item.realized_debit_amount)} </td>
                                          <td className="text-right">{numberWithCommas(item.realized_credit_amount)} </td>
                                          <td>{item.realized_date !== '' ? moment(item.realized_date).format('DD-MM-YYYY') : ''}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <td colSpan={8}></td>
                                  </tr>

                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th>Total</th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-right"> {numberWithCommas(totalRealizedDebit)} </th>
                                    <th className="text-right"> {numberWithCommas(totalRealizedCredit)} </th>
                                    <th className="text-right"> {numberWithCommas(totalPostedDebit)} </th>
                                    <th className="text-right"> {numberWithCommas(totalPostedCredit)} </th>
                                  </tr>
                                  <tr>
                                    <th colSpan={2}>
                                    </th>
                                    <th className="text-right">Un-Realized</th>
                                    <th className="text-right">{numberWithCommas(totalDebitDiff)} Dr</th>
                                    <th className="text-right">{numberWithCommas(totalCreditDiff)} Cr</th>
                                    <th></th>
                                    <th className="text-right">{'Net '}{totalNet.startsWith('-') ? 'Payable ' : 'Receivable '}</th>
                                    <th className="text-right">{numberWithCommas(totalNet)}</th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>

                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LiabilityReceivableReport;
