import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import {
  getFundByAmc,
  getFundByAMCandFundCode,
  getFunds,
  netAssetsUpload,
} from "../../../stores/services/funds.service";
import { getAmc } from "../../../stores/services/amc.service";
import { JsonToTable } from "react-json-to-table";
import xls from "xlsx";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { isNumber, numberWithCommas } from "../../../utils/customFunction";
import {
  addInvoice,
  searchInvoice,
} from "../../../stores/services/invoice.service";
import NumberFormat from "react-number-format";

const DailyFeeupload = () => {
  let date = new Date();
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allAMCFund, setAllAMCFund] = useState<any>([]);
  const [errorShow, setErrorShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState("");
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [fileData, setfileData] = useState<any>([]);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [invalidFormatData, setInvalidFormatData] = useState<any>([]);
  const [invalidFormatPopup, setInvalidFormatPopup] = useState(false);
  const [refreshFund, setrefreshFund] = useState(false);
  const [AmcFundName, setAMCFundName] = useState("");
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [invalidTrusteeFee, setInvalidTrusteeFee] = useState<any>([]);
  const [period, setPeriod] = useState("");
  const [taxType, setTaxType] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [head, setHead] = useState("");
  const [showGenerateInvoiceFlag, setShowGenerateInvoiceFlag] = useState(false);
  const [headOptions, setHeadOptions] = useState([
    { value: "Trustee Fee", label: "Trustee Fee" },
    { value: "Custody Fee", label: "Custody Fee" },
    { value: "Other", label: "Other" },
  ]);
  const [ViewAddHead, setViewAddHead] = useState(false);
  const [ViewAddPeriod, setViewAddPeriod] = useState(false);

  const [taxTypeOptions, setTaxTypeOptions] = useState([
    { value: "SST", label: "SST" },
    { value: "PST", label: "PST" },
  ]);
  const [periodOptions, setPeriodOptions] = useState([
    { value: "Other", label: "Other" },
  ]);
  const [allPeriod, setAllPeriod] = useState<any>([]);
  const [allInvoice, setAllInvoice] = useState<any>([]);
  const [dueDate, setDueDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [flag, setFlag] = useState(false);
  const [matchPflag, setMatchPFlag] = useState(false);
  const [feeSlabflag, setFeeSlabFlag] = useState(false);
  const [startDate, setStartDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [invalidApproveTruseeFee, setInvalidApproveTruseeFee] = useState(false);
  const [previousBalance, setPreviousBalance] = useState("0");
  const [addPreviousBalance, setAddPreviousBalance] = useState(true);
  const [errorShowPeriodModal, setErrorShowPeriodModal] = useState(false);
  const [matchPeriodFlag, setMatchPeriodFlag] = useState(false);
  const [periodNameModal, setPeriodNameModal] = useState("");
  const [errorShowGenerateInvoice, setErrorShowGenerateInvoice] =
    useState(false);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [feeSlabWarning, setFeeSlabWarning] = useState(false);
  const [calculateTrusteeFee, setCalculateTrusteeFee] = useState<any>([])
  const email = sessionStorage.getItem("email") || "";
  let sum_trustee_fee = 0;
  let sum_tax_on_trustee_fee = 0;
  let sum_calculated_trustee_fee = 0;
  let sum_management_fee = 0;
  let sum_tax_on_mgt_fee = 0;
  let sum_calculated_mgt_fee = 0;
  let sum_net_amount = 0;
  let sum_tax_rate = 0

  React.useEffect(() => {
    const getAllFunds = async () => {
      setAMCFundName("");
      fileData.length = 0;
      setfileData(fileData);
      setFileName("");
      setShowData(false);
      setShowGenerateInvoiceFlag(false);
      setErrorShow(false);
      setFeeSlabWarning(false);
      setFeeSlabFlag(false)
      setErrorShowGenerateInvoice(false);
      setErrorShowPeriodModal(false);
      setInvalidApproveTruseeFee(false);
      calculateTrusteeFee.length = 0
      setCalculateTrusteeFee([])
      setStartDate(moment(date).format("YYYY-MM-DD"));
      setEndDate(moment(date).format("YYYY-MM-DD"));
      setDueDate(moment(date).format("YYYY-MM-DD"))
      const response = await getFunds(email);
      let tempAmc = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.amc_code} - ${val.symbol_code}`,
          value: `${val.amc_code} - ${val.symbol_code}`,
        };
      });
      setAllAMCFund(tempAmc);
    };

    getAllFunds();
  }, [refreshFund]);

  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  const ErrorBorderOnAddPeriodModal = (value) => {
    if (errorShowPeriodModal && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  const ErrorBorderGenerateInvoice = (value) => {
    if (
      errorShowGenerateInvoice &&
      (value ? value.toString().trim() === "" : value == "")
    ) {
      return "required-border";
    } else {
      return "";
    }
  };

  const renderModalPeriodWarning = () => {
    switch (matchPeriodFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setMatchPeriodFlag(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                Period Match
              </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setMatchPFlag(!matchPflag);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Are you sure you want to upload same period!{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
                {matchPflag && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          UploadInvoice(true);
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Upload</span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const UploadInvoice = async (value) => {
    // allPeriods
    const match_period = allPeriod.filter(item => (item.period === period));
    try {
      if (match_period.length > 0 && !matchPflag) {
        setMatchPeriodFlag(true);
      } else
        if (
          !period ||
          (period && period.trim() == "") ||
          !taxType ||
          taxType == "" ||
          head.trim() == "" ||
          // !sum_tax_on_mgt_fee ||
          // (sum_tax_on_mgt_fee && sum_tax_on_mgt_fee.toString().trim() == "") ||
          !sum_net_amount ||
          (sum_net_amount && sum_net_amount.toString().trim() == "")
          //||
          // !sum_calculated_mgt_fee ||
          // (sum_calculated_mgt_fee &&
          //   sum_calculated_mgt_fee.toString().trim() == "")

        ) {
          setMatchPeriodFlag(false);
          setErrorShowGenerateInvoice(true);
        } else if (value && invalidTrusteeFee.length > 0) {
          setMatchPeriodFlag(false);
          setFlag(false);
          setInvalidApproveTruseeFee(true);
        } else {
          setLoading(true);

          try {
            let head_detail = [
              {
                head_name: head,
                gross_amount: sum_trustee_fee
                  ? sum_trustee_fee.toString().trim()
                  : "0",
                net_amount: sum_net_amount
                  ? sum_net_amount.toString().trim()
                  : "0",
                period: period,
                tax_type: taxType,
                tax_rate: sum_tax_rate.toString(),
                tax_amount: sum_tax_on_trustee_fee
                  ? sum_tax_on_trustee_fee.toString().trim()
                  : "0",
                due_date: dueDate,
              },
            ];
            if (addPreviousBalance && parseFloat(previousBalance) != 0) {
              head_detail.push({
                head_name: "Previous Balance",
                gross_amount: previousBalance,
                net_amount: previousBalance,
                period: period,
                tax_type: taxType,
                tax_rate: "0",
                tax_amount: "0",
                due_date: dueDate,
              });
              sum_net_amount = parseFloat(sum_net_amount.toString()) + parseFloat(previousBalance)
              sum_calculated_mgt_fee = parseFloat(sum_calculated_mgt_fee.toString()) + parseFloat(previousBalance)


            }
            let total_gross = addPreviousBalance ? parseFloat(sum_trustee_fee.toString() || "0") + parseFloat(previousBalance?.toString() || "0") : sum_trustee_fee
            const res = await addInvoice(
              email,
              AmcFundName.split("-")[0],
              AmcFundName.split("-")[1],
              dueDate.toString(),
              period,
              sum_tax_on_trustee_fee.toString(),
              sum_net_amount.toString(),
              total_gross.toString(),
              JSON.stringify(head_detail),
              startDate.toString(),
              endDate.toString()
            );
            if (res.data.status == 200) {
              setrefreshFund(!refreshFund);
              toast.success(res.data.message);
              fileData.length = 0;
              setfileData(fileData);
              setFileName("");
              setHead("");
              setTaxType("");
              setTaxRate("");
              setPeriod("");
              setShowData(false);
              setShowGenerateInvoiceFlag(false);
              setStartDate(moment(date).format("YYYY-MM-DD"));
              setEndDate(moment(date).format("YYYY-MM-DD"));
              setDueDate(moment(date).format("YYYY-MM-DD"))
              setInvalidApproveTruseeFee(false);
              setErrorShowGenerateInvoice(false);
              setErrorShowPeriodModal(false);
              invalidTrusteeFee.length = 0;
              setInvalidTrusteeFee(invalidTrusteeFee);
            } else {
              toast.error(res.data.message);
            }
            setLoading(false);
          } catch (error: any) {
            if (error.response.data.status == 500) {
              toast.error(error.response.data.message);
            }
            // else if(error.response.data.status==400){
            //   setErrorBoolean(true);
            //   setErrorData(error.response.data.data);
            // }
            else {
              toast.error(error.response.data.message);
            }
          }
          setLoading(false);
        }
    } catch (err: any) {
      setLoading(false);
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };


  const upload = async (e: any) => {
    setFileError("");
    setfileData(null);
    invalidFormatData.length = 0;
    setInvalidFormatData(invalidFormatData);
    invalidTrusteeFee.length = 0;
    setInvalidTrusteeFee(invalidTrusteeFee);
    setShowData(false);
    setShowGenerateInvoiceFlag(false);
    setStartDate(moment(date).format("YYYY-MM-DD"));
    setEndDate(moment(date).format("YYYY-MM-DD"));
    setDueDate(moment(date).format("YYYY-MM-DD"))
    setInvalidApproveTruseeFee(false);
    let regExp = /[a-zA-Z]/g;
    let file = e?.target.files[0];
    let filesize = parseInt(file.size);
    if (filesize <= 2097152) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (type === "xlsx" || type === "csv") {
        setFile(file);
        setFileName(file.name);
        //start
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target?.result;
          const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data: any = xls.utils.sheet_to_json(ws, {
            header: 2,
            raw: false,
            defval: "",
            dateNF: "YYYY-MM-DD",
          });
          for (let i = 0; i < data.length; i++) {
            if (
              !data[i]["Date"] ||
              !moment(data[i]["Date"], "YYYY-MM-DD", true).isValid()
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "Date",
                message: !data[i]["Date"] ? "empty field" : "invalid date",
              });
              setInvalidFormatData([...temp]);
            }
          }

          /* Update state */
          if (invalidFormatData.length > 0) {
            setFileError("");
            setFile("");
            setFileName("");
            setfileData([]);

            setFileDataCount(0);
            setInvalidFormatPopup(true);
            setShowGenerateInvoiceFlag(false);
          } else if (data && invalidFormatData.length == 0) {
            setfileData(data);
            setFileDataCount(data.length);
          }
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      } else {
        toast.error("Please Upload Correct Format of File");
      }
    } else {
      toast.error("File size should be less than 2MB");
    }
  };

  const renderinvalidFormatPopup = () => {
    switch (invalidFormatPopup) {
      case true:
        return (
          <Modal className=" " dialogClassName="modal60w"
            enforceFocus={false}
            show={true}>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setInvalidFormatPopup(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Kindly Fix the below error </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                        <h1 className="pb-3">Kindly fill following fields data</h1>
                        Missing Fields
                      </div> */}
                  <div className={"d-flex border w-100"}>
                    <div className={"w-50"}>Row Number</div>
                    <div className={"w-50"}>Field Name</div>
                    <div className={"w-50"}>Error</div>
                  </div>
                  {invalidFormatData.map((item, index) => {
                    return (
                      <div className={"d-flex w-100"} key={index}>
                        <div className={"w-50"}>{item.row_number}</div>
                        <div className={"w-50"}>{item.field_name}</div>
                        <div className={"w-50"}>{item.message}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const GetFundData = async () => {
    try {
      if (!AmcFundName) {
        setErrorShow(true);
      } else if (
        !fileData ||
        fileName == "Upload File" ||
        fileData.length == 0 ||
        !file
      ) {
        toast.error("Kindly Upload File");
      } else {
        setErrorShowGenerateInvoice(false);
        setFeeSlabFlag(false)
        calculateTrusteeFee.length = 0
        setCalculateTrusteeFee([])

        let response = await getFundByAMCandFundCode(
          email,
          AmcFundName.split("-")[0].trim(),
          AmcFundName.split("-")[1].trim()
        );
        if (response.data.data.length > 0) {
          setPreviewLoading(true);
          let fee_slab = response.data.data[0].fee_slab
            ? JSON.parse(response.data.data[0].fee_slab)
            : [];
          let flag = false
          for (let i = 0; i < fileData.length; i++) {
            flag = false
            for (let j = 0; j < fee_slab.length; j++) {
              if (
                parseFloat(fileData[i]["Pre Net Assets"].replaceAll(",", "")) >=
                parseFloat(fee_slab[j].slab_from.replaceAll(",", "")) &&
                parseFloat(fileData[i]["Pre Net Assets"].replaceAll(",", "")) <
                parseFloat(fee_slab[j].slab_to.replaceAll(",", ""))
              ) {
                flag = true
                let calculatedtrusteefee = (((parseFloat(fee_slab[j].rate.replaceAll(",", "")) / 100) * 
                parseFloat(fileData[i]["Pre Net Assets"].replaceAll(",", ""))) + 
                parseFloat(fee_slab[j].fix_amount ? fee_slab[j].fix_amount.replaceAll(",", "") : "0"))/365
                calculateTrusteeFee.push((parseFloat(calculatedtrusteefee.toString()) ).toFixed(2))
                if (
                  parseFloat(fileData[i]["Trustee Fee"] ?
                    fileData[i]["Trustee Fee"].replaceAll(",", "") : "0").toFixed(2) != parseFloat(calculatedtrusteefee.toString().replaceAll(",", "")).toFixed(2)
                ) {
                  let temp = invalidTrusteeFee;
                  temp.push(i+"-invalid");
                  setInvalidTrusteeFee([...temp]);

                }
              }
            }
            if (flag == false) {
              calculateTrusteeFee.push("0.00")
              let temp = invalidTrusteeFee;
              temp.push(i+"-valid");
              setInvalidTrusteeFee([...temp]);
            }
          }
          setCalculateTrusteeFee(calculateTrusteeFee)
          if (fee_slab.length > 0) {
            setShowData(true);
            setShowGenerateInvoiceFlag(true);
            setPreviewLoading(false);
          } else {
            setFeeSlabWarning(true);
          }
        } else {
          setShowData(false);
          setPreviewLoading(false);
          setShowGenerateInvoiceFlag(false);
          toast.error("Please Enter Correct Fund Code");
        }
      }
    } catch (err: any) {
      setShowData(false);
      setPreviewLoading(false);
      setShowGenerateInvoiceFlag(false);

      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const getPeriod = async (value: any) => {
    try {
      const response = await searchInvoice(
        email,
        value.split("-")[0],
        value.split("-")[1],
        "active"
      );
      if (response.data.data && response.data.data.length > 0) {
        setPreviousBalance(response.data.data[0].balance);
      }
      let tempAmc = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.period}`,
          value: `${val.period}`,
          start_date: val.period_start,
          end_date: val.period_end
        };
      });
      tempAmc.push({ value: "Other", label: "Other" });
      setAllPeriod(tempAmc);
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const renderModalAddHead = () => {
    switch (ViewAddHead) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddHead(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Head</h6>
              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <div className="form-group">
                  <div>Head </div>
                  <input
                    type="text"
                    value={head}
                    className={"form-control w-100 "}
                    onChange={(e) => {
                      setHead(e.target.value);
                    }}
                  />
                </div>

                <div className=" ">
                  <div className=" mt-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        if (head.trim() === "") {
                          toast.error("Kindly fill all fields.");
                        } else {
                          headOptions.unshift({ value: head, label: head });

                          setViewAddHead(false);
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderModalAddPeriod = () => {
    switch (ViewAddPeriod) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddPeriod(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Period</h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Start Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={"form-control  w-100"}
                        value={startDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setStartDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group  ">
                    <label>End Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={`form-control  w-100 `}
                        value={endDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setEndDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Name</label>
                    <div className="">
                      <input
                        type="text"
                        className={
                          "form-control  w-100 " +
                          ErrorBorderOnAddPeriodModal(periodNameModal)
                        }
                        value={periodNameModal}
                        onChange={(e) => {
                          setPeriodNameModal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group  ">
                    <label>Due Date</label>
                    <div className="">
                      <input
                        type="date"
                        className={`form-control  w-100 `}
                        value={dueDate}
                        onChange={(e) => {
                          let date = moment(e.target.value).format(
                            "YYYY-MM-DD"
                          );
                          setDueDate(date);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="row">
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      if (!periodNameModal || periodNameModal == "") {
                        setErrorShowPeriodModal(true);
                      } else {
                        setPeriod(periodNameModal);
                        allPeriod.unshift({
                          value: periodNameModal,
                          label: periodNameModal,
                        });
                        setPeriodNameModal("");
                        setViewAddPeriod(false);
                        setErrorShowPeriodModal(false);
                      }
                    }}
                  >
                    <span>Add Period</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderModalWarning = () => {
    switch (invalidApproveTruseeFee) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setInvalidApproveTruseeFee(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                UnMatched Calculated Trustee Fee
              </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setFlag(!flag);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Are you sure you want to upload!{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
                {flag && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          UploadInvoice(false);
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Upload</span>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderModalFeeSlabWarning = () => {
    switch (feeSlabWarning) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFeeSlabWarning(false);
                  setPreviewLoading(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Fee Slab Warning</h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="form-group mr-2">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setFeeSlabFlag(!feeSlabflag);
                          }}
                        />
                      </div>
                      <span style={{ color: "#EED202", fontWeight: "bold" }}>
                        Warning!!! Fee Slab Data not Found. Do You Want to
                        Continue?
                      </span>
                    </div>
                  </Col>
                </Row>
                {feeSlabflag && (
                  <div className=" mt-3">
                    <div className="">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setFeeSlabWarning(false);
                          setShowData(true);
                          setShowGenerateInvoiceFlag(true);
                          setPreviewLoading(false);
                        }}
                      >
                        <span>Upload File</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Upload Daily Fee
                  <Dropdown className="float-right">
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      Download Sample
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="./../../Uploader-Sample/Upload-Daily-Fee-Format.xlsx"
                        download
                      >
                        Upload Daily Fee
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </h4>
              </div>

              <div className="card-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-2">
                      <label>Fund</label>
                      <div
                        className={"form-group " + ErrorBorder(AmcFundName)}
                        style={{ border: "1px solid #4f81bd" }}
                      >
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          label={"Select AMC Funds"}
                          isLoading={allAMCFund.length > 0 ? false : true}
                          onChange={(e) => {
                            setAMCFundName(e.value);
                            allInvoice.length = 0;
                            setAllInvoice(allInvoice);
                            setPeriod("");

                            getPeriod(e.value);
                            setShowData(false);
                            setShowGenerateInvoiceFlag(false);
                          }}
                          value={allAMCFund.filter(
                            (option) => option.value === AmcFundName
                          )}
                          isClearable={false}
                          isSearchable={true}
                          name="color"
                          options={allAMCFund}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label>Choose File</label>
                      <div
                        className="multi-input pointer"
                        onClick={() => myRef1?.current?.click()}
                      >
                        <div
                          className="form-group"
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <div className="form-control">
                            {fileName === "" ? "Upload File" : fileName}
                          </div>
                          <input
                            type="file"
                            ref={myRef1}
                            style={{ display: "none" }}
                            multiple={false}
                            onChange={(e) => {
                              upload(e);
                              e.target.value = "";
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">
                              {fileError}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {fileUploadingFlag && (
                        <img src="assets/loader.svg" width="50" />
                      )}
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary mt-4 "
                        onClick={() => {
                          GetFundData();
                        }}
                        disabled={Boolean(previewLoading)}
                      >
                        {previewLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Preview</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {fileDataCount <= 0 || showData == false ? (
                  ""
                ) : (
                  <div
                    className="table-responsive"
                  // style={{
                  //   overflowX: "auto",
                  //   overflowY: "hidden",
                  // }}
                  >
                    <table className="table mt-3">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th className="text-right">Pre Net Assets</th>
                          <th className="text-right">Trustee Fee</th>
                          <th>Trustee Tax %</th>
                          <th className="text-right">Tax on Trustee Fee</th>
                          <th className="text-right">Net Amount</th>
                          <th className="text-right">Calculated Trustee Fee</th>
                          <th className="text-right">Management Fee</th>
                          <th>Mgt Fee Tax %</th>
                          <th className="text-right">Tax on Mgt Fee</th>
                          <th className="text-right">Calculated Mgt Fee</th>

                        </tr>
                      </thead>
                      <tbody id="myUL">
                        {fileData &&
                          fileData.map((item: any, index: number) => {
                            sum_trustee_fee =
                              parseFloat(
                                parseFloat(item["Trustee Fee"]
                                  ? item["Trustee Fee"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ).toFixed(2)) + sum_trustee_fee;
                            sum_tax_on_trustee_fee =
                              parseFloat(
                                item["Tax on Trustee Fee"]
                                  ? item["Tax on Trustee Fee"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ) + sum_tax_on_trustee_fee;
                            sum_tax_on_mgt_fee =
                              parseFloat(
                                item["Tax on Mgt Fee"]
                                  ? item["Tax on Mgt Fee"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ) + sum_tax_on_mgt_fee;
                            sum_net_amount =
                              parseFloat(parseFloat(
                                item["Net Amount"]
                                  ? item["Net Amount"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ).toFixed(2)) + sum_net_amount;
                            sum_management_fee =
                              parseFloat(
                                item["Management Fee"]
                                  ? item["Management Fee"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ) + sum_management_fee;
                            sum_calculated_trustee_fee =
                              parseFloat(
                                calculateTrusteeFee[index]
                                  ? calculateTrusteeFee[index]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ) + sum_calculated_trustee_fee;
                            sum_calculated_mgt_fee =
                              parseFloat(
                                item["Management Fee"]
                                  ? item["Management Fee"]
                                    .toString()
                                    .replaceAll(",", "")
                                  : "0"
                              ) + sum_calculated_mgt_fee;
                            sum_tax_rate = ((parseFloat(sum_tax_on_trustee_fee.toString()) / parseFloat(sum_trustee_fee.toString())) * 100)
                            return (
                              <>
                                <tr
                                  key={index}
                                  className={
                                    invalidTrusteeFee.includes(index+"-invalid") &&
                                    "highlight-row-error"
                                  }
                                >
                                  <td>{item["Date"]}</td>
                                  <td className="text-right">
                                    {numberWithCommas(parseFloat(item["Pre Net Assets"] ? item["Pre Net Assets"].replaceAll(",", "") : "0").toFixed(2))}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Trustee Fee"]
                                          ? item["Trustee Fee"].replaceAll(
                                            ",",
                                            ""
                                          )
                                          : "0"
                                      ).toFixed(2)
                                    )}
                                  </td>
                                  <td>{item["Trustee Tax %"] ? item["Trustee Tax %"].replaceAll("%", "") : "0"} %</td>

                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Tax on Trustee Fee"]
                                          ? item[
                                            "Tax on Trustee Fee"
                                          ].replaceAll(",", "")
                                          : "0"
                                      ).toFixed(2)
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Net Amount"]
                                          ? item["Net Amount"].replaceAll(
                                            ",",
                                            ""
                                          )
                                          : "0"
                                      ).toFixed(2)
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(calculateTrusteeFee[index])}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Management Fee"]
                                          ? item["Management Fee"].replaceAll(
                                            ",",
                                            ""
                                          )
                                          : "0"
                                      ).toFixed(2)
                                    )}
                                  </td>
                                  <td>{item["Mgt Fee Tax %"] ? item["Mgt Fee Tax %"].replaceAll("%", "") : "0"} %</td>
                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Tax on Mgt Fee"]
                                          ? item["Tax on Mgt Fee"].replaceAll(
                                            ",",
                                            ""
                                          )
                                          : "0"
                                      )
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(
                                      parseFloat(
                                        item["Calculated Mgt Fee"]
                                          ? item[
                                            "Calculated Mgt Fee"
                                          ].replaceAll(",", "")
                                          : "0"
                                      ).toFixed(2)
                                    )}
                                  </td>

                                </tr>
                              </>
                            );
                          })}
                      </tbody>

                      <tfoot >
                        <tr>
                          <th>Total</th>
                          <th></th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(sum_trustee_fee.toString() || '0').toFixed(2)
                            )}
                          </th>
                          <th></th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_tax_on_trustee_fee.toString() || '0'
                              ).toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(sum_net_amount.toString() || '0').toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_calculated_trustee_fee.toString() || '0'
                              ).toFixed(2)
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(sum_management_fee.toString() || '0').toFixed(2)
                            )}
                          </th>
                          <th></th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(sum_tax_on_mgt_fee.toString()).toFixed(
                                2
                              )
                            )}
                          </th>
                          <th className="text-right">
                            {numberWithCommas(
                              parseFloat(
                                sum_calculated_mgt_fee.toString() || '0'
                              ).toFixed(2)
                            )}
                          </th>

                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}

                {showGenerateInvoiceFlag && (
                  <div>
                    <div>
                      <h3 className="card-title">Generate Invoice</h3>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label>Gross Amount</label>
                        <div
                          className={
                            "form-group d-flex " +
                            ErrorBorderGenerateInvoice(sum_trustee_fee)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <input
                            type="text"
                            className={"form-control text-right"}
                            value={numberWithCommas(parseFloat(sum_trustee_fee.toString() || '0').toFixed(2))}
                            readOnly={true}
                          />
                          <span
                            style={{
                              border:
                                errorShowGenerateInvoice &&
                                  sum_trustee_fee &&
                                  sum_trustee_fee.toString().trim() == ""
                                  ? "2px solid #fb3d3d"
                                  : "1px solid #4f81bd",
                              textAlign: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            Rs
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>Net Amount</label>
                        <div
                          className={
                            "form-group d-flex " +
                            ErrorBorderGenerateInvoice(sum_net_amount)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <input
                            type="text"
                            className={"form-control text-right"}
                            value={numberWithCommas(parseFloat(sum_net_amount.toString() || '0').toFixed(2))}
                            readOnly={true}
                          />
                          <span
                            style={{
                              border:
                                errorShowGenerateInvoice &&
                                  sum_net_amount &&
                                  sum_net_amount.toString().trim() == ""
                                  ? "2px solid #fb3d3d"
                                  : "1px solid #4f81bd",
                              textAlign: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            Rs
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>System Calculated Amount</label>
                        <div
                          className={
                            "form-group d-flex "

                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            className={"form-control text-right"}
                            value={parseFloat(sum_calculated_trustee_fee.toString() || '0').toFixed(2)}
                            onValueChange={(e) => {
                              sum_calculated_trustee_fee = parseFloat(e.value || '');
                            }}
                          // readOnly={true}
                          />
                          <span
                            style={{
                              border:
                                errorShowGenerateInvoice &&
                                  sum_calculated_trustee_fee &&
                                  sum_calculated_trustee_fee.toString().trim() ==
                                  ""
                                  ? "2px solid #fb3d3d"
                                  : "1px solid #4f81bd",
                              textAlign: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            Rs
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label>Period</label>
                        <div
                          className={
                            "form-group " + ErrorBorderGenerateInvoice(period)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={"Select Period"}
                            onChange={(e) => {
                              if (e.value == "Other") {
                                setViewAddPeriod(true);
                              } else {
                                setPeriod(e.value);
                                setStartDate(e.start_date)
                                setEndDate(e.end_date)
                              }
                            }}
                            value={allPeriod.filter(
                              (option) => option.value === period
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={allPeriod}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>Tax Type</label>
                        <div
                          className={
                            "form-group " + ErrorBorderGenerateInvoice(taxType)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={"Select Tax Type"}
                            onChange={(e) => {
                              setTaxType(e.value);
                            }}
                            value={taxTypeOptions.filter(
                              (option) => option.value === taxType
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={taxTypeOptions}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">

                        <label>Tax Rate</label>
                        <div
                          className={
                            "form-group d-flex "

                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <input
                            type="text"
                            className={"form-control "}
                            //value={parseFloat(((parseFloat(sum_tax_on_trustee_fee.toString())/parseFloat(sum_trustee_fee.toString())) * 100).toString()).toFixed(2)}
                            value={parseFloat(sum_tax_rate.toString()).toFixed(2)}
                            readOnly={true}
                          // onChange={(e) => {
                          //   setTaxRate(e.target.value);
                          // }}
                          />
                          <span
                            style={{
                              border:
                                "1px solid #4f81bd",
                              textAlign: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            %
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label>Tax Amount</label>
                        <div
                          className={
                            "form-group d-flex " +
                            ErrorBorderGenerateInvoice(sum_tax_on_trustee_fee)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <input
                            type="text"
                            className={"form-control text-right"}
                            value={numberWithCommas(parseFloat(sum_tax_on_trustee_fee.toString() || '0').toFixed(2))}
                            readOnly={true}
                          />
                          <span
                            style={{
                              border: "1px solid #4f81bd",
                              textAlign: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            Rs
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label>Head</label>
                        <div
                          className={
                            "form-group " + ErrorBorderGenerateInvoice(head)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={"Select Head"}
                            onChange={(e) => {
                              if (e.value === "Other") {
                                setViewAddHead(true);
                                setHead("");
                              } else {
                                setHead(e.value);
                              }
                            }}
                            value={headOptions.filter(
                              (option) => option.value === head
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={headOptions}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>Previous Balance</label>
                        <div
                          className={
                            "form-group d-flex " +
                            ErrorBorderGenerateInvoice(previousBalance)
                          }
                          style={{ border: "1px solid #4f81bd" }}
                        >
                          <input
                            type="text"
                            className={"form-control text-right"}
                            value={numberWithCommas(parseFloat(previousBalance || '0').toFixed(2))}
                            readOnly={true}
                          />
                          <span
                            style={{
                              border:
                                errorShowGenerateInvoice &&
                                  previousBalance &&
                                  previousBalance.toString().trim() == ""
                                  ? "2px solid #fb3d3d"
                                  : "1px solid #4f81bd",
                              textAlign: "center",
                              padding: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "8px",
                            }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={true}
                              onChange={(e) => {
                                setAddPreviousBalance(!addPreviousBalance);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <button
                          className="btn btn-primary mt-4 "
                          onClick={() => {
                            UploadInvoice(true);
                          }}
                          disabled={Boolean(loading)}
                          style={{
                            whiteSpace: "nowrap",
                            width: "100%",
                            textAlign: "center",
                            justifyContent: "left",
                          }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Save Invoice Head</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {renderinvalidFormatPopup()}
            {renderModalAddHead()}
            {renderModalAddPeriod()}
            {renderModalWarning()}
            {renderModalFeeSlabWarning()}
            {renderModalPeriodWarning()}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default DailyFeeupload;
