import React, { useRef, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Header from '../../components/Header';
import { renderToString } from 'react-dom/server';
import * as htmlToImage from 'html-to-image';
import {
  addLetterTemplate,
  getAllLetterTemplates,
  UpdateLatterTemplates,
} from './../../stores/services/template.service';
import { Modal } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { getAllBanks } from './../../stores/services/bank.service';
import { AiOutlineAlignLeft } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { FiUploadCloud } from 'react-icons/fi';
import Dropzone from 'react-dropzone';
import { postionCoordinates } from './leterFields';

export type Coordinates = {
  id: string;
  x: number;
  y: number;
  classes: string;
  label: string;
  value: string;
};

const LetterFormater = React.forwardRef((props: any, ref) => {
  const [coordinates, setCoordinates] = useState(postionCoordinates);
  const email = sessionStorage.getItem('email') || '';
  const [name, setName] = useState('');
  let [count, setCount] = useState(1);
  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [editFlag, setEditFlag] = useState(false);
  const [bank_name, setBank_name] = useState('');
  const [checkChanges, setCheckChanges] = useState('');

  function handleMouseMoveOnPage(evt, atrName, atrID, value, classes) {
    var dateIdElement = document.getElementById('dateElement');
    let end: Coordinates = {
      x: 0,
      y: 0,
      label: atrName,
      id: atrID,
      value,
      classes,
    };
    let offsetX,
      offsetY = 0;
    if (evt.clientX > 1000) {
      offsetX = 700;
    } else {
      offsetX = evt.clientX;
    }
    if (evt.clientY > 840) {
      offsetY = 820;
    } else {
      offsetY = evt.clientY;
    }
    end = {
      x: offsetX - 20,
      y: offsetY - 20,
      label: atrName,
      id: atrID,
      value,
      classes,
    };

    coordinates.map((item, index) => {
      if (item.id === atrID) {
        coordinates[index] = end;
        setCoordinates(coordinates);
        setCheckChanges(`${atrName}-${end.x}-${atrID}`);
      }
    });
    // setCoordinates([end])
  }
  const [bankNameData, setBankNameData] = useState<any>([]);

  useEffect(() => {
    const getTemplates = async () => {
      setLoading(true);
      try {
        const response = await getAllLetterTemplates(email);
        setAllTemplates(response.data.data);
      } catch (error:any) {
        toast.error(error.response.data.message[0]);
      }
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (error) {}
      setLoading(false);
    };
    getTemplates();
  }, []);
  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_','')}>
          {item.bank_name}-{item.bank_code.replace('BANK_','')}
        </option>
      );
    });
  };
  const getTemplates = async () => {
    setLoading(true);
    try {
      const response = await getAllLetterTemplates(email);
      setAllTemplates(response.data.data);
    } catch (error:any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const [updateLoadingBtn, setUpdateLoadingBtn] = useState(false);

  const UpdateTemplateData = async () => {
    setUpdateLoadingBtn(true);
    try {
      const response = await UpdateLatterTemplates(
        email,
        name,
        JSON.stringify(coordinates),
        'MCB'
      );
      toast.success(response.data.message);
      getTemplates();
      setUpdateLoadingBtn(false);
    } catch (error:any) {
      toast.error(error.response.data.message);
      setUpdateLoadingBtn(false);
    }
  };

  const [file, setFile] = useState('');
  const handleDrop = (acceptedFiles: any) => {
    var url = URL.createObjectURL(acceptedFiles[0]);
    setFile(url);
  };

  const Prints = () => (
    <div className="a4-page" id={'dateElement'}>
      <div className="w-100">
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              {!file ? (
                <div className="drop-container">
                  <div className=" text-center">
                    <FiUploadCloud className="upload-icon" />
                    <h6 style={{ fontSize: '10px' }}>Upload</h6>
                  </div>
                </div>
              ) : (
                <img src={file} height={120} className="pt-2 pl-2" alt="" />
              )}
            </div>
          )}
        </Dropzone>
      </div>
      {coordinates &&
        coordinates.map((element, index) => {
          return (
            <div
              key={element.id}
              className={'position-absolute rotate-parent ' + element.classes}
              id={element.id}
              style={{
                top: `${element.y}px`,
                left: `${element.x}px`,
                fontSize: '12px',
                overflowWrap: 'break-word',
              }}
              onDragEnd={(e) => {
                handleMouseMoveOnPage(
                  e,
                  element.label,
                  element.id,
                  element.value,
                  element.classes
                );
              }}
              draggable
            >
              <div className="d-flex">
                <div className={' ' + (element.value ? 'label-width' : '')}>
                  {element.label}
                </div>
                {element.value && <div>:{element.value}</div>}
              </div>
            </div>
          );
        })}
    </div>
  );

  const printContent = () => {
    var node = document.getElementById('dateElement') as HTMLElement;
    var Options = {
      width: 680,
      height: 842,
    };
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image(680, 842);
        img.src = dataUrl;
        var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
        const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
        const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
        doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight); // add image to Pdf
        // doc.save('Cheque.pdf'); // Save the cheque
        doc.save('Cheque.pdf'); // Save the cheque
      })
      .catch(function (error) {
       
      });
  };

  const saveChequeTemplate = async () => {
    if (bankname !== '' && name !== '') {
      setDisableAddBtn(true);
      try {
        const response = await addLetterTemplate(
          email,
          name,
          JSON.stringify(coordinates),
          bankname
        );
        toast.success(response.data.message);
        setNameTab(false);
        getTemplates();
      } catch (error:any) {
        toast.error(error.response.data.message);
      }
      setDisableAddBtn(false);
    } else {
      toast.error('Kindly Fill All Fields');
    }
  };

  const [nameTab, setNameTab] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [bankname, setBankName] = useState('');

  const renderModalForTempaletePopup = () => {
    switch (nameTab) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setNameTab(false);
              setDisableAddBtn(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                setNameTab(false);
                setDisableAddBtn(false);
                // clearTaxobject();
              }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Add Template </h6>
             </div>   

            <div className="modal-body">
              <div className="row">

                <div className="col-md-12">
                <div className="form-group">
                  Select Bank
                  <select
                    className="form-control w-100"
                    value={bankname}
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      {' '}
                      Select Bank
                    </option>
                    {renderBankNameDropdown()}
                  </select>
                </div>
                </div>
              
                <div className="col-md-12">
                <div className="form-group">
                  Name
                  <input
                    type="text"
                    value={name}
                    className="form-control w-100"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                </div>
              
                <div className="col-md-12 mt-3">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      saveChequeTemplate();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  const [Loading, setLoading] = useState(false);
  const [alignLeft, setAlignLeft] = useState('0');

  const alignLeftFunction = () => {
    if (+alignLeft < 880 && +alignLeft > 0) {
      coordinates &&
        coordinates.map((element, index) => {
          if (element.x < 680) {
            element.x = +alignLeft;
          }
        });
      setCoordinates(coordinates);
      setCheckChanges(`${alignLeft}-${alignLeft}`);
    }
  };

  const AddFile = async (event: any) => {
    let file = event.target.files;
    handleDrop(file);
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid className="pl-1 pr-0">
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <input
          type="file"
          id="browser"
          style={{ display: 'none' }}
          onChange={AddFile}
          multiple={true}
        />
        <div className="row no-gutters">
          <div className="col-12 col-sm-6 pl-0 mt-1 col-md-11 hight-sx">
            <div className="d-flex">
              <Prints />
              <div className="flex glow-sp-x fields-section w-25">
                <div className="border h-100">
                  <h3 className="text-center p-3">Drag And Drop Fields</h3>
                </div>
              </div>
              <div className="Fieldslex formats-section ">
                <div className=" border h-100">
                  <h3 className="text-center p-3">All Letter Formats</h3>
                  <div className=" temp-scroll">
                    {allTemplates &&
                      allTemplates.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="scroll-items border card-title box-report text-center mx-5 mb-3 p-3 box-items pointer"
                            // style={{ color: 'white', fontSize: '16px' }}
                            onClick={() => {
                              let tempObj = JSON.parse(item.template_detail);
                              setCoordinates(tempObj);
                              setName(item.template_name);
                              setBank_name(item.bank_name);
                              setEditFlag(true);
                            }}
                          >
                            {item.template_name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div
              data-tip="Align from Left"
              className="d-flex justify-content-center ml-1 mt-2 mb-2"
            >
              <ReactTooltip
                textColor="white"
                backgroundColor="black"
                effect="float"
              />
              <input
                type="number"
                style={{ width: '70px' }}
                className="form-control h-25 rounded"
                placeholder="Left"
                onChange={(e) => {
                  setAlignLeft(e.target.value);
                }}
              />
              <AiOutlineAlignLeft
                className="mt-3 ml-2"
                onClick={() => {
                  alignLeftFunction();
                }}
              />
            </div>
            <div className="d-flex justify-content-center ml-1 mt-2 mb-2">
              <div className=" ">
                <button
                  className="btn btn-default "
                  onClick={() => {
                    count = count + 1;
                    setCount(count);
                    setCoordinates([
                      {
                        id: '1',
                        x: 700,
                        y: 50,
                        classes: '',
                        label: 'Date',
                        value: '',
                      },
                      {
                        id: '2',
                        x: 700,
                        y: 70,
                        classes: '',
                        label: 'Ref: ',
                        value: 'Value Here',
                      },
                      {
                        id: '3',
                        x: 700,
                        y: 90,
                        classes: '',
                        label: 'The Chief Manager,',
                        value: '',
                      },
                      {
                        id: '4',
                        x: 700,
                        y: 120,
                        classes: '',
                        label: 'Bank Name',
                        value: '',
                      },
                      {
                        id: '5',
                        x: 700,
                        y: 150,
                        classes: '',
                        label: 'Address',
                        value: '',
                      },
                      {
                        id: '6',
                        x: 700,
                        y: 170,
                        classes: '',
                        label: 'City',
                        value: '',
                      },
                      {
                        id: '7',
                        x: 700,
                        y: 185,
                        classes: '',
                        label: 'Dear Sir,',
                        value: '',
                      },
                      {
                        id: '8',
                        x: 700,
                        y: 210,
                        classes: 'font-weight-bold text-decoration-underline',
                        label: 'Heading Label One',
                        value: '',
                      },
                      {
                        id: '9',
                        x: 700,
                        y: 230,
                        classes: '',
                        label: 'Fund Bank Account Title',
                        value: 'Value Here',
                      },
                      {
                        id: '10',
                        x: 700,
                        y: 255,
                        classes: '',
                        label: 'Fund Bank Account No',
                        value: 'Value Here',
                      },
                      {
                        id: '12',
                        x: 700,
                        y: 265,
                        classes: '',
                        label: 'Broker',
                        value: 'Value Here',
                      },
                      {
                        id: '13',
                        x: 700,
                        y: 280,
                        classes: '',
                        label: 'Mode of Payment',
                        value: 'Value Here',
                      },
                      {
                        id: '14',
                        x: 700,
                        y: 295,
                        classes: '',
                        label: 'Settlement Amount',
                        value: 'Value Here',
                      },
                      {
                        id: '15',
                        x: 700,
                        y: 310,
                        classes: 'font-weight-bold text-decoration-underline',
                        label: 'Instrument Detail',
                        value: 'Value Here',
                      },
                      {
                        id: '16',
                        x: 700,
                        y: 330,
                        classes: '',
                        label: 'Instrument Type',
                        value: 'Value Here',
                      },
                      {
                        id: '17',
                        x: 700,
                        y: 345,
                        classes: '',
                        label: 'Issued Date',
                        value: 'Value Here',
                      },
                      {
                        id: '18',
                        x: 700,
                        y: 360,
                        classes: '',
                        label: 'Maturity Date',
                        value: 'Value Here',
                      },
                      {
                        id: '19',
                        x: 700,
                        y: 375,
                        classes: '',
                        label: 'Face Value',
                        value: 'Value Here',
                      },
                      {
                        id: '20',
                        x: 700,
                        y: 390,
                        classes: '',
                        label: 'Coupon / Yeild',
                        value: 'Value Here',
                      },
                      {
                        id: '21',
                        x: 700,
                        y: 410,
                        classes: 'font-weight-bold text-decoration-underline',
                        label: 'Counter Party Detial',
                        value: 'Value Here',
                      },
                      {
                        id: '22',
                        x: 700,
                        y: 430,
                        classes: '',
                        label: 'Counter Party Name',
                        value: 'Value Here',
                      },
                      {
                        id: '23',
                        x: 700,
                        y: 450,
                        classes: '',
                        label: 'Bank Account Detail',
                        value: 'Value Here',
                      },
                      {
                        id: '24',
                        x: 700,
                        y: 470,
                        classes: '',
                        label: 'SGL / IPS Account Detail',
                        value: 'Value Here',
                      },
                      {
                        id: '25',
                        x: 700,
                        y: 500,
                        classes: 'border p-2',
                        label: 'Text Box',
                        value: '',
                      },
                      {
                        id: '26',
                        x: 700,
                        y: 530,
                        classes: '',
                        label: 'Amount',
                        value: 'Value Here',
                      },
                      {
                        id: '27',
                        x: 700,
                        y: 550,
                        classes: '',
                        label: 'Your Truly',
                        value: '',
                      },
                      {
                        id: '28',
                        x: 700,
                        y: 590,
                        classes: 'border-top',
                        label: 'Authorized Signatory',
                        value: '',
                      },
                      {
                        id: '29',
                        x: 700,
                        y: 630,
                        classes: '',
                        label: 'AMC Name',
                        value: 'Value Here',
                      },
                      {
                        id: '30',
                        x: 700,
                        y: 650,
                        classes: 'border-top',
                        label: 'Authorized Signatory',
                        value: '',
                      },
                      {
                        id: '31',
                        x: 700,
                        y: 670,
                        classes: '',
                        label: 'NAV',
                        value: 'Value Here',
                      },
                      {
                        id: '32',
                        x: 700,
                        y: 680,
                        classes: '',
                        label: 'Gross Amount',
                        value: 'Value Here',
                      },
                      {
                        id: '33',
                        x: 700,
                        y: 690,
                        classes: '',
                        label: 'Net Amount',
                        value: 'Value Here',
                      },
                      {
                        id: '34',
                        x: 700,
                        y: 710,
                        classes: '',
                        label: 'Folio No',
                        value: 'Value Here',
                      },
                      {
                        id: '35',
                        x: 700,
                        y: 720,
                        classes: '',
                        label: 'Units',
                        value: 'Value Here',
                      },
                      {
                        id: '36',
                        x: 700,
                        y: 730,
                        classes: '',
                        label: 'Sale Date',
                        value: 'Value Here',
                      },
                    ]);
                    setCheckChanges(`${count}-${nameTab}`);
                    setEditFlag(false);
                  }}
                >
                  Clear
                </button>
              </div>
              <div className="">
                <button
                  className="btn btn-info ml-2"
                  onClick={() => {
                    printContent();
                  }}
                >
                  Print
                </button>
              </div>

              <div className="">
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    setNameTab(true);
                  }}
                >
                  Save
                </button>
              </div>
              {editFlag && (
                <div className="">
                  <button
                    className="btn btn-secondary ml-2"
                    onClick={() => {
                      UpdateTemplateData();
                    }}
                    disabled={Boolean(updateLoadingBtn)}
                  >
                    {updateLoadingBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'none' }}>
            {/* <Header /> */}
          </div>
        </div>
        {renderModalForTempaletePopup()}
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
});

export default LetterFormater;
