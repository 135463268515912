import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { addSecurity } from '../../../stores/services/security.service';
import { useHistory, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";

const codeRegex = new RegExp('^[A-Z]{1,5}$');
const SetupSecurity = () => {
  const history = useHistory();
  const [securityType, setSecurityType] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [maturityDate, setMaturityDate] = useState('');
  const [faceValue, setFaceValue] = useState('');
  const [price, setPrice] = useState('');

  const [securityTypeError, setSecurityTypeError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [issueDateError, setIssueDateError] = useState('');
  const [maturityDateError, setMaturityDateError] = useState('');
  const [faceValError, setFaceValError] = useState('');
  const [priceError, setPriceError] = useState('');

  const [Loading, setLoading] = useState(false);

  const validate = () => {
    let securityTypeErr,
      codeErr,
      nameErr,
      issueDateErr,
      maturityDateErr,
      faceValErr,
      priceErr = '';
    securityType.trim() === '' ? (securityTypeErr = 'Required') : (securityTypeErr = '');
    name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');
    code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');
    // issueDate.trim() ==='' ? (issueDateErr = 'Required') : (issueDateErr = '');
    // maturityDate.trim() === '' ? (maturityDateErr = 'Required') : (maturityDateErr = '');
    // faceValue.trim() === '' ? (faceValErr = 'Required') : (faceValErr = '');
    // price.trim() === '' ? (priceErr = 'Required') : (priceErr = '');

    if (securityTypeErr ||
      nameErr ||
      codeErr
      // issueDateErr||
      // maturityDateErr||
      // faceValErr||
      // priceErr
    ) {
      setSecurityTypeError(securityTypeErr);
      setNameError(nameErr);
      setCodeError(codeErr);
      // setIssueDateError(issueDateErr);
      // setMaturityDateError(maturityDateErr);
      // setFaceValError(faceValErr);
      // setPriceError(priceErr);
      return false;
    } else {
      return true;
    }
  };

  const createSecurity = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true)
      await addSecurity(sessionStorage.getItem('email') || '', name, code, securityType, issueDate, maturityDate, faceValue, price)
        .then((response) => {
          if (response.data.status == 200) {
            toast.success(response.data.message);
            setTimeout(function () {
              history.push('/admin/securities', { params: '1' })
            }, 3000);
          } else if (response.data.status == 500) {
            toast.error(response.data.message);
          } else {
            toast.error(response.data.message);
          }

        })
        .catch((err: any) => {
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message)
          }
        })
      setLoading(false);
    }
  }

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card ">
                <div className="">
                  <div className="card-header title-row">
                    <h4 className="card-title">
                      <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                        history.push('/admin/securities', { params: '1' })
                      }}></i>Setup - Investment Securities</h4>
                    <button className="t-3 ml-auto btn btn-primary btn-sm" onClick={() => {
                      history.push('/admin/securities', { params: '1' })
                    }}><i className="fa fa-eye mr-2"></i>View All</button>
                    {/* <Link to="/admin/securities" className="t-3 ml-auto btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link> */}
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label>Security Type</label>
                          <div>
                            <div className="">
                              <select
                                className={`form-control  w-100 ${securityTypeError ? ' required-border' : ''
                                  }`}
                                onChange={(e) => {
                                  setSecurityType(e.target.value);
                                  setSecurityTypeError('');

                                }}>
                                <option value='' hidden>Select Security</option>
                                <optgroup>Money Market</optgroup>
                                <option value='PIB'>Pakistan Investment Bonds</option>
                                <option value='TBILL'>Treasury Bills</option>

                                <optgroup>Debt Market</optgroup>
                                <option value='SUKUK'>Sukuk Certificates</option>
                                <option value='IJARA'>GoP Ijara Sukuks</option>
                                <option value='TFC'>Term Finance Certificates</option>
                                <option value='CP'>Commercial Papers</option>

                                <optgroup>Term Deposits</optgroup>
                                <option value='TDR'>Term Deposit Receipts</option>
                                <option value='CDR'>Call Deposit Receipts</option>
                                <option value='LOP'>Letter of Placement</option>
                              </select>


                            </div>

                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>Symbol: <span className="color-amber">*</span></label>
                          <div>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${codeError ? ' required-border' : ''
                                  }`}
                                value={code}
                                onChange={(e) => {
                                  setCode(e.target.value.toUpperCase());
                                  setCodeError('');
                                }} />

                            </div>

                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col md="6">
                        <div className="form-group">
                          <label>Name: <span className="color-amber">*</span></label>
                          <div className="">
                            <input
                              className={`form-control  w-100 ${nameError ? ' required-border' : ''}`}
                              onChange={(e) => {
                                setName(e.target.value);
                                setNameError('');
                              }} />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>Issue Date</label>
                          <div>
                            <div className="">
                              <input
                                type="date"
                                // className={`form-control  w-100 ${issueDateError ? ' required-border' : ''}`}
                                className="form-control w-100"
                                value={issueDate}
                                onChange={(e) => {
                                  setIssueDate(e.target.value);
                                  setIssueDateError('')
                                }} />


                            </div>

                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col md="6">
                        <div className="form-group">
                          <label>Maturity Date</label>
                          <div className="">
                            <input type='date' value={maturityDate}
                              // className={`form-control  w-100 ${maturityDateError ? ' required-border' : ''}`} 
                              className="form-control w-100"
                              onChange={(e) => {
                                setMaturityDate(e.target.value);
                                setMaturityDateError('');
                              }} />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>Face Value</label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                // className={`form-control text-right  w-100 ${faceValError ? ' required-border' : ''}`}
                                className="form-control w-100 text-right"
                                value={faceValue}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  setFaceValue(value);
                                  setFaceValError('');
                                }} />


                            </div>

                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col md="6">
                        <div className="form-group">
                          <label>Price</label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                // className={`form-control text-right  w-100 ${priceError ? ' required-border' : ''}`}
                                className="form-control w-100 text-right"
                                value={price}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 4);
                                  setPrice(value);
                                  setPriceError('')
                                }} />


                            </div>

                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="">
                      <button className="btn btn-primary" onClick={createSecurity} disabled={Boolean(Loading)}>
                        {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="login-txt"> Loading...</span></> : <span>Create</span>}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
};
export default SetupSecurity;