import React from 'react';
import { useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import moment from 'moment';
import { isNumber, numberWithCommas } from '../../../utils/customFunction';
import { useHistory } from 'react-router-dom';

const ViewFund = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState('');
  const [code, setCode] = useState('');
  const [amcName, setAmcName] = useState('');
  const [fundType, setFundType] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');
  const [revocationDate, setRevocationDate] = useState('');
  const [psxListing, setPsxListing] = useState('');
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [nav, setNav] = useState('');
  const [faceValue, setFaceValue] = useState('');
  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');
  const [clearMember_id, setCleariMemberID] = useState('');
  const [bats_id, setBatsID] = useState('');
  const [fundSecurities, setFundSecurities] = useState<any>([]);
  const [feeSlabFrom,setFeeSlabFrom]=useState('');	
  const [feeSlabTo,setFeeSlabTo]=useState('');	
  const [feeRate, setFeeRate]=useState('')	
  const [feeFixAmount,setFeeFixAmount]=useState('')	
  const [allFeeSlab, setAllFeeSlab]=useState<any>([])

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('fundObj') || '');
    let feeDetails=obj.fee_slab?JSON.parse(obj.fee_slab):null	
    if(feeDetails)	
    {	
      	
      setAllFeeSlab([...feeDetails])	
      	
    }
    setFundName(obj.fund_name);
    setCode(obj.symbol_code.replace('FUND_', ''));
    setNav(obj.nav);
    setFaceValue(obj.face_value);
    setAmcName(obj.amc_code);
    setFundType(obj.nature);
    setIncorporationDate(obj.date_of_incorporation);
    setRevocationDate(obj.date_of_revocation);
    setPsxListing(obj.psx_listing);
    if (obj.money_market && obj.money_market !== '[]') {
      setMoneyMarket(JSON.parse(obj.money_market));
    }
    if (obj.stock_market && obj.stock_market !== '' && obj.stock_market !== '[]') {
      setStockMarket(JSON.parse(obj.stock_market));
    }
    if (obj.fund_market && obj.fund_market !== '' && obj.fund_market !== '[]') {
      setFundSecurities(JSON.parse(obj.fund_market));
    }
    if (obj.term_deposit && obj.term_deposit !== '' && obj.term_deposit !== '[]') {
      setTermDeposit(JSON.parse(obj.term_deposit));
    }

    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);
    setCleariMemberID(obj.cmid)
    setBatsID(obj.bats_id);

  }, []);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{moment(items.issue_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.maturity_date).format('DD-MM-YYYY')}</td>
          <td>{numberWithCommas(items.coupon_rate || '')}</td>
          <td>{numberWithCommas(items.yield || '')}</td>
          <td className='text-right'>{numberWithCommas(items.mmface_value || '')}</td>
          <td className='text-right'>{numberWithCommas(items.face_value || '')}</td>
          <td className='text-right'>{numberWithCommas(items.price || '')}</td>
          <td className='text-right'>{numberWithCommas(items.units || '')}</td>
          <td className='text-right'>{items.total_amount && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td>
          <td>{items.tenor}</td>
        </tr>
      );
    });
  };
  const renderFeeDataInTable =()=>{	
   	
    return allFeeSlab.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{numberWithCommas(isNumber(items.slab_from))}</td>	
          <td>{numberWithCommas(isNumber(items.slab_to))}</td>	
          <td className='text-right'>{parseFloat(isNumber(items.rate)).toFixed(6)}</td>	
          <td className='text-right'>{numberWithCommas(isNumber(items.fix_amount))}</td>	
          	
         	
        </tr>	
      );	
    });	
  }

  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}</td>
          <td className='text-right'>{numberWithCommas(items.unit || '') && numberWithCommas(parseFloat(items.unit).toFixed(4))}</td>
          <td className='text-right'>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}</td>
          <td className='pl-5'>{items.market}</td>
          <td>{numberWithCommas(items.pledged)}</td>
        </tr>
      );
    });
  };

  //render fund Security table data
  const renderFundSecuirtiesTable = () => {
    return fundSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td >{numberWithCommas(parseFloat(isNumber(items.price)).toFixed(2))}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(items.units)).toFixed(2))}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(items.total_amount)).toFixed(2))}</td>
        </tr>
      );
    });
  };

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || '')}</td>
          <td>{numberWithCommas(items.interest_rate || '')}</td>
          <td>{moment(items.from_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.to_date).format('DD-MM-YYYY')}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>

              <div className="input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/funds')
                  }}></i>View - Funds</h4>
                <Link to="/admin/funds" className="t-3 ml-auto btn btn-primary btn-sm" replace>
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Fund Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Fund Code</label>
                        <div className="">
                          <input type="text" className="form-control" value={code} />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Fund Name</label>
                        <div className="">
                          <input type="text" className="form-control" value={fundName} />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>AMC Name</label>
                        <div className="">
                          <select className="form-control" value={amcName}>
                            <option value={amcName} defaultChecked hidden>
                              {amcName}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Nature / Type of Fund</label>
                        <div className="">
                          <input type="text" className="form-control" value={fundType} />
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Incorporation
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Date of Incorporation</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={moment(incorporationDate).format('DD-MM-YYYY')}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Date of Revocation</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={moment(revocationDate).format('DD-MM-YYYY')}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>NAV</label>
                            <div className="">
                              <input type="text" className="form-control" value={nav} />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Face Value</label>
                            <div className="">
                              <input type="text" className="form-control text-right" value={numberWithCommas(parseFloat(faceValue).toFixed(2))} />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>
                              CDC Participant ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={cdc_participant_id}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">CDC Account Number </label>
                            <input type="text" className="form-control"
                              value={cdc_account_number}
                            />

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='6'>
                          <div className="form-group">
                            <label>
                              BATS ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className="form-control"
                                value={bats_id}
                                readOnly
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md='6'>
                          <div className="form-group">
                            <label>
                              Clearing Member ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className="form-control"
                                value={clearMember_id}
                                readOnly
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md='6'>
                          <div className="">
                            <label>PSX Listing</label>
                            <div className=" ">
                              <div>
                                <FormGroup check className="form-check-radio">
                                  <Label check>
                                    <Input
                                      defaultChecked
                                      id="listed"
                                      name="listed"
                                      value="true"
                                      checked={true}
                                      type="radio"
                                    />
                                    <span className="form-check-sign" />
                                    {psxListing === 'true' ? 'Listed' : 'UnListed'}
                                  </Label>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
              {allFeeSlab.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                        Fee Slab
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="table-responsive">
                          {allFeeSlab.length > 0 && (
                            <div className="input-size-pos mb-3 mt-2 ">
                              <table className="table   ">
                                <thead>
                                  <tr>
                                    <th>Slab From</th>
                                    <th>Slab To</th>
                                    <th className='text-right'>Rate</th>
                                    <th className='text-right'>Fix Amount</th>
                                  </tr>
                                </thead>
                                <tbody>{renderFeeDataInTable()}</tbody>
                              </table>
                            </div>
                          )}
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}
                {moneyMarket.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Money Market Portfolio
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="input-size-pos mb-3 table-responsive">
                          <h3></h3>
                          <div className="">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Security Type</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th>Coupon Rate</th>
                                  <th>Yield</th>
                                  <th className='text-right'>Money Market Face Value</th>
                                  <th className='text-right'>Face Value</th>
                                  <th className='text-right'>Price</th>
                                  <th className='text-right'>Units</th>
                                  <th className='text-right'>Settlement Amount</th>
                                  <th>Tenor</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundDataInTable()}</tbody>
                            </table>
                          </div>
                        </div>


                      </CardBody>
                    </Card>
                  </Col>
                )}

                {stockMarket.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Stock Market Portfolio
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="input-size-pos mb-3 ">
                          <h3></h3>
                          <div className=" ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Average Rate</th>
                                  <th className='text-right'>Quantity </th>
                                  <th className='text-right'>Amount </th>
                                  <th className='pl-5'>Market </th>
                                  <th>Pledged </th>
                                </tr>
                              </thead>
                              <tbody>{renderEquityInTable()}</tbody>
                            </table>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}

                {fundSecurities.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                        Mutual Funds Portfolio
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="table-responsive">
                          {fundSecurities.length > 0 && (
                            <div className="input-size-pos mb-3 mt-2 ">
                              {/* <h3>Stock Market Portfolio</h3> */}
                              <table className="table   ">
                                <thead>
                                  <tr>
                                    <th>Fund Symbol / Code</th>
                                    <th>NAV Price</th>
                                    <th className='text-right'>No of Units</th>
                                    <th className='text-right'>Gross Amount</th>
                                  </tr>
                                </thead>
                                <tbody>{renderFundSecuirtiesTable()}</tbody>
                              </table>
                            </div>
                          )}
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}

              </Row>

              {termDeposit.length > 0 && (
                <Row>

                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Term Deposit Portfolio
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="input-size-pos mb-3 ">
                          <h3></h3>
                          <div className=" ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th>Principal Amount</th>
                                  <th>Interest Rate</th>
                                  <th>From Date</th>
                                  <th>To Date</th>
                                  <th>Bank</th>
                                  <th>Branch</th>
                                </tr>
                              </thead>
                              <tbody>{renderTermDepositInTable()}</tbody>
                            </table>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewFund;
