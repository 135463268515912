import React, { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import {
    getAccounts,
    updateAccountStatus,
    searchBankAccAPI,
    getAccountByFund,
    getAutoSettledAccountByFund
} from '../../../stores/services/account.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { FaCrown } from 'react-icons/fa';
import { getAmc } from '../../../stores/services/amc.service';
import { sortAMCS } from '../../../utils/customFunction';
import { getFundByAmc } from '../../../stores/services/funds.service';
import NumberFormat from 'react-number-format';

const AccountList = () => {
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const [Loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [isSearchActive, setSearchActive] = useState(false);
    const [fundAccountLength, setFundAccountLength] = useState(0);
    const [autoSettlement, setAutoSettlement] = useState<any>(false);
    let [activePage, setActivePage] = useState('');
    let [paginationArr, setPaginationArr] = useState(['']);
    const [pageSize, setPageSize] = useState('10');
    const [updateAmount, setUpdateAmount] = useState<any>('');
    const [previousAmount, setPreviousAmount] = useState<any>(0);

    const [Amc, setAmc] = useState('');
    const [allAmc, setAllAmc] = useState<any>([]);
    const [fund, setFund] = useState<any>([]);
    const [allFunds, setAllFunds] = useState<any>([]);
    const [fundAccounts, setFundAccounts] = useState<any>([]);

    React.useEffect(() => {
        sessionStorage.removeItem('accountObj');
        getAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage]);



    React.useEffect(() => {
        getAllAmc();
    }, [])
    const getAllAmc = async () => {
        try {
            const amcResponse = await getAmc(email, '', '', '');
            let sortAMCSByName = await sortAMCS(amcResponse.data.data);
            setAllAmc(sortAMCSByName);
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
    }

    const getAllAccountByFund = async (fundData) => {
        try {
            const response = await getAccountByFund(email, fundData)
            setFundAccounts(response.data.data);

        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };
 

    const renderAmcDropDown = () => {
        return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code.replaceAll('AMC_', '')}>
                    {item.name}
                </option>
            );
        });
    }

    const getFunds = async (code: any) => {
        try {
            const response = await getFundByAmc(email, code);
            setAllFunds(response.data.data);
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
    }

    const renderFundDropDown = () => {
        return allFunds.map((item: any, index: number) => {
            return (
                <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                </option>
            );
        });
    }


    const getAccountList = async () => {
        setLoading(true);
        try {
            const response = await getAccounts(email, activePage, pageSize);
            if (response.data.data.length > 0) {
                let pageArr = paginationArr;
                let existingPageIndex = paginationArr.findIndex(
                    (x) => x === response.data.page_info.next_page_number
                );
                if (
                    existingPageIndex === -1 &&
                    response.data.data.length === 10
                    // &&
                    // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
                ) {
                    pageArr.push(response.data.page_info.next_page_number);
                }
                setPaginationArr(pageArr);
                setData(response.data.data);
            } else {
                setData([]);
                toast.error(response.data.message);
            }
        } catch (err: any) {
            // toast.error(error.response.data.message[0]);
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
        setLoading(false);
    };

    const getAccountListUpdated = async () => {
        setLoading(true);
        try {
            const response = await getAccounts(email, activePage, pageSize);
            if (response.data.data.length > 0) {
                let pageArr = paginationArr;
                let existingPageIndex = paginationArr.findIndex(
                    (x) => x === response.data.page_info.next_page_number
                );
                if (
                    existingPageIndex === -1 &&
                    response.data.data.length === 10
                    // &&
                    // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
                ) {
                    pageArr.push(response.data.page_info.next_page_number);
                }
                setPaginationArr(pageArr);
                setData(response.data.data);
            } else {

            }
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
        setLoading(false);
    };
    React.useEffect(() => {
        if (data) {
            let i;
            res.length = 0;
            setRes(res);
            for (i = 0; i < data.length; i++) {
                res.push(data[i]);
                setRes(res);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const [res, setRes] = useState<any>([]);
    const [searchValue, setSearchValue] = useState('');
    function myFunction(e: any) {
        res.length = 0;
        setRes(res);
        var filter, td, i;
        filter = e.target.value;
        for (i = 0; i < data.length; i++) {
            const newText = data[i].account_no;
            td = newText.split(/\s/).join('');
            if (td) {
                if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
                    res.push(data[i]);
                    setRes(res);
                } else {
                }
            }
        }
    }
    let AllAccountsByFund: any = [];
    const searchAccData = async () => {
        setLoading(true);
        try {
            const response = await getAccountByFund(email, fund)
            AllAccountsByFund = response.data.data;
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
        try {
            const response = await searchBankAccAPI(email, searchValue);

            let FilterData = response.data.data.filter((item: any) => {
                if (Amc.length === 0 && fund.length === 0) {
                    if (item.account_no === searchValue) {
                        return item;
                    }
                }
            })

            if (FilterData.length > 0) {
                let pageArr = paginationArr;
                let existingPageIndex = paginationArr.findIndex(
                    (x) => x === response.data.page_info.next_page_number
                );
                if (
                    existingPageIndex === -1 &&
                    response.data.data.length === 10
                ) {
                    pageArr.push(response.data.page_info.next_page_number);
                }
                setPaginationArr(pageArr);
                setData(FilterData);
            } else {
                let searchByFundAndAccNo = AllAccountsByFund.filter((item: any) => {
                    if (item.fund_code === fund && item.account_no === searchValue) {
                        return item;
                    }
                })
                //Matching
                if (searchByFundAndAccNo.length > 0) {
                    setSearchActive(true);
                    setData(searchByFundAndAccNo);
                }
                else if (searchByFundAndAccNo.length === 0 && searchValue.length > 0) {
                    setData([]);
                    toast.error(response.data.message);
                }
                else if (AllAccountsByFund.length > 0) {
                    setSearchActive(true);
                    setData(AllAccountsByFund);
                } else {
                    setData([]);
                    toast.error(response.data.message);
                }
            }
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
        setLoading(false);
    };
    const handleSearchValue = (e: any) => {
        setSearchValue(e.target.value);
        if (e.target.value.length === 0) {
            getAccountListUpdated();
            setSearchActive(false);
            // setPageSize('10');
            setSearchValue(e.target.value);
        } else {
            setSearchValue(e.target.value);
        }
    };
    const [statusSetPopup, setStatusSetPopup] = useState(false);
    const [currentAccountStatus, setCurrentAccountStatus] = useState('');
    const [code, setCode] = useState('');
    const [accountLoading, setAccountLoading] = useState(false);
    const updateStatus = async () => {
        setAccountLoading(true);
        let status = '';
        if (currentAccountStatus === 'active') {
            status = 'deactive';
        } else {
            status = 'active';
        }
        try {
            const response = await updateAccountStatus(email, code, status);
            if (response.data.status === 200) {
                toast.success(response.data.message);
                setStatusSetPopup(false);
                getAccountListUpdated();
            } else {
                toast.error(response.data.message);
            }
        } catch (err: any) {
            if (err.response.data.status === 500) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }

        }
        setAccountLoading(false);
    };




    const renderUpdateAccountStatusPopup = () => {
        switch (statusSetPopup) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal60w"
                        enforceFocus={false}
                        show={true}
                    >
                        <div className="modal-header justify-content-center">
                            <button aria-hidden="true" className="close" onClick={() => {
                                setStatusSetPopup(false);
                            }} data-dismiss="modal" type="button">
                                <i className="tim-icons icon-simple-remove"></i>
                            </button>
                            <h6 className="title title-up">Action</h6></div>
                        <div className="modal-body">
                            <div className="text-center">
                                <Row>
                                    <div className="mx-auto">
                                        {currentAccountStatus === 'active' ? (
                                            <img src="assets/ban.svg" alt="" width="70" />
                                        ) : (
                                            <img src="assets/check.svg" alt="" width="70" />
                                        )}
                                        <h1 className="pb-3">Are You Sure?</h1>
                                        Are you sure you want to{' '}
                                        {currentAccountStatus === 'active'
                                            ? 'Deactivate'
                                            : 'Activate'}{' '}
                                        this Account?
                                    </div>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="">
                                            <button
                                                className="btn btn-primary"
                                                disabled={Boolean(accountLoading)}
                                                onClick={() => {
                                                    updateStatus();
                                                }}
                                            >
                                                {accountLoading ? (
                                                    <>
                                                        <span
                                                            className="spinner-border login-txt spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <span className="login-txt"> Loading...</span>
                                                    </>
                                                ) : (
                                                    'Yes'
                                                )}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="">
                                            <button
                                                className="btn btn-default"
                                                onClick={() => {
                                                    setStatusSetPopup(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };



    const [updateAmountLoading, setUpdateAmountLoading] = useState(false);
    const [updateAmountError, setUpdateAmountError] = useState('');

    const refreshValidate = () => {
        let updateAmountErr = ''
        updateAmount === '' ? (updateAmountErr = 'Required') : (updateAmountErr = '');
        if (updateAmountErr) {
            setUpdateAmountError(updateAmountErr);
            return false;
        } else {
            return true;
        }
    }

    const updateAmountofAccount = async () => {
        const isValid = refreshValidate();
        if (isValid) {
            setUpdateAmountLoading(true)
            //   try {
            //     const response = await RefreshTransactionLimit(email, txnEmail);
            //     if (response.data.status === 200) {
            //       toast.success(response.data.message);
            //       setUpdateAmountLoading(false);
            //     } else {
            //       toast.error(response.data.message);
            //     }
            //   } catch (error: any) {
            //     toast.error(error.response.data.message)
            //   }
            setUpdateAmountLoading(false);
        }
    }

    const [updateAmountPopup, setUpdateAmountPopup] = useState(false);

    const renderModalRefreshTransactionLimit = () => {
        switch (updateAmountPopup) {
            case true:
                return (
                    <Modal
                        className=" "
                        dialogClassName="modal90w"
                        show={true}
                        enforceFocus={false}
                        size="lg"
                        backdrop={true}
                        onHide={() => {

                        }}
                    >
                        <div className="modal-header">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setUpdateAmountPopup(false);
                                    //   setTxnEmailError('');
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <div>
                                <h6 className="title title-up">Update Amount</h6>

                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="  ml-3 mt-3 mb-3 mr-4">

                                <ReactTooltip
                                    textColor="white"
                                    backgroundColor="black"
                                    effect="float"
                                />

                                <div className="form-group">
                                    <div>Previous Amount</div>
                                    <NumberFormat
                                        thousandSeparator={true}
                                        inputMode="numeric"
                                        className={`form-control text-right w-100`}
                                        value={parseFloat(previousAmount || '0').toFixed(2)}
                                      readOnly
                                    />
                                </div>
                                <div className="form-group">
                                    <div>Update Amount</div>
                                    <NumberFormat
                                        thousandSeparator={true}
                                        inputMode="numeric"
                                        className={`form-control text-right w-100 ${updateAmountError ? 'required-border' : ''}`}
                                        value={parseFloat(updateAmount || '').toFixed(2)}
                                        onValueChange={(e) => {
                                            setUpdateAmount(e.value);
                                            setUpdateAmountError('');
                                        }}
                                    />
                                </div>
                                <div className=" ">

                                    <div className=" mt-3">
                                        <button type="button" disabled={Boolean(updateAmountLoading)} className="btn btn-primary btn-block"
                                            onClick={() => {
                                                updateAmountofAccount();

                                            }}>
                                            {updateAmountLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border login-txt spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="login-txt"> Searching...</span>
                                                </>
                                            ) : (
                                                'Update Amount'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };

    const renderData = () => {
        return res.map((items: any, index: any) => {
            return (
                <tr key={index}>
                    <td>{items.account_code.replace('ACCOUNT_', '')}</td>
                    <td>
                        <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                        />
                        {items.sma === 'true' ? (
                            <FaCrown
                                data-tip="Special Account"
                                className="crown-icon ml-1"
                            />
                        ) : (
                            ''
                        )}
                        <span className="ml-1">{items.amc_code} </span>
                    </td>
                    <td>{items.fund_code}</td>
                    <td>{items.bank_name}</td>
                    <td>{items.nature_of_account}</td>
                    <td>{items.account_no}</td>
                    <td>{numberWithCommas(items.balance_amount)}</td>
                    <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
                    <td ><p style={{ color: items.is_auto_settled === 'true' ? "green" : "white" }}>{items.is_auto_settled}</p> </td>
                    <td className="captilize">{items.status}</td>
                    <td >
                        <div className="mr-3 pointer">
                            {items.status !== 'active' ? (
                                <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                                    onClick={() => {
                                        setStatusSetPopup(true);
                                        setCurrentAccountStatus(items.status);
                                        setCode(items.account_code.replace('ACCOUNT_', ''));
                                    }}
                                >
                                    <i className="tim-icons icon-simple-remove"></i>
                                </button>
                            ) : (
                                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                                    onClick={() => {
                                        setStatusSetPopup(true);
                                        setCurrentAccountStatus(items.status);
                                        setCode(items.account_code.replace('ACCOUNT_', ''));
                                    }}
                                >
                                    <i className="fa fa-check"></i>
                                </button>
                            )}

                            <button type="button" className="btn-icon btn-link like btn btn-info btn-sm mr-2" onClick={() => {
                                sessionStorage.setItem('accountObj', JSON.stringify(items));
                                history.replace(`/admin/edit-account`);
                            }}>
                                <i className="tim-icons icon-pencil"> </i>
                            </button>
                            {/* <button data-tip={'Update Amount.'} className="btn-icon btn-link like btn btn-primary btn-sm" onClick={async () => {
                                if (items?.status !== 'active') {
                                    toast.error('Account Status is not Active');
                                } else {
                                    setUpdateAmountPopup(true);
                                    setPreviousAmount(parseFloat(items.balance_amount || '0'));
                                    // await setTxnEmail(user.email);
                                    // addTxnLimit(user.email);
                                }
                            }}
                            ><i className="fa fa-plus"></i></button> */}
                        </div>


                    </td>
                </tr>
            );
        });
    };

    const numberWithCommas = (amount: any) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const clearVal =()=>{
        
        setFund('')
        setAmc('')
        setSearchValue('');
        getAccountList();
        setActivePage('');
    }


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Bank Account Listing
                                        <div
                                            className="btn btn-primary btn-sm mt-a float-right"
                                            onClick={() => {
                                                history.replace('/admin/setup-accounts');
                                            }}
                                        >
                                            <span><i className="fa fa-plus mr-2"></i>Add New</span>
                                        </div></h4>

                                </div>

                                <div className="card-body">
                                    <Row>
                                        <Col md="3">
                                            <div className="form-group d-inline-flex">
                                                <div className="text-nowrap mr-2 mt-2">Select AMC</div>
                                                <select
                                                    className={'form-control'}
                                                    value={Amc}
                                                    onChange={(e) => {
                                                        setAllFunds([]);
                                                        setFund([]);
                                                        setAmc(e.target.value);
                                                        getFunds(e.target.value);
                                                        //   setFundErr('');
                                                    }}
                                                >
                                                    <option value="" defaultChecked hidden>
                                                        Select AMC
                                                    </option>
                                                    {renderAmcDropDown()}
                                                </select>
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div className="form-group d-inline-flex">
                                                <div className="text-nowrap mr-2 mt-2">Select Fund</div>
                                                <select
                                                    className="form-control"
                                                    value={fund}
                                                    onChange={(e) => {
                                                        setFund(e.target.value)
                                                        getAllAccountByFund(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked hidden>
                                                        Select Fund
                                                    </option>
                                                    {renderFundDropDown()}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <ReactTooltip
                                                textColor="white"
                                                backgroundColor="black"
                                                effect="float"
                                            />
                                            <div className="form-group">
                                                <input
                                                    type="search"
                                                    id="myInput"
                                                    placeholder="Enter Account No"
                                                    className="form-control"
                                                    data-tip="Account No"
                                                    value={searchValue}
                                                    onChange={(e) => {
                                                        handleSearchValue(e);
                                                    }}
                                                    onKeyPress={(e) => {
                                                        let key_board_keycode = e.which || e.keyCode;
                                                        if (key_board_keycode == 13) {
                                                            e.preventDefault();
                                                            searchAccData()
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="4">
                                            <div className="btn btn-primary mt-a" onClick={searchAccData}>
                                                <i className="fa fa-search"></i> Search
                                            </div>
                                            <div className="btn btn-default mt-a" onClick={() => clearVal()}>
                                                <i className="fa fa-clear"></i> Clear
                                            </div>
                                        </Col>

                                    </Row>
                                    {!Loading ? (
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Account Code</th>
                                                        <th>AMC Code</th>
                                                        <th>Fund Code</th>
                                                        <th>Bank Code</th>
                                                        <th>Nature</th>
                                                        <th>Account No</th>
                                                        <th>Net Amount</th>
                                                        <th>Created At</th>
                                                        <th>Auto Settled</th>
                                                        <th>status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{renderData()}</tbody>
                                            </table>
                                            {!isSearchActive && (
                                                <div
                                                    className="row p-3 pointer"
                                                    style={{
                                                        float: 'right',
                                                    }}
                                                >
                                                    {/* PREVIOUS BUTTON */}
                                                    {activePage !== paginationArr[0] ? (
                                                        <i
                                                            className="fa fa-arrow-left"
                                                            aria-hidden="true"
                                                            onClick={() => {
                                                                let pageArr = paginationArr;
                                                                let index = pageArr.findIndex(
                                                                    (x) => x === activePage
                                                                );
                                                                if (index !== -1) {
                                                                    setActivePage(paginationArr[index - 1]);
                                                                }
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {/* NEXT BUTTON */}
                                                    {activePage !== paginationArr[paginationArr.length - 1] ? (
                                                        <i
                                                            className="fa ml-3 fa-arrow-right"
                                                            aria-hidden="true"
                                                            onClick={() => {
                                                                let pageArr = paginationArr;
                                                                let index = pageArr.findIndex(
                                                                    (x) => x === activePage
                                                                );
                                                                if (index !== -1) {
                                                                    setActivePage(paginationArr[index + 1]);
                                                                }
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center my-4">
                                            <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                                        </div>
                                    )}
                                </div>

                                {renderUpdateAccountStatusPopup()}
                                {renderModalRefreshTransactionLimit()}
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AccountList;