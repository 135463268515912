
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup
} from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { generateTwoFa, getLoggedinUserInfo, login, twoFALogin } from './../../stores/services/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { getCaptype, getTxnCatagory } from './../../stores/services/transactions.service';
// import Particles from 'react-particles-js';
import ParticlesComp from '../ParticlesComp';
import Auth from '../../Auth';
import { env } from "./../../env";
import ReCAPTCHA from "react-google-recaptcha";
import { getFeatures } from '../../stores/services/role.service';
import './Calculator.css';

const Login = () => {
  const { txnr_id } = useParams<any>();
  let first_input: HTMLInputElement | null = null;
  let second_input: HTMLInputElement | null = null;
  let third_input: HTMLInputElement | null = null;
  let fourth_input: HTMLInputElement | null = null;
  let fifth_input: HTMLInputElement | null = null;
  let sixth_input: HTMLInputElement | null = null;
  const history = useHistory();
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [twoFACode, setTwoFACode] = useState('');
  const [firstCode, setFirstCode] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [thirdCode, setThirdCode] = useState('');
  const [fourthCode, setFourthCode] = useState('');
  const [fifthCode, setFifthCode] = useState('');
  const [sixthCode, setSixthCode] = useState('');
  const [inputType, setInputType] = useState('password');
  const emailRegex = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
  const verify_transaction = sessionStorage.getItem('verify_transaction') || '';

  const [loginEnable, setLoginEnable] = useState(false);
  const [loggedUserInfo2Fa, setloggedUserInfo2Fa] = useState<any>([])

  React.useEffect(() => {
    if (env.CAPTCHA_ENABLED.toString() == 'false') {
      setLoginEnable(true);
    }
    document.title = `${env.REACT_APP_TITLE}`
  }, []);
  const handleClick = () => {
    inputType === 'text' ? setInputType('password') : setInputType('text');
  };
  const validate = () => {
    let emailErr,
      passErr = '';
    email.trim() === ''
      ? (emailErr = 'Email Required')
      : emailRegex.test(email.toLowerCase()) !== true
        ? (emailErr = 'Invalid Email')
        : (emailErr = '');
    password.trim() === '' ? (passErr = 'Password Required') : (passErr = '');
    if (emailErr || passErr) {
      setEmailError(emailErr);
      setPasswordError(passErr);
      return false;
    } else {
      return true;
    }
  };

  function onChange(value) {

    // const isValid = validate();
    setLoginEnable(true);
  }

  const loginBtn = async () => {
    const isValid = await validate();
    if (isValid) {
      try {
        setLoading(true);
        const response = await login(email.toLowerCase(), password);
        if (response.data.status == 200) {
          if (response.data.data?.two_fa_enabled && response.data.data?.two_fa_enabled === 'true') {
            toast.success(response.data.message)

            setPopup(true);
            setLoading(false);
          } else {

            try {
              sessionStorage.setItem('token', response.data.token);
              sessionStorage.setItem('refreshToken', response.data.refreshToken);
              const userInfo = await getLoggedinUserInfo(email.toLowerCase());

              if (userInfo.data.status == 200) {
                try {
                  const response = await getCaptype(email.toLowerCase());
                  sessionStorage.setItem('txnType', JSON.stringify(response.data.txntypes));
                } catch (error) { }
                try {
                  const response = await getTxnCatagory(email.toLowerCase());
                  sessionStorage.setItem('txnTypeCatagory', JSON.stringify(response.data.txn_catagory));
                } catch (error) { }
                try {
                  const response = await getFeatures(email.toLowerCase());
                  sessionStorage.setItem('allFeatures', JSON.stringify(response.data.features));
                } catch (error) { }

                toast.success(userInfo.data.message);

                sessionStorage.setItem('additional_features', userInfo.data.data.additional_access);
                sessionStorage.setItem('denied_features', userInfo.data.data.denied_access);
                sessionStorage.setItem('name', userInfo.data.data.name);
                sessionStorage.setItem('email', userInfo.data.data.email.toLowerCase());
                sessionStorage.setItem('role', userInfo.data.data.role);
                sessionStorage.setItem('features', JSON.parse(userInfo.data.data.features));

                sessionStorage.setItem(
                  'loggedInUser',
                  JSON.stringify(userInfo.data.data)
                );
                setLoading(false);
                Auth.authenticate();
                sessionStorage.setItem('isAuthenticated', 'true');
                if (verify_transaction && verify_transaction!=='' && verify_transaction.includes('/admin/verify-transaction/')) {
                  history.replace(`${verify_transaction}`);
                } else {
                  history.replace('/admin/home');
                }

              }
              else {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                toast.error(userInfo.data.message);
              }
            } catch (err: any) {
              if (err.response !== undefined) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message)
              }

            }
          }
        } else {
          toast.error(response.data.message);
        }

      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
        setLoading(false);
      }
      setLoading(false);
    }
  }
  const login2FaBtn = async () => {
    setLoading(true);
    if (!firstCode) {
      setLoading(false);
      return;
    }
    if (!secondCode) {
      setLoading(false);
      return;
    }
    if (!thirdCode) {
      setLoading(false);
      return;
    }
    if (!fourthCode) {
      setLoading(false);
      return;
    }
    if (!fifthCode) {
      setLoading(false);
      return;
    }
    if (!sixthCode) {
      setLoading(false);
      return;
    }
    setTwoFACode(`${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`);
    try {
      const response = await twoFALogin(email.toLowerCase(), `${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`);
      if (response.data.status == 200) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('refreshToken', response.data.refreshToken);
        setPopup(false);
        setLoading(false);

        toast.success(response.data.message);
        try {
          const userInfo = await getLoggedinUserInfo(email.toLowerCase());

          try {
            const response = await getCaptype(email.toLowerCase());
            sessionStorage.setItem('txnType', JSON.stringify(response.data.txntypes));

          } catch (error) { }
          try {
            const response = await getTxnCatagory(email.toLowerCase());
            sessionStorage.setItem('txnTypeCatagory', JSON.stringify(response.data.txn_catagory));

          } catch (error) { }
          try {
            const response = await getFeatures(email.toLowerCase());
            sessionStorage.setItem('allFeatures', JSON.stringify(response.data.features));

          } catch (error) { }

          sessionStorage.setItem('name', userInfo.data.data.name);
          sessionStorage.setItem('email', email.toLowerCase());
          sessionStorage.setItem('role', userInfo.data.data.role);
          sessionStorage.setItem('additional_features', userInfo.data.data.additional_access);
          sessionStorage.setItem('denied_features', userInfo.data.data.denied_access);
          sessionStorage.setItem('features', JSON.parse(userInfo.data.data.features));

          sessionStorage.setItem(
            'loggedInUser',
            JSON.stringify(userInfo.data.data)
          );

          Auth.authenticate();
          sessionStorage.setItem('isAuthenticated', 'true')

          if (userInfo.data.data.role === 'SIGNATORY-A') {
            if (verify_transaction && verify_transaction!=='' && verify_transaction.includes('/admin/verify-transaction/')) {
              history.replace(`${verify_transaction}`);
            } else {
              history.replace('/admin/home');
            }
          } else if (userInfo.data.data.role === 'SIGNATORY-B') {
            if (verify_transaction && verify_transaction!=='' && verify_transaction.includes('/admin/verify-transaction/')) {
              history.replace(`${verify_transaction}`);
            } else {
              if (verify_transaction && verify_transaction!=='' && verify_transaction.includes('/admin/verify-transaction/')) {
                history.replace(`${verify_transaction}`);
              } else {
                history.replace('/admin/home');
              }
            }
          } else {
            if (verify_transaction && verify_transaction!=='' && verify_transaction.includes('/admin/verify-transaction/')) {
              history.replace(`${verify_transaction}`);
            } else {
              history.replace('/admin/home');
            }
          }
        } catch (error: any) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refreshToken');
          toast.error(response.data.message);
        }
      } else {
        setLoading(false);
        toast.error(response.data.message)
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
    setFirstCode('');
    setSecondCode('');
    setThirdCode('');
    setFourthCode('');
    setFifthCode('');
    setSixthCode('');
  }



 
  return (
    <>

      <ParticlesComp />
      <div className="container-login"  >
        <ToastContainer />
        <div className={`fa-popup ${popup ? '' : 'hidden'}`}>
          <div className="popup card-login card-white card mb-0">
            <p className="  text-black"> <strong> Two-Factor Authentication</strong> </p>
            <p className="text-black">Please enter One-Time Password (OTP)</p>
            <div className="digit-holder mx-auto ">
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    first_input = input;
                  }}
                  value={firstCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFirstCode(e.target.value);
                      second_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    second_input = input;
                  }}
                  value={secondCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSecondCode(e.target.value);
                      third_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    third_input = input;
                  }}
                  value={thirdCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setThirdCode(e.target.value);
                      fourth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fourth_input = input;
                  }}
                  value={fourthCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFourthCode(e.target.value);
                      fifth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fifth_input = input;
                  }}
                  value={fifthCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFifthCode(e.target.value);
                      sixth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    sixth_input = input;
                  }}
                  value={sixthCode}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSixthCode(e.target.value);
                    }
                    return;
                  }}
                />
              </FormGroup>
            </div>
            <div className="btn-holder">
              <button
                className="btn btn-primary bg-positive"
                onClick={login2FaBtn}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Confirm</span>
                )}
              </button>
              <button
                className="btn btn-default bg-negative"
                onClick={() => {
                  setFirstCode('');
                  setSecondCode('');
                  setThirdCode('');
                  setFourthCode('');
                  setFifthCode('');
                  setSixthCode('');
                  setPopup(false);
                }}
              >
                Cancel
              </button>

            </div>
          </div>
        </div>


        <Col className="" lg="4" md="6">
          <Card className="card-login card-white  bg-transparent">

            <CardHeader className="d-flex justify-content-center py-15 ">
              <img
                className="opacity-img"
                alt=""
                src={require("../../assets/MACS-Core Logo.png").default} width={"155px"}
              />
              <br />
            </CardHeader>
            <CardBody >
              <div className="login-section">
                <label className="">Email</label>
                <FormGroup>
                  <input type="text" className="form-control " placeholder="Enter Your Email" value={email} onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        // loginBtn();
                      }

                    }}
                  />
                  {emailError ? (
                    <p className="error-labels login-err">{emailError}</p>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </div>
              <div className="">
                <label>Password</label>
                <FormGroup>
                  <input
                    placeholder="Enter Your Password"
                    type={inputType}
                    className="form-control"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError('');
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        // loginBtn();
                      }

                    }}
                  />
                  {inputType === 'text' ? (
                    <MdVisibility
                      className="visibility-icon"
                      onClick={() => handleClick()}
                    />
                  ) : (
                    <MdVisibilityOff
                      className="visibility-icon"
                      onClick={() => handleClick()}
                    />
                  )}
                  {passwordError ? (
                    <p className="error-labels pass-err">{passwordError}</p>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </div>

              <div>
                {env.CAPTCHA_ENABLED.toString() == 'true' && (
                  <ReCAPTCHA
                    sitekey="6Le0HoAdAAAAADxrbEVg8ksdFb8AQMLROtEQu2go"
                    onChange={onChange}
                    onExpired={(e) => {
                      setLoginEnable(false);
                    }}
                  />
                )}
              </div>

            </CardBody>


            <CardFooter className="d-flex  space-between">
              {loginEnable === true && (
                <div className=" ">
                  <button
                    className="btn btn-primary "
                    onClick={(e) => {
                      loginBtn();
                    }}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="fa fa-spinner fa-spin"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      <span className="login-text">Login</span>
                    )}
                  </button>

                </div>
              )}

              <Link to="/forgot-password" className="mt-2" replace>
                Forgot Password?
              </Link>
            </CardFooter>

          </Card>
          <div className='text-center'>{env.REACT_APP_TITLE} <br /> {env.REACT_APP_VERSION}</div>
        </Col>
      </div>
    </>
  );
};

export default Login;
