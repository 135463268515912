import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { getUnitHoldersByAmcAndFolioNo } from '../../../stores/services/unit-holder.service';
import { getAmc } from '../../../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';
import { numberWithCommas } from '../../../utils/customFunction';
import ReactTooltip from 'react-tooltip';
import { sortAMCS } from '../../../utils/customFunction';
import { getFundByAmc } from '../../../stores/services/funds.service';

const csv = require('csvtojson/v2');

const ClientPortfolio = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);

  const [allAmc, setAllAmc] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [allFunds, setAllFunds] = useState<any>([]);

  const [amcError, setAmcError] = useState('');

  React.useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}-{item.amc_code.replace('AMC_', '')}
        </option>
      );
    });
  };
  const getUnitHoldersByAmcFolioNo = async (email, amc_code, folio_no) => {
    try {
      if (amc_code === '') {
        setAmcError('required-border');
      } else {
        setLoading(true);
        const response = await getUnitHoldersByAmcAndFolioNo(email, amc_code, amc_code + '-' + folio_no);
        if (response.data.status === 200) {
          if (response.data.data.length > 0) {
            await portfolio(response.data.data);
          } else {
            setTotalAmount('');
            setUnits('');
            setSelected([]);
            toast.error('Data not found.');
          }
        } else {
          toast.error(response.data.message);
        }
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const portfolio = (data) => {
    // let value = data.filter((item: any) => {
    //   return item.folio_no === folio_no;
    // });
    // setFolio_no(value[0].folio_no);
    // setFolio_no(folio_no);
    if (
      data[0].balance_unit !== '' &&
      data[0].balance_unit !== '[]' &&
      data[0].balance_unit !== undefined
    ) {
      let unitBlance = JSON.parse(data[0].balance_unit);
      let unit = 0;
      let amount = 0;
      unitBlance.map((item, index) => {
        let fName = ''
        unit = unit + parseFloat(item.fund_unit?item.fund_unit.toString().replaceAll(",",""): '0');
        amount = amount + parseFloat(item.fund_unit?item.fund_unit.toString().replaceAll(",",""): '0') * parseFloat(item.nav?item.nav.toString().replaceAll(",",""):'0');
        allFunds.map((elem) => {
          if (elem.symbol_code === item.fund_code) {
            fName = elem.fund_name;
          }
        })
        unitBlance[index].fund_name = fName
      });

      setTotalAmount(amount.toFixed(2));
      setUnits(unit.toFixed(2));

      setSelected(unitBlance);
    } else {
      selected.length = 0;
      setSelected(selected);
    }
  }

  const renderData = () => {
    return selected.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td   >
            {items.fund_name ? items.fund_name : 'N/A'}
          </td>
          <td> {items.fund_code ? items.fund_code : 'N/A'}</td>
          <td>
            {parseFloat(items.fund_unit? items.fund_unit.toString().replaceAll(",",""): '0').toFixed(4)}
          </td>
          {/* <td   >{parseFloat(items.nav).toFixed(4)}</td> */}
          <td>{numberWithCommas((parseFloat(items.fund_unit?items.fund_unit.toString().replaceAll(",",""):'0') * parseFloat(items.nav?items.nav.toString().replaceAll(",",""):'0')).toFixed(2))}</td>
        </tr>
      );
    });
  };

  const [selected, setSelected] = useState<any>([]);
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalUnits, setUnits] = useState('');

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="d-flex align-items-center"></div>
                {/* {!loading ? ( */}
                <div className="">
                  <div className="card-header">
                    <h4 className="card-title">
                      Unit Holder Portfolio
                    </h4>
                  </div>
                  <div className="card-body">

                    <div className="row">
                      <div className='col-md-4'>
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className={"form-control " + amcError}
                            onChange={async (e) => {
                              setAmc(e.target.value);
                              // setFolio_no(e.target.value+'-')
                              setAmcError('');
                              try {
                                allFunds.length = 0;
                                setAllFunds(allFunds);
                                const response = await getFundByAmc(email, e.target.value);
                                setAllFunds(response.data.data);
                              } catch (error) {

                              }
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="form-group">
                          <label>Folio Number</label>
                          <input type='text' className='form-control'
                            value={folio_no}
                            data-tip="Press Enter Key to seacrh data."
                            placeholder='Enter Folio no.'
                            onChange={(e) => {
                              setFolio_no(e.target.value);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                //  getUnitHoldersByAmcFolioNo(email, Amc, Amc+'-'+folio_no);
                                getUnitHoldersByAmcFolioNo(email, Amc, folio_no);
                              }
                            }}
                          />
                          {/* <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    label={'Select'}
                    isDisabled={false}
                    onChange={(e) => {
                      setUnitLoading(true);
                      let value = data.filter((item: any) => {
                        return item.folio_no === e.value;
                      });
                      setFolio_no(value[0].folio_no);
                      if (
                        value[0].balance_unit !== '' &&
                        value[0].balance_unit !== '[]' &&
                        value[0].balance_unit !== undefined
                      ) {
                        let unitBlance = JSON.parse(value[0].balance_unit);
                        let unit = 0;
                        let amount = 0;
                        unitBlance.map((item, index) => {
                          unit = unit + parseFloat(item.fund_unit);
                          amount =
                            amount +
                            parseFloat(item.fund_unit) * parseFloat(item.nav);
                        });
                        setTotalAmount(amount.toFixed(2));
                        setUnits(unit.toFixed(2));
                        setSelected(unitBlance);
                      } else {
                        selected.length = 0;
                        setSelected(selected);
                      }
                      setUnitLoading(false);
                      // selected()
                    }}
                    value={data.filter((option) => option.value === folio_no)}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={data}
                  /> */}
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className=" ">
                          <button className="btn btn-primary mt-4 mr-3"
                            disabled={Boolean(loading)}
                            onClick={() => {
                              getUnitHoldersByAmcFolioNo(email, Amc, folio_no);
                            }}>
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Searching...</span>
                              </>
                            ) : (
                              <span> <i className="fa fa-search"></i>Search</span>
                            )}
                          </button>

                        </div>
                      </div>
                    </div>
                    <div className="">
                      {selected.length > 0 ? (
                        <table className="table mt-3">
                          <thead>
                            <tr>
                              <th   >Fund Name</th>
                              <th   >Fund Code</th>
                              <th   >Units Hold</th>
                              {/* <th   >NAV</th> */}
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderData()}
                            <tr className="border-top">
                              <td   ><h3>Total</h3></td>
                              <td></td>
                              <td>{totalUnits}</td>
                              {/* <td></td> */}
                              <td >{numberWithCommas(totalAmount)}</td>
                            </tr>
                          </tbody>
                        </table>
                      ) : unitLoading ? (
                        ''
                      ) : (
                        <div className="table-empty">
                          <BsTable className="icon" />
                          {folio_no === '' ? (
                            <p className="text-center empty-text">
                              Select Unit Holder
                            </p>
                          ) : (
                            <p className="text-center empty-text">Empty</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* ) : (
            <div className="d-flex justify-content-center my-5">
             <i className="fa fa-spinner fa-spin fa-3x" ></i> 
            </div>
          )} */}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClientPortfolio;
