
import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import { isNumber, numberWithCommas } from '../../utils/customFunction';
import Config from '../../config';


export const MoneyMarketPurchaseLetter = (transaction: any, securities: any, amtWords: any, transNetAmount: any, transGrossAmount: any) => {
    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q = "qrcodemmPurchase";
    let id=`${Config.q_r_url }/admin/verify-transaction/`+transaction.txn_id;
    let txnId = transaction.txn_id;
    let branchName = '';
    let branchCity = '';
    //     if(transaction.branch != undefined){
    //         branchName = transaction.branch.split(',')[0];
    //         branchCity = transaction.branch.split(',')[1];
    //         branchName = branchCity === undefined ? branchName+',' : branchName;
    //         branchCity = branchCity === undefined ? '' : branchCity+',';
    // }
    if (transaction.branch != undefined) {
        if (transaction.branch.includes('–')) {
            branchName = transaction.branch.split('–')[0];
            branchCity = transaction.branch.split('–')[1];
            branchName = branchCity === undefined ? branchName.trim() + ',' : branchName.trim() + ',';
            branchCity = branchCity === undefined ? '' : branchCity.trim() + '.';
        } else {
            branchName = transaction.branch + ',';
        }
    }
    setTimeout(() => {
        if (id != undefined) {
            var element = document.getElementById(q)!;
            if (element != null) {
                element.innerHTML = "";
            }
            var qrcode = new QRCode(document.getElementById(q), {
                width: 50,
                height: 50
            });
            qrcode.makeCode(id);
        }

    }, 500)

    const  myFunc = () => {
        let securityData = ""
        securities.map((items: any, index: any) => {

            securityData = securityData + ` 
        <tr><td valign="top"> <b> Instrument Detail </b></td><td colspan="3"><hr /></td></tr> 
        <tr>
            <td valign="top"> Instrument Type</td><td> <b>: ${items.security_type || ''} </b> </td> 
        </tr>
        <tr>
        <td valign="top"> Issue Date</td><td> <b>: ${moment(items.issue_date || '').format('LL')}  </b> </td>
        </tr>
        <tr>            
            <td valign="top"> Maturity Date</td> <td><b>: ${moment(items.maturity_date || '').format('LL')} </b> </td>
        </tr>
        <tr>
            <td valign="top"> Face Value</td><td> <b>: ${numberWithCommas(parseFloat(isNumber(items.total_face_value)).toFixed(2)) || ''}</b>  </td>
        </tr>
        <tr>
            <td valign="top"> Coupon / Yield</td> <td> <b>: ${numberWithCommas(parseFloat(isNumber(items.coupon_rate)).toFixed(4)) || '0.0000'} / ${numberWithCommas(parseFloat(isNumber(items.yield)).toFixed(4)) || '0.0000'} </b></td>
        </tr>
        <tr>
            <td valign="top"> Price</td> <td> <b>: ${numberWithCommas(parseFloat(isNumber(items.price)).toFixed(4)) || ''} </b></td>
        </tr> 
        `
        });
        
        return securityData;
    }

    const securityType = securities.map(u => u.security_type).join(', ');

    // String.prototype.removeDuplicate = function() { 
    // return Array.from(new Set(this.split(', '))).toString();
    // }
    // const numberWithCommas = (amount:any) => {
    //     amount= amount || '0';
    //     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //   } 
    function removeDuplicate(secType) {
        return Array.from(new Set(secType.split('|'))).toString().replace(',', '');
    }

    // add counter party ips account
    const counterIPSAcc = securities.map(u => u.counter_party_ips_account).join(', ');
    // Counter Bank
    const counterBank = securities.map(u => u.counter_bank).join(', ');

    const counterPartyName = securities.map(u => u.counter_party_name).join(', ');

    const brokerName = securities.map(u => u.broker_name);



    return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${branchName}<br /> ${branchCity}</div>
            <br />
            <div> Sub: <b style="border-bottom:1px solid black">Purchase of ${removeDuplicate(securityType)} with ${counterPartyName}</b></div>
            <br />
            <table style="width: 100%;" >
                <tr>
                    <td valign="top" width="20% !importnat"> Settlement Date</td> 
                    <td width="80% !importnat"><b>: ${moment(transaction.settlement_date).format('LL')}</b></td> 
                </tr>
                 <tr>
                    <td valign="top">Fund Account Title</td> 
                    <td ><b>: ${transaction.account_title}   </b></td> 
                </tr>
                 <tr>
                    <td valign="top"> Fund  Account No.</td> 
                    <td colspan="3"><b>: ${transaction.account_number || ''} (${transaction.bank}, ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch}) </b></td> 
                </tr>
                 <tr>
                    <td valign="top"> Broker</td> 
                    <td ><b>: ${brokerName} </b></td> 
                </tr>
                 <tr>
                    <td valign="top"> Mode of Payment </td> 
                    <td ><b>: ${transaction.mode_of_payment} </b></td> 
                </tr>
                <tr>
                    <td valign="top"> Amount</td> 
                    <td valign="top">
                        <b>: PKR: ${numberWithCommas(transNetAmount)} (Rupees ${amtWords}) </b>
                    </td> 
                </tr> 

                ${myFunc()}

                <tr>
                    <td style={text:"nowrap";}> <b> Counter Party Detail:</b></td> 
                    <td colspan="3"><hr /></td>
                </tr>

                <tr>
                    <td style={text:"nowrap";} valign="top"> Counter Party Name</td> 
                    <td><b>: ${counterPartyName}  </b></td> 
                </tr>
                <tr>
                    <td style={text:"nowrap";} valign="top"> Bank Account Detail </td> 
                     <td><b>: ${transaction.counter_bank} </b></td> 
                </tr> 
                <tr>
                    <td style={text:"nowrap";}> SGL / IPS Account Detail </td> 
                    <td colspan="3"><b>: ${counterIPSAcc} </b></td> 
                </tr>
            </table>
            <br />
            <div style="text-align:justify"> 
                Kindly receive the above mentioned instrument in our IPS account maintain with your branch from counter
                party and simultaneously transfer the above mentioned settlement amount on above settlement date through ${transaction.mode_of_payment} 
                transfer to counter party by debiting our above mention account maintain with your branch.
             </div>

            <div>Further a cheque  <b>(#  ${transaction.instrument_no}) </b> of the said settlement amount is being enclosed herewith.</div>
          
            <br />
            <div>Kindly note that transaction will be made on RVP basis.</div>
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `
}
