import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import {
  uploadUnitHolding
} from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';	
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import {JsonToTable} from 'react-json-to-table';
import xls from "xlsx";
import { resetWarningCache } from 'prop-types';
const csv = require('csvtojson/v2');
//const xlsx = require('xlsx-to-json');

const UploadUnitHolding=()=>{
const email = sessionStorage.getItem('email') || '';
const role = sessionStorage.getItem('role') || '';
const history = useHistory();
const [file, setFile] = useState('');
const [fileName, setFileName] = useState('');
const [fileError, setFileError] = useState('');
const [fileData, setfileData] = useState<any>([]);
const myRef1 = useRef<HTMLInputElement>(null);
const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
const[fileDataCount, setFileDataCount]=useState(0);
const [loading, setLoading] = useState(false);
const [folio_no, setFolio_no]=useState('');

   const Uploadunitholding = async () => {
    if (file) {
        try {
          setLoading(true);
          const res = await uploadUnitHolding(email, fileData);
          if(res.data.status==200){
          toast.success('Unit Holding uploaded successfully');
          }else{
            toast.error(res.data.message)
          }
          setFile('');
          setFileName('');
          fileData.length=0;
          setfileData(fileData);
          setFileDataCount(0);
         
        } catch (err:any) {
          setLoading(false);
          if(err.response !== undefined ) {
            toast.error(err.response.data.message);	
          }else {
            toast.error(err.message)
          }
        }
        setLoading(false);
      } else {
        toast.error('Kindly Upload File');
      }
  };

  const upload = async (e: any) => {
    fileData.length=0;
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if(type==="xlsx" || type === 'csv'){
      setFile(file);
      setFileName(file.name);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
     if(data){
      setfileData(data);
      setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

    return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
    <Container fluid>
      <ToastContainer limit={1} />
      <div className="card">
        <div className="d-flex align-items-center"></div>
      
          <div className="">
            <div className="card-header">
              <h4 className="card-title">Upload Unit Holding
              <a className='float-right' href="./../../Uploader-Sample/Upload-Unit-Holding.xlsx" download>Download Sample</a>
              </h4>
              
            </div>
            <div className="card-body"> 
            <div className="col-md">
            <Link		
                to="/admin/unit-holders"		
                className="t-3 ml-auto btn btn-primary btn-sm float-right"		
                replace		
              >		
               <i className="fa fa-eye mr-2"></i> View All		
              </Link>
              {role==='ADMIN' ? (
                <>
                      <button
                    className="btn btn-primary btn-sm float-right mr-3"
                    onClick={() => {
                      history.replace(`/admin/upload-multiple-unitholding`)
                    }}
                  > 
                    <span>  <i className="fa fa-upload mr-2"></i>Upload Multiple Unit Holding</span>
                  </button>
                  </>
                ):(
                  ''
                )}  
                      </div>
            {/* <div className="ml-3 mr-3"> */}
                  <div className="row">
                      <div className="col-md-4">
                      <label>Choose File</label>
                      
                  <div onClick={() => myRef1?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Upload Unit Holding File">
                          
                            {fileName === '' ? 'Upload File' : fileName}
                          </div> 
                          <input
                            type="file"
                            ref={myRef1}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Upload File"	
                            onChange={(e) => {
                              upload(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">{fileError}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </div>
                  </div>
                  {/* </div> */}

              <div className="table-responsive">
              <JsonToTable json={fileData} />
              </div>

              Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>

              <div className="">	 
                    <button
                    className="btn btn-info"
                    onClick={() => {
                        Uploadunitholding();
                    }}
                    disabled={Boolean(loading)}
                  > 
                
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  </div>

          </div>
          </div>

      </div>
    </Container>
    </Col>
        </Row>
      </div>
  </>	
    );
};

export default UploadUnitHolding;