

import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ViewTransaction from './../transactions/view-beta-txn';
import { AiOutlineBook } from 'react-icons/ai';
import {
  getMailTemplates,
  inWords,
  getAllLetterTemplates,
  SendMailTo,
  getAllBankTemplates,
} from './../../stores/services/template.service';
import { getAllPendingTransactions, getLedger, realizedMarkLedger, searchTransactions } from '../../stores/services/transactions.service';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import jsPDF from 'jspdf';
import { useHistory } from 'react-router-dom';
import { RiCheckboxMultipleLine, RiContactsBookLine, RiMailSendFill, RiPrinterLine } from 'react-icons/ri';
import { BsFileRichtext } from 'react-icons/bs';
import { BsTable } from 'react-icons/bs';
import { getAllBanks } from '../../stores/services/bank.service';
import { toast, ToastContainer } from 'react-toastify';

import { ReceiveOfFundRTGSEmailTemplate } from '../letterTemplates/inflowRTGS.letter.template';
import { OnlineTransferOfFundLetter } from '../letterTemplates/onlineTF.letter.template';
import { TransferOfFundRTGSEmailTemplate } from '../letterTemplates/outflowRTGS.letter.template';
import { demandDraftLetterTemplate } from '../letterTemplates/demandDraft.letter.template';
import { MoneyMarketPurchaseLetter } from '../letterTemplates/mmPurcahse.letter.template';
import { mmMaturityOfSecurityLetter } from '../letterTemplates/mmMaturityOfSecurity.letter.template';
import { mmMaturityOfCouponLetter } from '../letterTemplates/mmMaturityOfCoupon.letter.template';
import { MoneyMarketSaleLetter } from '../letterTemplates/mmSale.letter.template';
import { equityInvestmentLetter } from '../letterTemplates/equityInvestment.letter.template';
import { transferInFundRTGSEmailTemplate } from '../letterTemplates/transferIn.letter.template';
import createDOMPurify from 'dompurify';
import ReactExport from "react-export-excel";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import LetterFooter from '../letterTemplates/letter-footer';
import { AiFillCloseCircle, AiOutlineClose } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { IoMdClose } from 'react-icons/io';
import { isDateValid, isNumber } from '../../utils/customFunction';
import { addMegaTransaction } from '../../stores/services/beta-transaction.service';
import { Hidden } from '@material-ui/core';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { getUnitHoldersByAmcAndFolioNo } from '../../stores/services/unit-holder.service';
import { searchBankAccAPI } from '../../stores/services/account.service';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const DOMPurify = createDOMPurify(window);

const PendingTransactions = () => {
  const role_name = sessionStorage.getItem('role') || '';
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const history = useHistory();
  let [transactions, setTransactions] = useState<any>([]);
  let [status, setStatus] = useState('-1');
  let [searchValue, setSearchValue] = useState('');
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const email = sessionStorage.getItem('email') || '';
  const role = sessionStorage.getItem('role') || '';
  const [allLetterTemplates, setAllLetterTemplates] = useState<any>([]);
  const [letterLoading, setLetterLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);

  const [mailModelBoolean, setMailModelBoolean] = useState(false);
  const [mailTempLoading, setMailTempLoading] = useState(false);
  const [mailTempData, setMailTempData] = useState<any>([]);

  const [chooseTemplate, setChooseTemplate] = useState('');
  const [securityData, setSecurityData] = useState<any>([]);
  const [transType, setTransType] = useState<any>([]);
  const [transMode, setTransMode] = useState<any>([]);
  const [mmCoupon, setMMCoupon] = useState<any>([]);
  const [amtWords, setAmtWords] = useState<any>([]);
  const [grossAmtWords, setGrossAmtWords] = useState<any>([]);
  const [transNetAmount, setTransNetAmount] = useState<any>([]);
  const [transGrossAmount, setTransGrossAmount] = useState<any>([]);
  const [paymentType, setPaymentType] = useState<any>('');
  const [mmType, setMMType] = useState<any>([]);
  const [ledgerData, setLedgerData] = useState<any>([]);


  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [counter, setCounter] = useState('0');

  const [ccEmail, setCCEmail] = useState<any>([]);
  const [toEmail, setToEmail] = useState<any>([]);
  const [sendEmailModalView, setSendEmailModalView] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  let date1: any = new Date();

  const [Amc, setAmc] = useState('');
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState('');

  const [togglePagination, setTogglePagination] = useState(false);

  const [bankData, setBankData] = useState<any>([]);

  const [color, setColor] = useState("#db6cfa");
  const styles = {
    color: color
  }


  React.useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(email);
        setBankData(bankResponse.data.data);
      } catch (error) {
      }
    };
    fetchBank();
  }, []);

  React.useEffect(() => {
    async function getTransactions() {
      if (togglePagination != true) {
        setResLoading(true);
        await getAllPendingTransactions(
          '',
          sessionStorage.getItem('email') || '',
          status === '-1' ? '' : status,
          searchValue,
          activePage
          // sessionStorage.getItem('email') || '',	
          // status,	
          // searchValue,	
          // '',	
          // activePage
        )
          .then((response) => {
            if (response.data.status === 200) {
              let pageArr = paginationArr;
              let existingPageIndex = paginationArr.findIndex(
                (x) => x === response.data.page_info.next_page_number
              );
              if (
                existingPageIndex === -1 &&
                response.data.data.length === 10
                // &&	
                // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
              ) {
                pageArr.push(response.data.page_info.next_page_number);
              }
              setPaginationArr(pageArr);
              setTransactions(response.data.data);
              setResLoading(false);
            } else if (response.data.status === 404) {
              toast.error(response.data.message);
              setTransactions([]);
              setResLoading(false);
            }
          })
          .catch((err) => {
            setResLoading(false);
          });
        setResLoading(false);
      } else { // if search is enable
        setResLoading(true);
        if (searchValue !== '') {
          searchValue = 'TXN_' + searchValue;
        }
        await searchTransactions(
          sessionStorage.getItem('email') || '',
          Amc,
          fund,
          status === '-1' ? '' : status,
          searchValue,
          activePage
        )
          .then((response) => {

            if (response.data.data.length > 0) {
              let pageArr = paginationArr
              let existingPageIndex = paginationArr.findIndex(
                (x) => x === response.data.page_info.next_page_number
              );
              if (
                existingPageIndex === -1 &&
                response.data.data.length === 10
                // &&	
                // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
              ) {
                pageArr.push(response.data.page_info.next_page_number);
              }
              setPaginationArr(pageArr);
              setTransactions(response.data.data);
            } else {
              setTransactions([]);
            }
            setResLoading(false);

          })
          .catch((err) => {
            setResLoading(false);
          });
        setResLoading(false);

      }
    }
    getTransactions();
  }, [activePage]);

  useEffect(() => {
    const fetchMailTemp = async () => {
      try {
        const response = await getMailTemplates();
        setMailTempData(response.data.template);
        if (response.data.template.length < 1) {
          setMailTempLoading(true);
        } else {
          setMailTempLoading(false);
        }
      } catch (error) { }
    };
    fetchMailTemp();
  }, []);

  const getLetterTemplateCall = async (bankName) => {
    try {
      const response = await getAllLetterTemplates(email, bankName);
      setAllLetterTemplates(response.data.data);
      if (response.data.data.length < 1) {
        setLetterLoading(true);
      } else {
        setLetterLoading(false);
      }
    } catch (error) { }
  };

  //render dropdown for mail data
  const renderMailTemplateDropdown = () => {
    return mailTempData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.value}>
          {item.name}
        </option>
      );
    });
  };


  //render dropdown for security data	
  const renderLetterTemplateDropdown = () => {
    return allLetterTemplates.map((item: any, index: string) => {
      return (
        <option key={index} value={item.template_detail}>
          {item.template_name}
        </option>
      );
    });
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const bankName = (bankcode) => {
    const bankdata = bankData.filter((item: any, index: string) => {
      return (item.bank_code.replace('BANK_', '') === bankcode);
    });
    if (bankdata.length > 0) {
      return bankdata[0].bank_name;
    }
    else {
      return bankcode;
    }
  }

  useEffect(() => {
    const getAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        setAllAmc(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!")
        }
      }
    }
    getAMC();
  }, [])

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: string) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  }

  const getFunds = async (code: any) => {
    try {
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (error) {

    }
  }

  // Fund Type Drop Down
  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index) => {
      return (
        // <option style={{width: 'auto'}} key={index} value={item.symbol_code}>
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    })
  }



  const searchTransactionsByAMCAndFund = async (statusVar = '', searchVar = '', amcCodeVar = '', fundCodeVar = '') => {
    setTogglePagination(true);
    return new Promise(async (resolve, reject) => {
      if (searchVar !== '') {
        searchVar = 'TXN_' + searchVar;
      }

      setResLoading(true);
      setSearchLoading(true);
      await searchTransactions(
        sessionStorage.getItem('email') || '',
        amcCodeVar,
        fundCodeVar,
        statusVar === '-1' ? '' : statusVar,
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            // let pageArr = paginationArr

            paginationArr = paginationArr.filter(function (item) {
              return item == ''
            })
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);

          resolve('Success');

        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };

  const clearFilters = async (statusVar = '', searchVar = '') => {
    return new Promise(async (resolve, reject) => {
      setResLoading(true);
      setClearLoading(true);
      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        statusVar === '-1' ? '' : statusVar,
        searchValue,
        activePage
        // sessionStorage.getItem('email') || '',	
        // statusVar === '-1' ? '' : statusVar,	
        // searchVar,	
        // '',	
        // activePage	
      )
        .then((response) => {
          if (response.data.status === 200) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (response.data.data.length !== 10) {
              if (
                existingPageIndex === -1 &&
                response.data.data.length === 10
              ) {
                pageArr.push(response.data.page_info.next_page_number);
              }
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
            setResLoading(false);
            setClearLoading(false);
            resolve('Success');
            setTogglePagination(false); // false search pagination.
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
            setClearLoading(false);
            setTogglePagination(false); // false search pagination.
          }
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setClearLoading(false);
      setTogglePagination(true);
    });
  };
  const [setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [data, setData] = useState<any>([]);
  const titleCase = (value: string) => {
    let sentence = value.toLowerCase().split('_');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };

  const [trx_history, setTrxHistory] = useState([]);

  const renderPsxData = () => {
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{items.quantity}</td>
          <td>{items.avg_rate}</td>
          <td>{items.gross_amount}</td>
          <td>{items.deductions}</td>
          <td>{items.additions}</td>
          <td>{items.net_amount}</td>
        </tr>
      );
    });
  };
  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const [securites, setSecurites] = useState<any>([]);
  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setFlagForSecutities(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForSecutities(false);
                  // clearTaxobject();	
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">   View Security</h6>
            </div>
            <div className="modal-body">
              <div className="">
                {securites.length > 0 && (
                  <div className="input-size-pos">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Market</th>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Avg Rate</th>
                          <th>Gross Amount</th>
                          <th>Deduction</th>
                          <th>Addition</th>
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderPsxData()}
                      </tbody>
                    </table>
                  </div>)}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  //render table data for tax	
  //view tax table	
  const [viewTaxModal, setTaxModal] = useState(false);
  const [totalTax, setTotalTax] = useState<any>([]);
  //render Tax table data	
  const renderTaxData = () => {
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{items.amount}</td>
          <td>{items.calculatedAmount}</td>
        </tr>
      );
    });
  };
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setTaxModal(false) }}
          >

            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTaxModal(false);
                  // clearTaxobject();	
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> View Txn Charges  </h6>
            </div>
            <div className="modal-body">
              <div className="">
                {totalTax.length > 0 && (
                  <div className="input-size-pos">
                    <table className="table my-table">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderTaxData()}
                      </tbody>
                    </table>
                  </div>)}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  const renderData = () => {
    return data.map((value: any, index: number) => {
      return (
        <>
          {value[0] !== 'txn_history' &&
            value[0] !== 'file' &&
            value[1] !== '' &&
            value[1] !== 0 ? (
            <Col md="6" key={index}>
              <div className="input-holder left">
                <p className="label">{titleCase(value[0])}</p>

                {value[0] === 'securities' ? (
                  <div
                    className="input-1 flex justify-content-center"
                    style={{
                      background: '#0e4646',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFlagForSecutities(true);
                      setSecurites(JSON.parse(value[1]));
                    }}
                  >
                    View Securities
                  </div>
                ) : value[0] === 'txn_charges' ? (
                  <div
                    className="input-1 flex justify-content-center"
                    style={{
                      background: '#0e4646',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setTaxModal(true);
                      setTotalTax(JSON.parse(value[1]));
                    }}
                  >
                    View Txn Charges
                  </div>
                ) : (
                  // <AiFillEye className="m-3"/>	
                  <div className="input-1">
                    <p
                      style={{
                        fontSize: '15px',
                      }}
                    >
                      {value[1] &&
                        value[1].toString().includes('Pakistan Standard')
                        ? moment(value[1].toString()).format(
                          'DD-MM-YYYY h:mm a'
                        )
                        : value[1] && value[1].toString()}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          ) : (
            ''
          )}
        </>
      );
    });
  };

  // render Model for adding Fund	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);


  const [note, setNote] = useState('');
  const [viewChecklist, setViewCheckList] = useState(false);
  const [trx_checklist, setTrxCheckList] = useState([]);
  const [templateDD, setTemplateDD] = useState();
  const viewCheckListPopup = () => {
    switch (viewChecklist) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="xl"
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewCheckList(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approval Check List </h6>
            </div>
            <div className="modal-body">
              <div className="">
                <div className={'checlist-scroll'}>
                  {trx_checklist.map((val: any, ind: any) => {
                    return (
                      <Row key={ind} className="pt-3">

                        <Col md="5">
                          <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>
                        </Col>
                        <Col md="7">
                          <Row>
                            <Col md="3">

                              <FormGroup check>
                                <Label check>
                                  <Input
                                    checked={val.value == 'compliant'}
                                    type="checkbox"
                                    value="compliant"
                                    readOnly />
                                  <span className="form-check-sign" />
                                  Compliant
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox"
                                    checked={val.value == 'na'}
                                    value="na"
                                    readOnly />
                                  <span className="form-check-sign" />
                                  N /A
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="7">
                              <FormGroup>
                                <input
                                  type="text"
                                  placeholder="Comments"
                                  value={val.comment}
                                  className="form-control"
                                  readOnly
                                />
                              </FormGroup>

                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                {note !== '' && (
                  <Row>
                    <Col md="12">

                      <div className="form-group">
                        <label>Reason of Reject</label>
                        <textarea
                          placeholder="Reason"
                          className={'form-control w-100'}
                          value={note}
                          readOnly
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className=" ">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setViewCheckList(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [reverseTransactionModal, setReverseTransactionModal] = useState(false);
  const [checkBoxLoading, setCheckBoxLoading] = useState(false);
  const [reverseTransaction, setReverseTransaction] = useState<any>({});
  const [description, setDescription] = useState('');

  const reverseTransactionData = async () => {
    setCheckBoxLoading(true);
    reverseTransaction.account_no = reverseTransaction.account_number;
    reverseTransaction.executed_date = moment(reverseTransaction.executed_date).format('YYYY-MM-DD');
    if (reverseTransaction.txnr_type === 'saleofunit') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'redemptionofunits') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'unitconversion') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'conversionin') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'conversionout') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'fundtransfer') {
      let fromBank = reverseTransaction.bank;
      let fromBranch = reverseTransaction.branch;
      let fromAccountNo = reverseTransaction.account_no;
      let fromAccountTitle = reverseTransaction.account_title;
      let toBank = reverseTransaction.counter_bank;
      let toBranch = reverseTransaction.counter_branch;
      let toAccountNo = reverseTransaction.counter_account_number;
      let toAccountTitle = reverseTransaction.counter_account_title;
      reverseTransaction.bank = toBank;
      reverseTransaction.branch = toBranch;
      reverseTransaction.account_no = toAccountNo;
      reverseTransaction.account_title = toAccountTitle;
      reverseTransaction.counter_bank = fromBank;
      reverseTransaction.counter_branch = fromBranch;
      reverseTransaction.counter_account_number = fromAccountNo;
      reverseTransaction.counter_account_title = fromAccountTitle;
      reverseTransaction.low_balance_agree = 'Yes';
    }
    if (reverseTransaction.txnr_type === 'outflow') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'inflow') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    if (reverseTransaction.txnr_type === 'fund-dividend-payout') {
      if (reverseTransaction.payment_type === 'Payment') {
        reverseTransaction.payment_type = 'Receipt';
      } else if (reverseTransaction.payment_type === 'Receipt') {
        reverseTransaction.payment_type = 'Payment';
      }
    }
    try {
      const response = await addMegaTransaction(reverseTransaction); // for add new transaction
      if (response.data.status === 400) {
        // setViewBankBalanceModal(true);
        toast.warning(response.data.message);
      } else if (response.data.status === 417) {
        toast.warning(response.data.message);
        setReverseTransactionModal(false);
        setCheckBoxLoading(false);
      } else if (response.data.status === 401) {
        toast.error(response.data.message);
        setReverseTransactionModal(false);
        setCheckBoxLoading(false);
      } else if (response.data.status === 402) {
        toast.error(response.data.message);
        setReverseTransactionModal(false);
        setCheckBoxLoading(false);
      } else {
        // setUnderStand(false);
        // setViewBankBalanceModal(false);
        sessionStorage.setItem('last_id', response.data.txn_id);
        toast.success(`${response.data.message} - ${response.data.txn_id.replace('TXN_', '')}`);
        setReverseTransactionModal(false);
        setCheckBoxLoading(false);
        history.replace(`/admin/transaction-types`);
      }
    } catch (error: any) {
      setReverseTransactionModal(false);
      setCheckBoxLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  }


  const viewReverseTransaction = () => {
    switch (reverseTransactionModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="xl"
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setReverseTransactionModal(false);
                  setCheckBoxLoading(false);
                  setDescription('');
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Reverse ({reverseTransaction.txnr_type
                ? CapTxnType(reverseTransaction.txnr_type)
                : 'N/A'}) Transaction </h6>
            </div>
            <div className="modal-body">
              <div className="">
                <Row>
                  <Col>
                    <div className="w-100 flex mr-2">
                      <div>Description</div>
                      <input
                        type="text"
                        value={description}
                        className={
                          'form-control w-100'
                        }
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox"
                          checked={checkBoxLoading}
                          onChange={() => {
                            setCheckBoxLoading(!checkBoxLoading)
                          }} />
                        <span className="form-check-sign" />
                        <span style={{ color: '#EED202', fontSize: '16px' }}>Warning!!! are you want to reverse this transaction </span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='3'>
                    <div className=" ">
                      <button
                        className="btn btn-primary d-inline"
                        onClick={async () => {
                          reverseTransactionData();
                        }
                        }
                        disabled={!checkBoxLoading}
                      >
                        Reverse Transaction
                      </button>
                    </div>
                  </Col>
                  <Col md='3'>
                    <div className=" ">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setReverseTransactionModal(false);
                          setCheckBoxLoading(false);
                          setDescription('');
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  let letterState = [];


  const [displayLetterCheck, setDisplayLetterCheck] = useState(false);
  const renderModalForLetterPopup = () => {
    switch (displayLetterCheck) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setDisplayLetterCheck(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setDisplayLetterCheck(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">  Select Template</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select</label>
                    <ReactTooltip textColor='white' backgroundColor='Black' effect="float" />
                    <select className="form-control"
                      onChange={(e) => {
                        // eslint-disable-next-line  
                        e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                        setChooseTemplate(e.target.value);
                        setLetterTempates(true);
                        setResetPaymentType(true);
                      }}
                    >
                      <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Letter Template'}</option>
                      {transType !== 'debtmarketinvestment' && transMode.trim().toLowerCase() === 'RTGS'.toLowerCase() && paymentType.trim().toLowerCase() === 'Receipt'.toLowerCase() && (<option value="TransferInRTGS">RTGS Inflow </option>)}
                      {transType !== 'debtmarketinvestment' && transMode.trim().toLowerCase() === 'RTGS'.toLowerCase() && (paymentType.trim().toLowerCase() === 'Payment or Receipt'.toLowerCase()) && (<option value="ReceivedRTGS">RTGS Inflow Fund Transfer</option>)}

                      {/* {transType !== 'debtmarketinvestment' && transMode === 'RTGS' &&  (paymentType === 'Receipt' || paymentType === 'Payment or Receipt' ) &&   (<option value="ReceivedRTGS">RTGS Inflow</option>) } */}
                      {transType !== 'debtmarketinvestment' && transMode.trim().toLowerCase() === 'RTGS'.toLowerCase() && (paymentType.trim().toLowerCase() === 'Payment'.toLowerCase() || paymentType.trim().toLowerCase() === 'Payment or Receipt'.toLowerCase()) && (<option value="TransferRTGS"> RTGS Outflow</option>)}
                      {/* Pay order / Demand draft Letter */}
                      {transType !== 'debtmarketinvestment' && transMode.trim().toLowerCase() === 'PO/DD'.toLowerCase() && (paymentType.trim().toLowerCase() === 'Payment'.toLowerCase()) && (<option value="demandDraft"> Demand Draft</option>)}

                      {transType !== 'debtmarketinvestment' && transMode.trim().toLowerCase() === 'Online'.toLowerCase() && (paymentType.trim().toLowerCase() === 'Payment'.toLowerCase() || paymentType.trim().toLowerCase() === 'Payment or Receipt'.toLowerCase()) && (<option value="OnlineTransferFund"> Online Transfer of Fund</option>)}

                      {transType === 'debtmarketinvestment' && mmType.trim().toLowerCase() === 'Buy'.toLowerCase() && (<option value="MMPurchase"> Money Market Purchase</option>)}
                      {transType === 'debtmarketinvestment' && mmType.trim().toLowerCase() === 'Sell'.toLowerCase() && (<option value="MMSale"> Money Market Sale</option>)}
                      {transType === 'debtmarketinvestment' && mmType.trim().toLowerCase() === 'Maturity'.toLowerCase() && (<option value="MMMaturitySecurity"> Money Market Maturity of Security</option>)}
                      {transType === 'debtmarketinvestment' && mmType.trim().toLowerCase() === 'Coupon'.toLowerCase() && (<option value="MMMaturityCoupon"> Money Market Maturity of Coupon</option>)}

                    </select>
                    {/* <input type="text" name="" value={transType }/> 
                  <input type="text" name="" value={mmType }/> 
                  <input type="text" name="" value={transMode}/>
                  <input type="text" name="" value={paymentType}/>   */}
                  </div>
                </div>

                <div className="col-md-12 ">
                  {showTemplates === true && chooseTemplate === 'TransferInRTGS' && (
                    <div className="a4-page-letter saleLetter_height" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transferInFundRTGS) }} />

                    </div>
                  )}

                  {showTemplates === true && chooseTemplate === 'ReceivedRTGS' && (
                    <div className="a4-page-letter saleLetter_height" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(receivedFundRTGS) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'TransferRTGS' && (
                    <div className="a4-page-letter-largeData" id={'letterview'} ref={canvasRefs}>

                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transferFundRTGS) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'demandDraft' && (
                    <div className="a4-page-letter-largeData" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(demand_draft) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'OnlineTransferFund' && (
                    <div className="a4-page-letter saleLetter_height" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineFT) }} />
                    </div>
                  )}
                  {/* <PDFExport paperSize="A4" pageTemplate={LetterFooter} margin={'1.5cm'} scale={0.8} repeatHeaders={true} fileName={`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`} ref={pdfExportComponent}>
                <div className="a4-page-letter saleLetter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineFT) }} />
               
              </div>
              
                </PDFExport> */}
                  {showTemplates === true && chooseTemplate === 'MMSale' && (
                    <div className="a4-page-letter-largeData saleLetter" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmSale) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'MMPurchase' && (
                    <div className="a4-page-letter-largeData saleLetter" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmPurchase) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'MMMaturitySecurity' && (
                    <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturitySecurity) }} />
                    </div>
                  )}
                  {showTemplates === true && chooseTemplate === 'MMMaturityCoupon' && (
                    <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                      <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturityCoupon) }} />
                    </div>
                  )}
                </div>
                {/* <Row>
                <Col md='12'>
                  <div className="form-group  ">
                    <label>To</label>
                    <input
                      type="text"
                      placeholder="Recipients"
                      className={"form-control"}
                      value={toEmail.toString()}
                      onChange={(e) => {
                        let temp = e.target.value.split(",");
                        setToEmail([...temp]);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group  ">
                    <label>CC</label>
                    <input
                      type="text"
                      placeholder="CC"
                      className={"form-control"}
                      value={ccEmail.toString()}
                      onChange={(e) => {
                        let temp = e.target.value.split(",");
                        setCCEmail([...temp]);
                      }}
                    />
                  </div>
                </Col>
              </Row> */}
                <div className="col-md-12 mt-3">
                  <div className="d-flex">
                    <button
                      className="btn btn-primary mt-3 mr-3"
                      onClick={() => {
                        printContentLetterFormat();

                      }} disabled={Boolean(disableAddBtn)}
                    >
                      {disableAddBtn ? <>Loading...</> : <>Print</>}
                    </button>
                    <button
                      className="btn btn-primary mt-3 ml-3"
                      disabled={true}
                      onClick={() => {
                        const accountData = async () => {
                          try {
                            const response = await searchBankAccAPI(email, bank_account);
                            if (response.data.status === 200) {
                              setTxnData(txnData);
                              setToEmail(response?.data?.data[0]?.operation_head_email === '' ? response?.data?.data[0]?.bm_email : response.data.data[0]?.operation_head_email + ',' + response.data.data[0]?.bm_email)
                              // setSendEmailModalView(true);
                              SendMail();
                              // setDisplayLetterCheck(false)
                            }
                          } catch (error: any) {
                            if (error.response !== undefined) {
                              toast.error(error.response.data.message);
                            } else {
                              toast.error("Something went wrong!");
                            }
                          }

                        }
                        accountData();
                      }}
                    >
                      Email
                    </button>
                  </div>
                  {/* <button type="button" data-tip={'Send Mail'} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                        
                    onClick={() => {
                      setTxnData(JSON.stringify(transaction));
                      setFund_account(
                        transaction.fund_account
                      );
                      setMailModelBoolean(true);
                    }}
                  >
                    <i className="fa fa-envelope fa-4x"></i>
                  </button>
                  <button className="btn btn-primary btn-block" onClick={() => {
                    printContentLetterFormat();

                  }} disabled={Boolean(disableAddBtn)}>
                    {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="login-txt"> Loading...</span></> : <span>Print</span>}
                  </button> */}
                </div>

              </div>


            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const SendMail = async () => {
    if (tempValue !== '') {
      setdisableSendBtn(true);
      try {
        const response = await SendMailTo(email, chooseTemplate, fund_account, txnData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setTxnData('');
          setFund_account('');
          setTempValue('');
          setdisableSendBtn(false);
          setDisplayLetterCheck(true)
          setMailModelBoolean(false);
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.info('First Select Template');
    }
    setdisableSendBtn(false);
  };

  // const renderMailPopup = () => {
  //   switch (mailModelBoolean) {
  //     case true:
  //       return (
  //         <Modal
  //           className=""
  //           dialogClassName="modal60w"
  //           show={true}
  //           enforceFocus={false}
  //         >

  //           <div className="modal-header ">
  //             <button
  //               aria-hidden={true}
  //               className="close"
  //               data-dismiss="modal"
  //               type="button"
  //               onClick={() => {
  //                 setMailModelBoolean(false);
  //               }}
  //             >
  //               <i className="tim-icons icon-simple-remove" />
  //             </button>
  //             <h6 className="title title-up"> Select Template </h6>
  //           </div>

  //           <div className="modal-body">
  //             <Row>
  //               <div className="col-md-12">
  //                 <div className="form-group">
  //                   <select
  //                     className="form-control"
  //                     onChange={(e) => {
  //                       setTempValue(e.target.value);
  //                     }}
  //                   >
  //                     <option value="" defaultChecked hidden>
  //                       {mailTempLoading
  //                         ? 'No Template Found'
  //                         : 'Select Template'}
  //                     </option>
  //                     {renderMailTemplateDropdown()}
  //                   </select>
  //                 </div>
  //               </div>
  //               <div className="col-md-12">
  //                 <button
  //                   className="btn btn-primary btn-block"
  //                   onClick={() => {
  //                     SendMail();
  //                   }}
  //                   disabled={Boolean(disableSendBtn)}
  //                 >
  //                   {disableSendBtn ? (
  //                     <>
  //                       <span
  //                         className="spinner-border login-txt spinner-border-sm"
  //                         role="status"
  //                         aria-hidden="true"
  //                       ></span>
  //                       <span className="login-txt"> Loading...</span>
  //                     </>
  //                   ) : (
  //                     <span>Send</span>
  //                   )}
  //                 </button>
  //               </div>
  //             </Row>
  //           </div>
  //         </Modal>
  //       );
  //     default:
  //       return '';
  //   }
  // };



  const renderModalSendEmail = () => {
    switch (sendEmailModalView) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="sm"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setCCEmail([])
                  setSendEmailModalView(false);
                  setDisplayLetterCheck(true)
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Email Detail</h6>
              </div>
            </div>

            <div className="modal-body">
              <Row>
                <Col md='12'>
                  <div className="form-group">
                    <label>Select Template</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setTempValue(e.target.value);
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        {mailTempLoading
                          ? 'No Template Found'
                          : 'Select Template'}
                      </option>
                      {renderMailTemplateDropdown()}
                    </select>
                  </div>
                </Col>
              </Row>
              

              <Row>
                <Col>
                  <button
                    className="btn btn-primary mt-2"
                    style={{ width: "100%" }}
                    disabled={emailLoading}
                    onClick={() => {

                      SendMail()
                    }}
                  >
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>{emailLoading ? "Sending..." : "Send Email"}</span>
                  </button>
                </Col>
              </Row>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };



  const canvasRefs = useRef(null);



  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [disableSendBtn, setdisableSendBtn] = useState(false);
  const [tempValue, setTempValue] = useState('');
  const [fund_account, setFund_account] = useState('');
  const [bank_account, setBank_account] = useState('');
  const [txnData, setTxnData] = useState('');
  const [txnObject, setTxnObject] = useState<any>({});

  let transferInFundRTGS = transferInFundRTGSEmailTemplate(data, transType, amtWords, transNetAmount, transGrossAmount, grossAmtWords);

  let receivedFundRTGS = ReceiveOfFundRTGSEmailTemplate(data, transType, amtWords, transNetAmount, transGrossAmount, grossAmtWords);
  let onlineFT = OnlineTransferOfFundLetter(data, amtWords, transMode, transType, transNetAmount, transGrossAmount);

  let transferFundRTGS = TransferOfFundRTGSEmailTemplate(data, amtWords, transNetAmount, transGrossAmount);
  //  demand draft letter
  let demand_draft = demandDraftLetterTemplate(data, amtWords, transType, transNetAmount, transGrossAmount, grossAmtWords);

  let mmPurchase = MoneyMarketPurchaseLetter(data, securityData, amtWords, transNetAmount, transGrossAmount);
  let equity = equityInvestmentLetter(data, securityData, transType);
  let mmMaturitySecurity = mmMaturityOfSecurityLetter(data, securityData, transType, amtWords, transNetAmount, transGrossAmount);
  let mmMaturityCoupon = mmMaturityOfCouponLetter(data, securityData, amtWords, transNetAmount, transGrossAmount);
  let mmSale = MoneyMarketSaleLetter(data, securityData, amtWords, transNetAmount, transGrossAmount);

  const [showTemplates, setLetterTempates] = useState(false);
  const [resetPaymentType, setResetPaymentType] = useState(false);

  const printContentLetterFormat = () => {
    try {

      var doc = new jsPDF('p', 'pt', [595.275, 841.89], true);
      let pageHeight = doc.internal.pageSize.getHeight();

      doc.html(document.getElementById('letterview') as HTMLElement, {
        callback: function (doc) {
          let totalPages = doc.internal.pages.length - 1;
          for (let i = 1; i <= totalPages; i++) {
            doc.setFontSize(9);
            // pdf.setTextColor(150)
            doc.text(`______________________________________________________________________________________________________`, doc.internal.pageSize.getWidth() - 550, doc.internal.pageSize.getHeight() - 55);
            doc.text(`Operations Office: Perdesi House | Old Queens Road | Karachi-74200 | Pakistan`, doc.internal.pageSize.getWidth() - 460, doc.internal.pageSize.getHeight() - 40);
            doc.text(`+92 21 32419770  |  www.digitalcustodian.co  |  info@digitalcustodian.co`, doc.internal.pageSize.getWidth() - 440, doc.internal.pageSize.getHeight() - 30);

          }
          doc.save(`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`);
        },
        html2canvas: {
          useCORS: true,
          scale: 0.94
          // width: 500
        },
        margin: [20, 0, 0, 0],
        x: 0,
        y: 0,
      });

    } catch (error: any) {
    }

  }



  const getLederByTxnId = async () => {
    try {
      setledgerLoading(true);
      const response = await getLedger(email, txnObject.txn_id);
      console.log("🚀 ~ file: pending-transactions.tsx:1562 ~ getLederByTxnId ~ response:", response)
      ledgerData.length = 0;
      setLedgerData(ledgerData);
      let array = Object.entries(response.data.data);
      let data: any = []
      array.forEach(([key, value]) => {
        let record: [] = []
        data = value;
        record['Record'] = data.Record
        record['Record']['ledger_id'] = data.Key
        ledgerData.push(record)
      })
      setCountLedgerData(ledgerData.length)
      setledgerLoading(false);
    } catch (err) {
      setledgerLoading(false);
    }
  };

  const [viewLedgers, SetViewLedgers] = useState(false);
  const [ledgerLoading, setledgerLoading] = useState(false);
  const [onceUpdateRel, setOnceUpdateRel] = useState(false);
  const [countledgerData, setCountLedgerData] = useState(0);
  const renderViewLedgersPopup = () => {
    switch (viewLedgers) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            size="xl"
            show={true}
            enforceFocus={false}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  SetViewLedgers(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Ledgers </h6>
            </div>

            <div className="modal-body">
              <Row>
                <Col md='12'>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Ledgers</h4>
                    </div>
                    <div className="card-body">
                      {!ledgerLoading ? (

                        countledgerData > 0 && (
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Ledger ID</th>
                                  <th>Description</th>
                                  <th>Fund Account</th>
                                  <th>Debit Amount</th>
                                  <th>Credit Amount</th>
                                  <th>Counter Account</th>
                                  <th>Realized</th>
                                  {(role_name === 'ADMIN' || role_name === 'CONCERNED_OFFICER') && (
                                    <>
                                      <th>Action</th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {ledgerData.map((item: any, index: string) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.Record.ledger_id.replace('ID_', '')}</td>
                                      <td>{item.Record.description.split('-')[0]}</td>
                                      <td>{item.Record.account_number}
                                        <br /> <small><span className="text-muted"> {item.Record.account_title} </span></small>
                                      </td>
                                      <td>{numberWithCommas(parseFloat(isNumber(item.Record.debit_amount)))}</td>
                                      <td>{numberWithCommas(parseFloat(isNumber(item.Record.credit_amount)))}</td>
                                      <td>{item.Record.counter_account_number}
                                        <br /> <small><span className="text-muted"> {item.Record.counter_account_title} </span></small>
                                      </td>
                                      <td>
                                        <FormGroup check>
                                          <Label check>
                                            <input type="checkbox" checked={item.Record.realized === "true" ? true : false} />
                                            <span className="form-check-sign" />
                                          </Label>
                                        </FormGroup>
                                      </td>
                                      {(role_name === 'ADMIN' || role_name === 'CONCERNED_OFFICER') && (
                                        <>
                                          <td>
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            {txnObject?.txn_status === 'COMPLETED' &&
                                              parseFloat(item.Record.credit_amount) > 0 &&
                                              txnObject?.instrument_type === 'Cheque' && (
                                                <>
                                                  <RiPrinterLine
                                                    data-tip={'Print Cheque'}
                                                    className={'mr-1'}
                                                    onClick={() => {
                                                      getTemplates(item.Record.bank_code);
                                                      setData(item);
                                                      setTitle(txnObject?.counter_account_title);
                                                      setAmount(item.Record.credit_amount);
                                                      sessionStorage.setItem('cheque', JSON.stringify(item.Record));
                                                      setDisplayChequeModal(true);
                                                    }}

                                                  />
                                                  <span className="text-primary">{'|'}</span>
                                                </>
                                              )}

                                            {(item.Record.instrument_no == '' && role_name === 'CONCERNED_OFFICER') || (role_name === 'ADMIN') ? (
                                              <>
                                                <ReactTooltip
                                                  textColor="white"
                                                  backgroundColor="black"
                                                  effect="float"
                                                />
                                                <FaRegMoneyBillAlt
                                                  data-tip={'Add Instrument Detail'}
                                                  className={'ml-1 mr-1'}
                                                  onClick={() => {
                                                    setledgerId(item.Record.ledger_id.replace('ID_', ''));
                                                    setInstrumentNo(item.Record.instrument_no);
                                                    setAddInstrumentModal(true);
                                                    setRealized(item.Record.realized);

                                                  }}
                                                />
                                                <span className="text-primary"> {'|'} </span>
                                              </>
                                            ) : (
                                              ''
                                            )}


                                            {(txnObject.txn_status === 'COMPLETED' && ((role_name === 'CONCERNED_OFFICER' && item.Record.realized === "false") || (role_name === 'ADMIN'))) && (
                                              <>
                                                <ReactTooltip
                                                  textColor="white"
                                                  backgroundColor="black"
                                                  effect="float"
                                                />
                                                <RiCheckboxMultipleLine
                                                  data-tip={'Realized In Bank'}
                                                  className={'ml-1'}
                                                  onClick={() => {
                                                    setledgerId(item.Record.ledger_id.replace('ID_', ''));
                                                    setRealized(item.Record.realized);
                                                    if (item.Record.realized == 'true') {
                                                      setRealize(true);
                                                      setShowDate(true);
                                                      setOnceUpdateRel(true);
                                                    } else {
                                                      setRealize(false);
                                                      setShowDate(false)
                                                    }

                                                    setAddMarkRealized(true);
                                                  }}
                                                />
                                              </>
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>

                          </div>
                        )

                      ) :
                        (
                          <div className="d-flex justify-content-center my-5">
                            <i className="fa fa-spinner fa-spin fa-3x" ></i>
                          </div>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [ledgerId, setledgerId] = useState('');
  let [realizedDate, setRealizedDate] = useState<any>(moment(date1).format('YYYY-MM-DD'))
  let [realize, setRealize] = useState(false);
  let [realized, setRealized] = useState('');
  const [addMarkRealized, setAddMarkRealized] = useState(false);
  const [disableIntrBtn, setDisableIntrBtn] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [showDate, setShowDate] = useState(false);

  const RealizedInBank = async () => {
    try {
      setDisableIntrBtn(true);
      const response = await realizedMarkLedger(email, ledgerId, realized, realizedDate, '', '')
      console.log('response===', response)
      if (response.data.status == 200) {
        toast.success(response.data.message);
        setShowDate(false);
        setRealizedDate(moment(date1).format('YYYY-MM-DD'));
        getLederByTxnId()
        setDisableIntrBtn(false);
        setOnceUpdateRel(false);
      } else {
        toast.error(response.data.message)
        setDisableIntrBtn(false);
      }
      setledgerId('');
      // setRealizedDate('');
      setRealize(false);
      setRealized('');
      setAddMarkRealized(false);
    } catch (error) {
      setDisableIntrBtn(false);
      // setAddMarkRealized(false);
    }
  }
  const renderModalForMarkRealized = () => {
    switch (addMarkRealized) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setAddMarkRealized(false)
              setOnceUpdateRel(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setAddMarkRealized(false);
                  setOnceUpdateRel(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Realized In Bank</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div>Realized In Bank</div>
                  <FormGroup check>
                    <Label check>
                      <input type="checkbox" checked={realized === "true" ? true : false} onChange={(e) => {
                        if (realize === true) {
                          setRealize(false);
                          setRealized('false')
                          setErrorShow(false);
                          setShowDate(false);
                        } else {
                          setRealize(true);
                          setRealized('true');
                          setShowDate(true);
                        }
                      }} />
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </div>
                {showDate == true && (
                  <div className="col-md-12 mt-3">
                    <div>Realized Date</div>
                    <input type="date" disabled value={realizedDate} className={"form-control "} />
                  </div>
                )}

                <div className="col-md-12 mt-3">
                  {onceUpdateRel === false ? (
                    <button className="btn btn-primary btn-block" onClick={() => {
                      if (realize == true && realizedDate == '') {
                        setErrorShow(true);
                      } else {
                        setErrorShow(false);
                        RealizedInBank();
                      }

                    }} disabled={Boolean(disableIntrBtn)}>
                      {disableIntrBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="login-txt"> Loading...</span></> : <span>Mark</span>}
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" disabled={Boolean(onceUpdateRel)}>
                      <span className="login-txt"> Already Realized</span>
                    </button>
                  )}


                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [addInstrumentModal, setAddInstrumentModal] = useState(false);
  const [instrumentNo, setInstrumentNo] = useState('');
  const [instrumentDate, setInstrumentDate] = useState('');
  const addInstrumentCall = async () => {
    let realized_date: any = realized === '' ? '' : realizedDate;
    setDisableIntrBtn(true);
    try {
      const response = await realizedMarkLedger(email, ledgerId, realized, realized_date, instrumentNo, instrumentDate);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        getLederByTxnId()
        setDisableIntrBtn(false);
        setAddInstrumentModal(false);
      } else {
        toast.error(response.data.message);
        setDisableIntrBtn(false);
        setAddInstrumentModal(false);
      }
      setledgerId('');
      setInstrumentNo('');
      setInstrumentDate('');
      setDisableIntrBtn(false);
    } catch (error) {
      setDisableIntrBtn(false);
    }
  };
  const renderModalForInstrumentPopup = () => {
    switch (addInstrumentModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setAddInstrumentModal(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setAddInstrumentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">  Add Instrument Detail</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div>Instrument No</div>
                    <input type="text" value={instrumentNo != '' ? instrumentNo : txnObject['instrument_no']} className="form-control" onChange={(e) => {
                      setInstrumentNo(e.target.value);
                    }} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div>Instrument Date</div>
                    <input type="date" className="form-control" onChange={(e) => {
                      let insDate: any = moment(e.target.value).format('YYYY-MM-DD');
                      setInstrumentDate(insDate)
                    }} />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <button className="btn btn-primary btn-block" onClick={() => {
                    addInstrumentCall();
                  }} disabled={Boolean(disableIntrBtn)}>
                    {disableIntrBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="login-txt"> Loading...</span></> : <span>Add</span>}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [allTemplates, setAllTemplates] = useState<any>([]);
  // const [data, setData] = useState<any>([]);
  const [displayChequeModal, setDisplayChequeModal] = useState(false);
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  // render Model for adding Fund	
  // const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
  // const [tempLoading, setTempLoading] = useState(false);

  const getTemplates = async (bankName) => {
    try {
      const response = await getAllBankTemplates(email, bankName);
      setAllTemplates(response.data.data);
      if (response.data.data.length < 1) {
        setTempLoading(true);
      } else {
        setTempLoading(false);
      }
    } catch (error) { }
  };

  const renderTemplateDropdown = () => {
    return allTemplates.map((item: any, index: string) => {
      return (
        <option key={index} value={item.template_detail}>
          {item.template_name}
        </option>
      );
    });
  };

  const printChequeValidation = () => {
    let check = true;
    if (selectedTemplate === '') {
      check = false;
    } else if (title === '') {
      check = false;
    } else if (amount === '') {
      check = false;
    }
    return check;
  };

  const printContent = async () => {
    let unitholder_cnic = ''
    const isValid = printChequeValidation();
    //&& fields['mode_of_payment']!='RTGS' && fields['mode_of_payment']!='PO/DD'
    if (txnObject?.txnr_type === "redemptionofunits" || txnObject?.txnr_type === "funddividendpayout") {
      let unitholder_data = await getUnitHoldersByAmcAndFolioNo(email, txnObject['amc_code'], txnObject['folio_no'])
      if (unitholder_data.data.status == 200) {
        unitholder_cnic = unitholder_data.data.data[0].cnic
      }

    }
    setTimeout(() => {
      if (isValid) {
        //for cheque	
        let bankName;
        // if(fields['mode_of_payment']==='PO/DD' || fields['mode_of_payment']==='RTGS'){
        //   bankName = fields['bank'];
        // }else{
        //   bankName = title;
        // }

        bankName = title

        let tempData = [...selectedTemplate];
        let txn_name = CapTxnType(txnObject?.txnr_type);
        //  let txn_name = CapTxnType(data.description.split('-')[0]);	
        // let txn_name = data.Record.description.split('-')[0];

        tempData[0].label = moment(data.instrument_date).format('DD-MM-YYYY');
        tempData[1].label = unitholder_cnic.trim() != "" ? bankName + "–" + unitholder_cnic : bankName;
        tempData[2].label = amount;
        tempData[3].label = inWords(amount).replace("and Paisa Zero ", "");
        tempData[5].label = `${txn_name} #${data.Record.txn_id}`;
        sessionStorage.setItem('template', JSON.stringify(tempData));
        sessionStorage.setItem('modeofpaymentCheque', JSON.stringify({ "mode_of_payment": txnObject?.mode_of_payment, "bank_name": txnObject?.bank, "cnic": unitholder_cnic }))
        history.replace('/admin/print-screen/pending');
      }
    }, 5000);

  };

  const renderModaForPrintChequePopup = () => {
    switch (displayChequeModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => { setDisplayChequeModal(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setDisplayChequeModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">  Select Template</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Template</label>
                    <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                    <select className="form-control"
                      onChange={(e) => {
                        let x = JSON.parse(e.target.value);
                        setSelectedTemplate(x);
                      }}>
                      <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Template'}</option>
                      {renderTemplateDropdown()}
                    </select>
                  </div>

                </div>
                <div className="col-md-12">
                  <button className="btn btn-primary btn-block mt-3" onClick={() => {
                    printContent();
                  }} disabled={Boolean(disableAddBtn)}>
                    {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="login-txt"> Loading...</span></> : <span>Print</span>}
                  </button>

                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  let txnr_id: any;
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {!view ? (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transactions</h4>
                  </div>
                  <div className="card-body">
                    <div className="">
                      {/* <div className="d-flex inline-box">	 */}
                      <Row>
                        <Col md="2">
                          <div className="form-group">
                            <ReactTooltip
                              textColor="white"
                              backgroundColor="black"
                              effect="float"
                            />
                            <label>Transaction ID</label>
                            <input
                              type="search"
                              id="myInput"
                              placeholder="Transaction Id"
                              data-tip="Search Transaction"
                              className="form-control"
                              value={searchValue}
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                let key_board_keycode = e.which || e.keyCode;
                                if (key_board_keycode == 13) {
                                  e.preventDefault();
                                  if ((searchValue || status !== "-1" || Amc || fund) !== '') {
                                    searchTransactionsByAMCAndFund(status, searchValue, Amc, fund).then(() => {
                                      // setPaginationArr(['']);
                                      setActivePage('');

                                    });
                                  }
                                }
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="form-group ml-2 mr-2">
                            <label >Select AMC</label>
                            <select
                              className={"form-control"}
                              value={Amc}
                              onChange={(e) => {
                                setAmc(e.target.value);
                                getFunds(e.target.value);
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                Select AMC
                              </option>
                              {renderAmcDropdown()}
                            </select>
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="form-group ml-2 mr-2">
                            <label >Select Fund</label>
                            <select
                              className={"form-control"}
                              value={fund}
                              onChange={(e) => {
                                setFund(e.target.value);
                              }}>
                              <option value="" defaultChecked hidden>
                                Select Fund
                              </option>
                              {renderFundsDropDown()}
                            </select>
                          </div>
                        </Col>

                        <Col md="2">
                          {/* <div className=" inline-box">	 */}
                          <div className="form-group ml-2 mr-2">
                            <label >Select Status</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                              value={status}
                            >
                              <option value="-1">Select type</option>
                              <option value="COMPLIANCE">Compliance</option>
                              <option value="INREVIEW">In Review</option>
                              <option value="SIGNATORY-A">Signatory A</option>
                              <option value="SIGNATORY-B">Signatory B</option>
                              <option value="COMPLETED">Completed</option>
                              <option value="CONCERNED-OFFICER">CONCERNED OFFICER</option>
                              <option value="REJECTED-TRUSTEE">Rejected TRUSTEE</option>
                              <option value="REJECTED-AMC">Rejected AMC</option>
                            </select>
                          </div>

                        </Col>


                        <Col md="4">
                          <div className=" inline-box">
                            <button
                              className="btn btn-primary mt-4"
                              disabled={Boolean(searchLoading)}
                              onClick={() => {
                                if ((searchValue || status !== "-1" || Amc || fund) !== '') {
                                  searchTransactionsByAMCAndFund(status, searchValue, Amc, fund).then(() => {
                                    // setPaginationArr(['']);
                                    setActivePage('');

                                  });
                                }
                              }}
                            >
                              {searchLoading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Searching...</span>
                                </>
                              ) : (
                                'Search'
                              )}
                            </button>
                            <button
                              className="btn btn-default mt-4"
                              disabled={Boolean(clearLoading)}
                              onClick={() => {
                                setSearchValue('');
                                clearFilters('', '').then(() => {
                                  setStatus('-1');
                                  setSearchValue('');
                                  setActivePage('');
                                  setAmc('');
                                  setFund('');
                                });
                              }}
                            >
                              {clearLoading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Clear</span>
                                </>
                              ) : (
                                'Clear'
                              )}
                            </button>
                          </div>
                        </Col>
                        {/* </div>	 */}

                      </Row>
                      {/* </div>	 */}
                    </div>


                    <div className="">
                      {resLoading ? (
                        <div className="d-flex justify-content-center my-5">
                          <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                        </div>
                      ) : (
                        transactions.length > 0 ? (
                          <div className='table-responsive'>
                            <table className="table mt-2 ">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Transaction</th>
                                  <th>Fund</th>
                                  <th>Bank</th>
                                  <th className='text-right'>Amount</th>
                                  <th>Type</th>
                                  <th>Instrument</th>
                                  <th>Counter</th>
                                  <th>Created</th>
                                  <th>Status</th>
                                  <th>Act</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactions.map((transaction: any, index: number) => {
                                  return (
                                    // <tr style={customStyle(transaction.txn_status)}  key={index}>
                                    // <tr key={index} className={(transaction.txnr_type
                                    //   ? CapTxnType(transaction.txnr_type)
                                    //   : 'N/A' )==='Equity Auto-Settlement'? "highlight-row-error": '' }>
                                    <tr key={index}>
                                      <td>{isDateValid(moment(transaction.instruction_date).format('DD-MMM'))} <br />
                                        <span className="sub-content">Exe: {moment(transaction.execution_date).format('DD-MMM')}</span>
                                      </td>

                                      <td>{transaction.txnr_type ? (
                                        <span style={{
                                          display: 'inline-block',
                                          width: '100px',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis'
                                        }}>
                                          {CapTxnType(transaction.txnr_type)}
                                        </span>
                                      ) : (
                                        'N/A'
                                      )}<br /> <span className="sub-content">{transaction.txn_id.replace('TXN_', '')}</span></td>

                                      <td>{transaction.fund_code}	<br />
                                        <span className="sub-content">{transaction.amc_code
                                          ? transaction.amc_code
                                          : 'N/A'}	</span>
                                      </td>

                                      <td >{transaction.bank}<br />
                                        <span className="sub-content">{transaction.account_number.length > 6
                                          ? "..." + transaction.account_number.substring(transaction.account_number.length - 6)
                                          : transaction.account_number}</span></td>

                                      <td className="text-right pr-3">{numberWithCommas(parseInt(transaction.net_amount || ''))} <br />
                                        <span className="sub-content">{numberWithCommas(parseInt(transaction.gross_amount || ''))}</span>
                                      </td>
                                      <td>{transaction?.mode_of_payment}<br />
                                        <span className="sub-content">{transaction?.payment_type}</span>
                                      </td>

                                      <td>{transaction.instrument_type}<br />
                                        <span className="sub-content">{`${transaction?.instrument_no === '' ? isDateValid(moment(transaction?.instrument_date).format('DD-MMM-YY')) : transaction?.instrument_no}`}</span>
                                      </td>

                                      <td>{transaction.counter_bank} <br />
                                        <span className="sub-content">{transaction.counter_account_number.length > 6
                                          ? "..." + transaction.counter_account_number.substring(transaction.counter_account_number.length - 6)
                                          : transaction.counter_account_number}</span></td>


                                      <td>{moment(transaction.created_at).format('DD-MM-YYYY')} <br />
                                        {/* <span className="sub-content">{transaction.created_by	
                                ? transaction.created_by	
                                : 'N/A'}</span>	 */}
                                        <span className="sub-content">{transaction.created_by.split('@')[0]}</span>
                                      </td>
                                      <td>{transaction.txn_status}<br />
                                        <span className="sub-content">{transaction.txn_status === 'COMPLETED' ? (
                                          <>{`Realized (${isDateValid(moment(transaction?.txn_history[transaction?.txn_history?.length - 1]?.updated_at).format('DD-MMM-YY'))})`}</>
                                        ) : (
                                          <>Unrealized</>
                                        )}</span>
                                      </td>
                                      <td className=" ">


                                        <div className="multi-icons pointer">
                                          {/* <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6" onClick={() => {
                                            history.push(`/admin/verify-transaction/${txnr_id= transaction.txn_id.replace('TXN_','')}`);

                                          }}  >
                                            <i className="fa fa"></i>
                                          </button> */}
                                          <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6" onClick={() => {
                                            // setData(transaction);
                                            let txnData = transaction;
                                            let bankname = bankName(transaction.bank.toString())
                                            let counterbankname = bankName(transaction.counter_bank.toString())
                                            setData({
                                              ...txnData,
                                              bank: bankname,
                                              counter_bank: counterbankname
                                            });
                                            sessionStorage.setItem("view_txn_from", "transaction-status")

                                            setView(true);
                                            setTrxHistory(transaction.txn_history);
                                          }}  >
                                            <i className="fa fa-eye"> </i>
                                          </button>

                                          {
                                            transaction.txnr_type !== 'transferofunits' && (
                                              <>
                                                {
                                                  ((transaction.mode_of_payment === "Cheque" || transaction.mode_of_payment === "Online" || transaction.mode_of_payment === "RTGS" || transaction.mode_of_payment === "IFT" || transaction.mode_of_payment === "IBFT")
                                                    && (transaction.txn_status === "COMPLETED") && (transaction.amc_code === "MCBAH")) && (
                                                    <>
                                                      <ExcelFile element={
                                                        <button type="button" data-tip={'Download Excel file'} style={{ border: "none" }} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                                                        ><i className="fa fa-download"></i></button>
                                                      } filename="IFT/IBFT Excel Report">
                                                        <ExcelSheet data={[transaction]} name="Sheet1">
                                                          <ExcelColumn label="P&C ID" value="" />
                                                          <ExcelColumn label="REFERENCE ID" value="" />
                                                          <ExcelColumn label="FUND NAME" value="fund_code" />
                                                          <ExcelColumn label="FUND BANK" value="bank" />
                                                          <ExcelColumn label="FUND BANK BRANCH" value="branch" />
                                                          <ExcelColumn label="FUND BRANCH CITY" value="" />
                                                          <ExcelColumn label="FUND A/C TITLE" value="account_title" />
                                                          <ExcelColumn label="REQUESTED PAYMENT DATE" value="created_at" />
                                                          <ExcelColumn label="ACTUAL PAYMENT DATE" value="settlement_date" />
                                                          <ExcelColumn label="TRANSACTION TYPE" value="txnr_type" />
                                                          <ExcelColumn label="BENEFICIARY ID / SHORT_NAME" value="" />
                                                          <ExcelColumn label="BENEFICIARY BANK A/C TITLE" value="counter_account_title" />
                                                          <ExcelColumn label="BENEFICIARY BANK A/C #" value="counter_account_number" />
                                                          <ExcelColumn label="BENEFICIARY BANK NAME" value="counter_bank" />
                                                          <ExcelColumn label="BENEFICIARY BRANCH NAME & CITY" value="counter_branch" />
                                                          <ExcelColumn label="INSTRUMENT TYPE" value="instrument_type" />
                                                          <ExcelColumn label="PRINTING LOCATION" value="" />
                                                          <ExcelColumn label="CLEARING ZONE" value="" />
                                                          <ExcelColumn label="INSTRUMENT NO" value="instrument_no" />
                                                          <ExcelColumn label="AMOUNT PAYABLE" value="" />
                                                          <ExcelColumn label="ACTUAL PAID" value="net_amount" />
                                                          <ExcelColumn label="PENDING NOT PAID" value="" />
                                                          <ExcelColumn label="PAYMENT MODE" value="mode_of_payment" />
                                                          <ExcelColumn label="P&C STATUS" value="" />
                                                          <ExcelColumn label="REMARKS" value="" />
                                                        </ExcelSheet>
                                                      </ExcelFile>
                                                    </>
                                                  )
                                                }

                                                {/* {transaction.txn_status === 'COMPLETED' && transaction.txnr_type!=='equityinvestment' && (   */}
                                                {transaction.txn_status === 'COMPLETED' &&
                                                  (
                                                    // case 1
                                                    ((transaction.mode_of_payment.toUpperCase() !== 'CHEQUE' &&
                                                      transaction.instrument_type.toUpperCase() !== 'CHEQUE' && transaction.mode_of_payment.toUpperCase() !== 'PO/DD') || (transaction.mode_of_payment.toUpperCase() === 'PO/DD') && (transaction.payment_type.toUpperCase() !== 'RECEIPT')) ||
                                                    // case 2
                                                    ((transaction.mode_of_payment.toUpperCase() === 'ONLINE') && (transaction.payment_type.toUpperCase() !== 'RECEIPT')) ||
                                                    // case 3
                                                    (transaction.mode_of_payment.toUpperCase() === 'RTGS')

                                                  ) &&
                                                  (
                                                    <>
                                                      <ReactTooltip
                                                        textColor="white"
                                                        backgroundColor="black"
                                                        effect="float"
                                                      />
                                                      <button type="button" data-tip={'Print Letter'} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                                                        onClick={async () => {
                                                          let txnData = transaction;

                                                          setTxnData(JSON.stringify(transaction));
                                                          console.log("🚀 ~ file: pending-transactions.tsx:2470 ~ onClick={ ~ transaction.fund_account:", transaction.fund_account)
                                                          setFund_account(
                                                            transaction.fund_account
                                                          );
                                                          setBank_account(
                                                            transaction.account_number
                                                          );
                                                          // if (txnData.mode_of_payment = "PO/DD") {
                                                          //   try {
                                                          //     const response = await getLedger(email, txnData.txn_id.replace('TXN_', ''));
                                                          //     setLedgerData(response.data.data.filter(item=>item['Record'].credit_amount>=0));
                                                          //     // })
                                                          //   } catch (error: any) {
                                                          //     if (error.response !== undefined) {
                                                          //       toast.error(error.response.data.message)
                                                          //     }
                                                          //   }
                                                          // }
                                                          let bankname = bankName(transaction.bank.toString())
                                                          let counterbankname = bankName(transaction.counter_bank.toString())
                                                          getLetterTemplateCall(transaction.bank);
                                                          setData({
                                                            ...txnData,
                                                            bank: bankname,
                                                            counter_bank: counterbankname
                                                          });
                                                          if (transaction.securities === "") {
                                                            transaction.securities = "[]";
                                                          }

                                                          let securities = JSON.parse(transaction.securities);
                                                          setSecurityData(securities);

                                                          setMMType(securities.filter(u => u.type === 'Maturity' || u.type === 'Buy' || u.type === 'Sell' || u.type === 'Coupon').map(e => e.type)[0]);
                                                          setTransType(transaction.txnr_type);
                                                          setTransMode(transaction.mode_of_payment);
                                                          setPaymentType(transaction.payment_type);
                                                          // Net Amount
                                                          transaction.net_amount = transaction.net_amount.replace(",", "");
                                                          // let amount= transaction.net_amount.split('.'); 
                                                          setAmtWords(inWords(parseFloat(transaction.net_amount).toFixed(2)));

                                                          setTransNetAmount(isNumber(parseFloat(transaction.net_amount).toFixed(2)));

                                                          // Gross Amount
                                                          transaction.gross_amount = transaction.gross_amount.replace(",", "");
                                                          // let grossAmount= transaction.gross_amount.split('.'); 
                                                          setGrossAmtWords(inWords(parseFloat(transaction.gross_amount).toFixed(2)));
                                                          setTransGrossAmount(isNumber(parseFloat(transaction.gross_amount).toFixed(2)));

                                                          setDisplayLetterCheck(true);
                                                          setLetterTempates(false);
                                                          setResetPaymentType(false)
                                                        }}
                                                      >
                                                        <i className="fa fa-envelope-open-text"></i>
                                                      </button>

                                                      {/* <button type="button" data-tip={'Send Mail'} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                                                        onClick={() => {
                                                          setTxnData(JSON.stringify(transaction));
                                                          setFund_account(
                                                            transaction.fund_account
                                                          );
                                                          setMailModelBoolean(true);
                                                        }}
                                                      >
                                                        <i className="fa fa-envelope fa-4x"></i>
                                                      </button> */}
                                                    </>
                                                  )}
                                              </>
                                            )
                                          }
                                          {
                                            (transaction.txn_status === 'COMPLETED' && transaction.txnr_type !== 'pledgedin' && transaction.txnr_type !== 'pledgedout' && transaction.txnr_type !== 'transferofunits') && (
                                              <button type="button" data-tip={'View Ledgers'} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                                                onClick={() => {
                                                  const getLederByTxnId = async () => {
                                                    try {
                                                      setledgerLoading(true);
                                                      const response = await getLedger(email, transaction.txn_id.replace('TXN_', ''));
                                                      ledgerData.length = 0;
                                                      setLedgerData(ledgerData);
                                                      let array = Object.entries(response.data.data);

                                                      let data: any = []
                                                      array.forEach(([key, value]) => {
                                                        let record: [] = []
                                                        data = value;
                                                        record['Record'] = data.Record
                                                        record['Record']['ledger_id'] = data.Key
                                                        ledgerData.push(record)
                                                      })
                                                      setCountLedgerData(ledgerData.length)
                                                      setTxnObject(transaction)
                                                      SetViewLedgers(true);
                                                      setledgerLoading(false);
                                                    } catch (err) {
                                                      setledgerLoading(false);
                                                    }
                                                  };
                                                  getLederByTxnId();
                                                }}
                                              >
                                                <i className="fa fa-clipboard-list"></i>
                                              </button>
                                            )
                                          }

                                          {/* {transaction.txn_status === "COMPLETED" && (
                                            <button type="button" data-tip={'Reverse Transaction'} className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6"
                                              onClick={() => {
                                                setReverseTransactionModal(true)
                                                setReverseTransaction(transaction)
                                              }}
                                            >
                                              <i className="fa fa-backward"></i>
                                            </button>
                                          )} */}
                                        </div>


                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )
                      )}

                      {/* {togglePagination===true ? ( */}
                      <div
                        className="row pointer mr-3"
                        style={{
                          float: 'right',
                        }}

                      >
                        {/* PREVIOUS BUTTON */}
                        {activePage !== paginationArr[0] ? (
                          <i
                            className="fa fa-arrow-left"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index - 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {/* NEXT BUTTON */}
                        {activePage !== paginationArr[paginationArr.length - 1] ? (
                          <i
                            className="fa ml-3 fa-arrow-right"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index + 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* ):(
                   ''
                  )} */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">

                  <h4>
                    <span>
                      <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {
                        setView(false);
                        setColor('#db6cfa');
                      }}></i>
                      View Transaction
                    </span>
                    <span>
                      <Link to='/admin/transaction-status' >
                        <IoMdClose size={35} className='ml-2 mt-1 pointer float-right'
                          style={styles}
                          onMouseEnter={() => setColor("white")}
                          onMouseLeave={() => setColor("#db6cfa")}
                          onClick={() => {
                            setView(false);
                            setColor('#db6cfa');
                          }}
                        />
                      </Link>
                    </span>
                  </h4>
                  {/* <h4 className='float-right'>
                    
                  </h4> */}
                  <ViewTransaction data={data} />

                  <div className="line"></div>

                  <Row>
                    {trx_history.map((hist: any, index: number) => {
                      return (
                        <Col
                          md="3"
                          onClick={() => {
                            if (hist.action === 'REJECTED-TRUSTEE' || hist.action === 'REJECTED-AMC') {
                              setNote(hist.note);
                            } else {
                              setNote('');
                            }
                            if (hist.action === 'CREATED') {
                              return;
                            }
                            if (!hist.check_list) return;
                            setTrxCheckList(hist.check_list);
                            setViewCheckList(true);
                          }}
                        >
                          <div className="card process-card pointer">
                            <div className="card-header">
                              <h4 className="card-title">
                                <span className="card-number">
                                  {index + 1}
                                </span>
                                <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                              </h4>
                            </div>
                            <div className="card-body text-center">

                              <p className="small mt-2">Status:</p>
                              <h3 className="accepted text-primary">  {hist.action}    </h3>
                              {hist.updated_at !== '' ? (
                                <>
                                  <p className="small mt-2"> by</p>
                                  <h4 className="comment text-primary">{(hist.updated_by)}</h4>
                                  <p className="small mt-2">at</p>
                                  <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>
                                </>
                              ) : (
                                ''
                              )}
                              {hist.note !== '' ? (
                                <>
                                  <p className="small mt-2">Comment</p>
                                  <small className="comment dot-text" title={hist.note}>{hist.note}</small>
                                </>
                              ) : (
                                ''
                              )}
                              {
                                hist.created_by !== '' ? (
                                  <>
                                    <p className="small mt-2"> by</p>
                                    <h4 className="text-primary">{hist.created_by}</h4>
                                    <p className="small mt-2"> at</p>
                                    <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")}</h4>

                                  </>
                                ) : (
                                  ''
                                )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              {renderSecuritiesModalPopup()}
              {renderTaxPopup()}
              {renderModalForLetterPopup()}
              {viewCheckListPopup()}
              {viewReverseTransaction()}
              {/* {renderMailPopup()} */}
              {renderViewLedgersPopup()}
              {renderModalForMarkRealized()}
              {renderModalForInstrumentPopup()}
              {renderModaForPrintChequePopup()}
              {renderModalSendEmail()}
            </Container>
          </Col>
        </Row>
      </div >
    </>
  );
};
export default PendingTransactions;


