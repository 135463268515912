import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { editBankData } from '../../../stores/services/bank.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const EditBank = () => {
  const history = useHistory();
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [bankNamelError, setBankNameError] = useState('');
  const [bankCodeError, setBankCodeError] = useState('');
  const [Loading, setLoading] = useState(false);
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('bankObj') || '');
    setBankName(obj.bank_name);
    setBankCode(obj.bank_code.replace('BANK_',''));
  }, []);
  const validate = () => {
    let nameError = '';
    let codeError = '';
    bankName.trim() === '' ? (nameError = 'Required') : (nameError = '');
    bankCode.trim() === '' ? (codeError = 'Required') : (codeError = '');

    if (codeError || nameError) {
      setBankNameError(nameError);
      setBankCodeError(codeError);
      return false;
    } else {
      return true;
    }
  };
  const AddBank = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await editBankData(bankName, bankCode, email);
        if(response.data.status===200){
        setBankName('');
        setBankCode('');
        toast.success(response.data.message);
        sessionStorage.removeItem('bankObj');
        setTimeout(() => {
          history.replace(`/admin/banks`);
        }, 2000);
      }else{
        toast.error(response.data.message);
      }
      } catch (error:any) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header title-row">
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              history.replace('/admin/banks')
          }}></i>Edit - Bank</h4>
  
            <Link to="/admin/banks" className="btn btn-primary btn-sm ml-auto" replace>
              <i className="fa fa-eye mr-2"></i>View All
              </Link>
          </div>
          <div className="card-body">
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    className={`form-control  w-100 ${bankNamelError ? 'required-border' : ''
                      }`}
                    value={bankName}
                    onChange={(e) => {
                      setBankName(e.target.value);
                      setBankNameError('');
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        AddBank();
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label>Bank Code</label>
                  <div className="disable-input">
                    <input
                      readOnly
                      type="text"
                      placeholder="Bank Code"
                      className={`form-control  w-100 ${bankCodeError ? 'required-border' : ''
                        }`}
                      value={bankCode}
                      onChange={(e) => {
                        setBankCode(e.target.value.toUpperCase());
                        setBankCodeError('');
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          AddBank();
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex ">
            <div className="form-group">
              
              <button
                className="btn btn-primary ml-3"
                onClick={AddBank}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>

              <button
                className="btn btn-default ml-3"
                onClick={() => {
                  history.replace('/admin/banks');
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EditBank;
