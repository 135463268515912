import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isNumber, numberWithCommas } from '../../../utils/customFunction';

const ViewFundSecurity = () => {
    const history = useHistory();
    const [code, setCode] = useState('');
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')

    const [file, setFile] = useState('');
    React.useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem('fundSecurityObj') || '');
        setCode(obj.code);
        setName(obj.name);
        setPrice(obj.price);


        setFile(obj.companies);
    }, []);
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <div className="card mt-4">
                                <div className="">
                                    <div className="card-header title-row">
                                        <h4 className="card-title">
                                            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                                                history.push('/admin/securities', { params: '3' })
                                            }}></i>View - Investment In Fund Securities</h4>
                                        <button type="button" className="t-3 ml-auto btn btn-primary btn-sm" onClick={() => {
                                            history.push('/admin/securities', { params: '3' })
                                        }}><i className="fa fa-eye mr-2"></i>View All</button>
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Security Name</label>
                                                    <div className="form-control">{name}</div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Code</label>
                                                    <div className="form-control">{code}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <div className="form-control">{numberWithCommas(isNumber(parseFloat(price).toFixed(4)))}</div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>

                                </div>
                            </div>
                        </Container>

                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ViewFundSecurity;