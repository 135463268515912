import React, { useState, useRef, useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JsonToTable } from 'react-json-to-table';
import XLSX from "xlsx";
import {
    VerifiedClient,
    getAllUnitHolders, markUnitHoldersFiler, sentClientVerificationData
} from '../../../stores/services/unit-holder.service';
import { useHistory } from 'react-router-dom';
import { convertTypeAcquisitionFromJson } from 'typescript';
import { MoneyMarketSaleLetter } from '../../letterTemplates/mmSale.letter.template';
// import { useWorker } from "@koale/useworker";
// import readXlsxFile from 'read-excel-file'
import Worker from "./worker.js"
import WebWorker from "./setup";


const ClientVerification = () => {

    const [loading, setLoading] = useState(false);
    const email = sessionStorage.getItem('email') || '';
    const [data,setData]=useState([])
    const [allHolder,setAllHolder]=useState([])
    const [matchData,setMatchData]=useState([])

    useEffect(() => {
        matchData.length=0
        setMatchData([])
        clientVerificationData();
        // onload()
    }, []);

const clientVerificationData = async () => {
  setLoading(true);
  try {
    const response = await VerifiedClient();
    const holder_response=await getAllUnitHolders(email,"","")
    
    if(holder_response.data.status == 200)
    {
        let temp:any=[]
        setAllHolder(holder_response.data.data)
        // let temp = holder_response.data.data.filter((item) => {
        //     return response?.data?.some((item2) => item2.cnic?.replaceAll("-","") == item.cnic?.replaceAll("-","") &&  item.cnic?.replaceAll("-","") != "");
        //   })
        for(let i=0;i<holder_response.data.data.length;i++)
        {
            let index= response?.data?.find(item=>item.cnic?.replaceAll("-","") == holder_response.data.data[i].cnic?.replaceAll("-","") && holder_response.data.data[i].cnic?.replaceAll("-","") != "")
           
            if(index)
            {
               
                temp.push({folio_number:holder_response.data.data[i].folio_no, cnic : holder_response.data.data[i].cnic,name:holder_response.data.data[i].name, amc_code:holder_response.data.data[i].amc_code,})

            }
        }
        if(temp.length > 0)
        {
            await sentClientVerificationData(email, JSON.stringify(temp))
        }

        setMatchData(temp)


         
    }else{
        setAllHolder([])
    }
   if(response.status == 200)
   {
    setData(response.data)
   }else{
    setData([])
   }
   
   setLoading(false)
  } catch (err: any) {
    if (err.response !== undefined) {
      toast.error(err.response.data.message);
    } else {
      toast.error(err.message)
    }
    setLoading(false);
  }
}
    const onload = async () => {
        setLoading(true);

        try {
            const response = await getAllUnitHolders(email, '1', '');
            if (response.data.status == 200) {
                console.log('Alldata', response.data.data)
                // const allNonFiler = response1.data.data.filter((elem: any) => {
                //     elem.tax_status = "Non-Filer";
                //     return elem;
                // })
            } else {
                toast.error(response.data.message);
            }
        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message)
            }
        }
        setLoading(false);
    }



    // const runUpload = (e: any) => {
    //     let file = e?.target.files[0];
    //     setFileLoading(true);
    //     let type = file.name.substring(file.name.lastIndexOf('.') + 1)
    //     const reader: any = new FileReader();
    //     const rABS = !!reader.readAsBinaryString;
    //     reader.onload = function (evt: any) {
    //         /* Parse data */
    //         const bstr = reader.result;
    //         const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

    //         let ws;
    //         let data: any;
    //         let fileData1: any = [];

    //         for (let i = 0; i < wb.SheetNames.length; i++) {
    //             let wsname = wb.SheetNames[i];
    //             ws = wb.Sheets[wsname];
    //             data = XLSX.utils.sheet_to_json(ws, {
    //                 header: 2,
    //                 raw: false,
    //                 defval: "",
    //                 dateNF: 'YYYY-MM-DD',
    //             });
    //             fileData1.push(data);
    //         }
    //         let fileData2 = fileData1.flat();

    //         let webWorker: any = new WebWorker(Worker)
    //         webWorker.onmessage = async function (e) {
    //             let filer = e.data;
    //             try {
    //                 const response = await markUnitHoldersFiler(email, filer);
    //                 if (response.status == 200) {
    //                     toast.success("Update Successfuly")
    //                     setFileLoading(false);
    //                 } else {
    //                     toast.error(response.data.message);
    //                     setFileLoading(false);
    //                 }
    //             } catch (err: any) {
    //                 if (err.response !== undefined) {
    //                     toast.error(err.response.data.message);
    //                 } else {
    //                     toast.error(err.message)
    //                 }
    //             }

    //         };
    //         webWorker.postMessage({ noneFiler: noneFiler, fileData2: fileData2 });

    //     }
    //     reader.readAsBinaryString(file);
    // };

    // const markAllFiler = async () => {
    //     setBtnLoading(true)
    //     try {
    //         const response = await markUnitHoldersFiler(email, fileData);
    //         if (response.data.status == 200) {
    //             toast.success(response.data.message)
    //         } else {
    //             toast.error(response.data.message);
    //         }
    //     } catch (err: any) {
    //         if (err.response !== undefined) {
    //             toast.error(err.response.data.message);
    //         } else {
    //             toast.error(err.message)
    //         }
    //     }
    //     setBtnLoading(false)
    // }


    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />

                            <div className="card ">
                                <div className="card-header">
                                    <h4 className="card-title">Client Verification(NECTA)</h4>
                                </div>
                                <div className="card-body">
                                    {!loading ? (
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Folio No</th>
                                                        <th>Name</th>
                                                        <th>CNIC</th>
                                                        <th>AMC Code</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {

                                                    matchData.map((elem: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{elem.folio_number}</td>
                                                                <td>{elem.name}</td>
                                                                <td>{elem.cnic }</td>
                                                                <td>{elem.amc_code}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }



                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center my-5">
                                            <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                                        </div>
                                    )}

                                    {matchData.length > 0  && <div className="col-12 card-title">Data was sent to Compliance Successfully</div>}
                                </div>


                            </div>

                            
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    )
};
export default ClientVerification;


