import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getAllPendingTransactions, searchTransactionsByRole } from '../../stores/services/transactions.service';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { getChecklist } from '../../stores/services/checklist.service';
import CheckListItem from '../../components/checklist-item';
import { updateBulkTransactionStatus } from '../../stores/services/transactions.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAmc } from '../../stores/services/amc.service';
import { isDateValid } from '../../utils/customFunction';

const ComplianceSignatoryTransactions = (props: any) => {

  const history = useHistory();
  const loginUser = sessionStorage.getItem('loggedInUser') || '';
  let [searchValue, setSearchValue] = useState('');
  let [transactions, setTransactions] = useState([]);
  let [headLabel, setHeadLabel] = useState('');
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  let getUrlPath = (window.location.pathname.split('/').pop() || '').toString();

  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [Amc, setAmc] = useState('');
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [togglePagination, setTogglePagination] = useState(false);


  React.useEffect(() => {
    setHeadLabel(getUrlPath);
    async function getTransactions() {
      setResLoading(true);
      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        getUrlPath.toUpperCase() === 'COMPLIANCE'
          ? 'COMPLIANCE'
          : getUrlPath.toUpperCase() === 'SIGNATORY-A'
            ? 'INREVIEW,SIGNATORY-A'
            : 'INREVIEW,SIGNATORY-B',
        searchValue,
        activePage
      )
        .then((response) => {
          if (response.data.status === 200) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            // let txnn = response.data.data.filter((item) => {
            //   let accept: any = false;
            //   item.txn_history.filter((elem) => {
            //     if ((email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-B") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-B")) {
            //       accept = true;
            //     }
            //   })
            //   if (accept === false) {
            //     return item;
            //   }
            // })
            // setTransactions(txnn);

            setCnt(0);
            setTransactions(response.data.data);
            setResLoading(false);
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
          }
        })
        .catch((err) => {
          setResLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!")
          }
        });
      setResLoading(false);
    }

    getTransactions();

  }, [activePage]);

  React.useEffect(() => {
    let userInfo: any = JSON.parse(loginUser);
    let loginUserInfo: any = userInfo.amc_code.split(',');
    const getAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        if (loginUserInfo[0] === '') {
          setAllAmc(amcResponse.data.data);
        } else {
          const allowAmcFilter = amcResponse.data.data.filter((item) => {
            for (let i = 0; i <= loginUserInfo.length; i++) {
              if (item.amc_code === loginUserInfo[i]) {
                return item;
              }
            }
          })
          setAllAmc(allowAmcFilter);
        }

      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
    }
    getAMC();
  }, [])

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: string) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  }

  const getFunds = async (code: any) => {
    try {
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (error) {

    }
  }

  // Fund Type Drop Down
  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    })
  }

  // Search Transaction
  const searchTransactionsByAMCAndFund = async (statusVar = '', searchVar = '', amcCodeVar = '', fundCodeVar = '') => {
    setTogglePagination(true);
    return new Promise(async (resolve, reject) => {
      if (searchVar !== '') {
        searchVar = 'TXN_' + searchVar;
      }

      setResLoading(true);
      setSearchLoading(true);
      await searchTransactionsByRole(
        sessionStorage.getItem('email') || '',
        amcCodeVar,
        fundCodeVar,
        getUrlPath.toUpperCase() === 'COMPLIANCE'
          ? 'COMPLIANCE'
          : getUrlPath.toUpperCase() === 'SIGNATORY-A'
            ? 'INREVIEW,SIGNATORY-A'
            : 'INREVIEW,SIGNATORY-B',
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            // let pageArr = paginationArr

            paginationArr = paginationArr.filter(function (item) {
              return item == ''
            })
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);

          resolve('Success');

        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };
  // 
  const CapTxnType = (type: any) => {
    let i, txType;
    if (txnTypes) {
      for (i = 0; i < txnTypes.length; i++) {
        if (txnTypes[i].api_name === type) {
          txType = txnTypes[i].dis_name;
          break;
        }
      }
    }
    return txType;
  };

  const searchFilters = async (searchVar = '') => {
    return new Promise(async (resolve, reject) => {
      let search = true;
      if (searchVar === '') {
        search = false;
      }
      setResLoading(true);
      setSearchLoading(search);
      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        getUrlPath.toUpperCase() === 'COMPLIANCE'
          ? 'COMPLIANCE'
          : getUrlPath.toUpperCase() === 'SIGNATORY-A'
            ? 'INREVIEW,SIGNATORY-A'
            : 'INREVIEW,SIGNATORY-B',
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.status === 200) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            // let txnn = response.data.data.filter((item) => {
            //   let accept: any = false;
            //   item.txn_history.filter((elem) => {
            //     if ((email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-B") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-B")) {
            //       accept = true;
            //     }
            //   })
            //   if (accept === false) {
            //     return item;
            //   }
            // })
            setCnt(0);
            setTransactions(response.data.data);
            setResLoading(false);
            setSearchLoading(false);
            resolve('Success');
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
            setSearchLoading(false);
          }
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          setTransactions([]);
          toast.error(err.response.data.message);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!")
          }
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };

  const clearFilters = async (searchVar = '') => {
    return new Promise(async (resolve, reject) => {
      setResLoading(true);
      setClearLoading(true);
      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        getUrlPath.toUpperCase() === 'COMPLIANCE'
          ? 'COMPLIANCE'
          : getUrlPath.toUpperCase() === 'SIGNATORY-A'
            ? 'INREVIEW,SIGNATORY-A'
            : 'INREVIEW,SIGNATORY-B',
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.status === 200) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }

            setPaginationArr(pageArr);
            // let txnn = response.data.data.filter((item) => {
            //   let accept: any = false;
            //   item.txn_history.filter((elem) => {
            //     if ((email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-A") || (email === elem.updated_by && elem.action === 'ACCEPTED' && elem.updated_by_role === "SIGNATORY-B") || (email === elem.updated_by && elem.action === '' && elem.updated_by_role === "SIGNATORY-B")) {
            //       accept = true;
            //     }
            //   })
            //   if (accept === false) {
            //     return item;
            //   }
            // })
            setCnt(0);
            setTransactions(response.data.data);
            setResLoading(false);
            setClearLoading(false);
            resolve('Success');
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
            setClearLoading(false);
          }
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          setTransactions([]);
          toast.error(err.response.data.message);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!")
          }
          reject('Error');
        });
      setResLoading(false);
      setClearLoading(false);
    });
  };

  let [bulkProcessingData, setBulkProcessingData] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);
  const [checklistData, setCheckListData] = useState<any>([]);
  const role_name = sessionStorage.getItem('role') || '';
  const email = sessionStorage.getItem('email') || '';
  const [reasonText, setReasonText] = useState('');
  const [reasonTextError, setReasonTextError] = useState('');
  const [rejectLoading, setRejectLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [changeCheck, setChangeCheck] = useState('');
  let [count, setCount] = useState(0);
  let [cnt, setCnt] = useState(0);

  const addTxnForBulk = (txnObj) => {
    count = count + 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id.replace('TXN_', '') === txnObj.txn_id.replace('TXN_', '');
    });

    if (check.length === 0) {
      let temp = {
        id: txnObj.txn_id.replace('TXN_', '') || '',
        status: txnObj.txn_status || '',
        counter: txnObj.counter || '0',
        made_by: txnObj.made_by || ''
      };
      bulkProcessingData.push(temp);
      setBulkProcessingData(bulkProcessingData);
      setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
    }
    setCount(count);
  };
  const addAllTxnForBulk = (txnObjs) => {
    count = count + 1;
    txnObjs.filter((txnObj) => {
      const check = bulkProcessingData.filter((item) => {
        return item.id.replace('TXN_', '') === txnObj.txn_id.replace('TXN_', '');
      });
      if (check.length === 0) {
        let temp = {
          id: txnObj.txn_id.replace('TXN_', '') || '',
          status: txnObj.txn_status || '',
          counter: txnObj.counter || '0',
          made_by: txnObj.made_by || ''
        };
        bulkProcessingData.push(temp);
        setBulkProcessingData(bulkProcessingData);
        setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
      }
    })



    setCount(bulkProcessingData.length);
  };

  const removeTxnFromBulk = (txnObj) => {
    count = count - 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id.replace('TXN_', '') !== txnObj.txn_id.replace('TXN_', '');
    });
    bulkProcessingData = check;
    setBulkProcessingData(bulkProcessingData);
    setCount(count);
    setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
  };

  const removeAllTxnFromBulk = (txnObjs) => {
    count = count - 1;
    txnObjs.filter((txnObj) => {
      const check = bulkProcessingData.filter((item) => {
        return item.id.replace('TXN_', '') !== txnObj.txn_id.replace('TXN_', '');
      });
      bulkProcessingData = check;
      setBulkProcessingData(bulkProcessingData);
      setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
    })
    setCount(bulkProcessingData.length);
  };

  const checkBoolean = (txnId) => {
    let tx = false;
    bulkProcessingData.map((item, index) => {
      if (item.id === txnId) {
        tx = true;
      }
    })
    return tx;
  }
  const checkAllBoolean = (txnObjs) => {
    cnt = 0;
    txnObjs.filter((elem) => {
      bulkProcessingData.map((item, index) => {
        if (item.id === elem.txn_id.replace('TXN_', '')) {
          checkBoolean(item.id);
          cnt += 1;
        }
      })
    })
    if (cnt === 10) {
      return true;
    } else {
      return false;
    }
  }



  React.useEffect(() => {
    const getAllChecklists = async () => {
      try {
        const response = await getChecklist(email, role_name);
        setCheckListData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }

      }
    };
    getAllChecklists();
  }, []);

  const updateFormData = (obj, id) => {
    let temp = checklistData;
    temp[id] = obj;
    setCheckListData(temp);
  };



  const updateBulkTxStatus = async () => {
    const isValid = validate();
    if (isValid) {

      if (status === 'accept') {
        setReasonTextError('');
        setLoading(true);
        let data = JSON.stringify(bulkProcessingData);
        try {
          const response = await updateBulkTransactionStatus(
            email,
            role_name,
            status,
            reasonText,
            checklistData,
            data,
            headLabel.toUpperCase()
          );
          if (response.data.message === "Transactions updated successfully") {
            setCheckListSetPopup(false);
            setTimeout(() => {
              searchFilters('').then(() => {
                setSearchValue('');
                setActivePage('');
              });
            }, 3000);

            bulkProcessingData.length = 0;
            setBulkProcessingData(bulkProcessingData);
            setStatus('');
            setReasonText('');
            toast.success(response.data.message);
          } else if (response.data.status === 409) {
            setCheckListSetPopup(false);
            searchFilters('').then(() => {
              setSearchValue('');
              setActivePage('');
            });
            bulkProcessingData.length = 0;
            setBulkProcessingData(bulkProcessingData);
            setStatus('');
            setReasonText('');
            toast.error(response.data.message);
          } else if (response.data.message !== 'Transaction is already marked as completed!') {
            toast.error(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (err: any) {
          setLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message || 'Error is Processing');
          } else {
            toast.error(err.message)
          }
        }
        setCount(0);
        setLoading(false);
      } else {
        setStatusError('');
        if (reasonText.trim() === '') {
          setReasonTextError('Required');
        } else {
          setReasonTextError('');
          setLoading(true);
          let data = JSON.stringify(bulkProcessingData);
          try {
            const response = await updateBulkTransactionStatus(
              email,
              role_name,
              status,
              reasonText,
              checklistData,
              data,
              headLabel.toUpperCase()
            );
            if (response.data.message === "Transactions updated successfully") {
              setCheckListSetPopup(false);
              searchFilters('').then(() => {
                setSearchValue('');
                setActivePage('');
                bulkProcessingData.length = 0;
                setBulkProcessingData(bulkProcessingData);
                setStatus('');
                setReasonText('');
                toast.success(response.data.message);
              });


            } else if (response.data.status === 409) {
              setCheckListSetPopup(false);
              searchFilters('').then(() => {
                setSearchValue('');
                setActivePage('');
              });
              bulkProcessingData.length = 0;
              setBulkProcessingData(bulkProcessingData);
              setStatus('');
              setReasonText('');
              toast.error(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.data.message || 'Error is Processing');
          }
          setCount(0);
          setLoading(false);
        }
      }

    }// end of isvalid
  };


  const validate = () => {
    let statusErr = '';
    status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');
    if (statusErr) {
      setStatusError(statusErr);
      return false;
    } else {
      return true;
    }
  };

  const renderCheckListPopup = () => {
    switch (checklistSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            enforceFocus={false}
            size="lg"
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setCheckListSetPopup(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Approval Check List </h6>
            </div>


            <div className="modal-body">
              <div className="table-responsive" >
                <div className={'checlist-scroll'}>
                  {checklistData.map((val: any, ind: any) => {
                    return (
                      <CheckListItem
                        updateFormData={updateFormData}
                        val={val}
                        ind={ind}
                      />
                    );
                  })}
                </div>
                <div> Transactions Selected: <span className="text-primary">{count}</span>  </div>
                <Row className="pb-3">
                  <Col md="12">
                    <div className="d-flex">
                      <FormGroup check className="mr-4">
                        <Label check>
                          <Input checked={status === 'accept'}
                            type="checkbox"
                            value="accept"
                            onChange={(e) => {
                              setReasonText('');
                              setStatusError('');
                              setStatus(e.target.value);
                            }} />
                          <span className="form-check-sign" />
                          Accept
                        </Label>
                      </FormGroup>

                      <FormGroup check className="ml-1">
                        <Label check>
                          <Input type="checkbox"
                            checked={status === 'reject'}
                            value="reject"
                            onChange={(e) => {
                              setStatusError('');
                              setStatus(e.target.value);
                            }} />
                          <span className="form-check-sign" />
                          Reject
                        </Label>
                      </FormGroup>


                    </div>
                  </Col>
                </Row>
                <Row>
                  {statusError ? (
                    <text className="error-lab">{statusError}</text>
                  ) : (
                    ''
                  )}
                </Row>
                {status === 'reject' && (
                  <Row>
                    <Col >
                      <div className="form-group">
                        <textarea
                          placeholder="Reason"
                          className={'form-control w-100'}
                          value={reasonText}
                          onChange={(e) => {
                            setReasonText(e.target.value);
                            setReasonTextError('');
                          }}
                        ></textarea>
                      </div>
                      {reasonTextError && (
                        <text className="error-lab">{reasonTextError}</text>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="12">
                    <button
                      className="btn btn-primary mr-3"
                      disabled={Boolean(Loading)}
                      onClick={() => {
                        updateBulkTxStatus();
                      }}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        'Submit'
                      )}
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setCheckListSetPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    {/* </div> */}
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">

            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">{headLabel.toUpperCase()} Transactions</h4>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md="2">
                      <div className='form-group'>
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <label >Transaction ID</label>
                        <input
                          type="search"
                          id="myInput"
                          placeholder="Transaction ID"
                          data-tip="Search Transaction"
                          className="form-control mr-2"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label >Select AMC</label>
                        <select
                          className={"form-control"}
                          value={Amc}
                          onChange={(e) => {
                            setAmc(e.target.value);
                            getFunds(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select AMC
                          </option>
                          {renderAmcDropdown()}
                        </select>
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label >Select Fund</label>
                        <select
                          className={"form-control"}
                          value={fund}
                          onChange={(e) => {
                            setFund(e.target.value);
                          }}>
                          <option value="" defaultChecked hidden>
                            Select Fund
                          </option>
                          {renderFundsDropDown()}
                        </select>
                      </div>
                    </Col>

                    {/* <Col md="2">
                      <div className="form-group ml-2 mr-2">
                        <label >Select Status</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                          value={status}
                        >
                          <option value="-1">Select type</option>
                          <option value="COMPLIANCE">Compliance</option>
                          <option value="INREVIEW">In Review</option>
                          <option value="SIGNATORY-A">Signatory A</option>
                          <option value="SIGNATORY-B">Signatory B</option>
                          <option value="COMPLETED">Completed</option>
                          <option value="CONCERNED-OFFICER">CONCERNED OFFICER</option>
                          <option value="REJECTED-TRUSTEE">Rejected TRUSTEE</option>
                          <option value="REJECTED-AMC">Rejected AMC</option>
                        </select>
                      </div>

                    </Col> */}

                    <Col md="4">
                      <div className=" inline-box">
                        <button
                          className="btn btn-primary mt-4"
                          disabled={Boolean(searchLoading)}
                          onClick={() => {
                            if ((searchValue || status !== "-1" || Amc || fund) !== '') {
                              setChangeCheck(searchValue);
                              setBulkProcessingData(bulkProcessingData);
                              searchTransactionsByAMCAndFund(status, searchValue, Amc, fund).then(() => {
                                // setPaginationArr(['']);
                                setActivePage('');

                              });
                            }
                          }}
                        >
                          {searchLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Searching...</span>
                            </>
                          ) : (
                            'Search'
                          )}
                          {/* Search		 */}
                        </button>
                        <button
                          className="btn btn-default mt-4"
                          disabled={Boolean(clearLoading)}
                          onClick={() => {
                            setCount(0);
                            setAmc('');
                            setFund('')
                            setChangeCheck('')
                            setBulkProcessingData([]);
                            setSearchValue('');
                            clearFilters('').then(() => {
                              setActivePage('');
                            });
                          }}

                        >
                          {clearLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Clear</span>
                            </>
                          ) : (
                            'Clear'
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>


                  <div className="">
                    {resLoading ? (
                      <div className="d-flex justify-content-center my-5">
                        <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                      </div>
                    ) : (
                      transactions.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th><FormGroup check>
                                  <Label check>
                                    <Input type="checkbox"
                                      checked={checkAllBoolean(transactions)}
                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          addAllTxnForBulk(transactions);
                                        } else {
                                          removeAllTxnFromBulk(transactions);
                                        }
                                      }} />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup></th>
                                <th>Transaction Id</th>
                                <th>AMC Code</th>
                                <th>Transaction Type</th>
                                <th className="text-right">Net Amount</th>
                                <th>Created By</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            
                            <tbody>
                              {transactions.map((transaction: any, index) => {
                                return (

                                  <tr key={index} className={(transaction.txnr_type
                                    ? CapTxnType(transaction.txnr_type)
                                    : 'N/A') === 'Equity Auto-Settlement' ? "highlight-row-error" : ''}>
                                    <td>
                                      <FormGroup check>
                                        <Label check>
                                          <Input type="checkbox"
                                            checked={checkBoolean(transaction.txn_id.replace('TXN_', ''))}
                                            onChange={(e) => {
                                              if (e.target.checked === true) {
                                                addTxnForBulk(transaction);
                                              } else {
                                                removeTxnFromBulk(transaction);
                                              }
                                            }} />
                                          <span className="form-check-sign" />
                                        </Label>
                                      </FormGroup>

                                    </td>
                                    <td>{transaction.txn_id.replaceAll('TXN_', '')}</td>
                                    <td>
                                      {transaction.amc_code ? transaction.amc_code : ''}
                                    </td>
                                    <td>
                                      {transaction.txnr_type
                                        ? CapTxnType(transaction.txnr_type)
                                        : 'N/A'}
                                    </td>
                                    <td className="text-right" > {numberWithCommas(transaction.net_amount)}</td>
                                    <td>
                                      {transaction.created_by
                                        ? transaction.created_by
                                        : 'N/A'}
                                    </td>
                                    <td>
                                    {/* {moment(JSON.parse(transaction.txn_history[0])[0].created_at).format('DD-MM-YYYY')} */}
                                      {transaction.txn_history
                                        ? transaction.txn_history[0]?.created_at
                                          ? moment(
                                            transaction.txn_history[0].created_at
                                          ).format('DD-MM-YYYY')
                                          : 'N/A'
                                        : 'N/A'}
                                    </td>
                                    <td>{transaction.txn_status}</td>
                                    <td className=" ">
                                      <div className="multi-icons pointer">
                                        <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                                          sessionStorage.setItem(
                                            'csab-txn',
                                            JSON.stringify(transaction)
                                          );
                                          history.replace(`/admin/details/${headLabel}`);
                                        }}	>
                                          <i className="fa fa-eye">  </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">No record found</p>
                        </div>
                      )
                    )}
                    <div
                      className="row pointer mr-3"
                      style={{
                        float: 'right',
                      }}
                    >
                      {activePage !== paginationArr[0] ? (
                        <i
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex((x) => x === activePage);
                            if (index !== -1) {
                              setActivePage(paginationArr[index - 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                      {/* NEXT BUTTON */}
                      {activePage !== paginationArr[paginationArr.length - 1] ? (
                        <i
                          className="fa ml-3 fa-arrow-right"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex((x) => x === activePage);
                            if (index !== -1) {
                              setActivePage(pageArr[index + 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                    </div>

                    {count === 0 ? (
                      ''
                    ) : (
                      <div className="mb-2">
                        <span className="text-primary">{count}</span> transactions selected. &nbsp; <a onClick={() => {
                          bulkProcessingData.length = 0;
                          setBulkProcessingData(bulkProcessingData);
                          setCount(0);
                          setChangeCheck(`${count}`);
                        }}>Clear Selection </a> </div>
                    )}

                    {bulkProcessingData.length > 0 && (
                      <Row>
                        <Col md="6">
                          <div className="d-flex">
                            <div className="">
                              <button
                                className="btn btn-primary"
                                disabled={Boolean(Loading)}
                                onClick={() => {
                                  setCheckListSetPopup(true);
                                }}
                              >
                                Action
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {renderCheckListPopup()}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ComplianceSignatoryTransactions;	
