import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param amc_code
 * @param amc_name
 * @param focal_person_email
 * @param focal_persan_name
 * @param amc_signatories
 * @param tax_advisor
 * @param concerned_officer
 * @param subsitute_concerned_officer
 * @param amc_auditor
 * @param from
 * @param to
 * @param txn_creator_field
 * @param evidence
 * @param list_of_authorized_signatories
 * @param amc_br
 */
const addAmc = async (
  email: string,
  amc_code: string,
  amc_name: string,
  focal_person_email: string,
  focal_person_name: string,
  amcsig: any,
  concerned_officer: string,
  subsitute_concerned_officer: string,
  from: string,
  to: string,
  txn_field: any,
  evidence: any,
  account_title: string,
  account_no: string,
  bank: string,
  branch: string,
  focal_person_phone: string,
  focal_person_mobile: string,
  tax_exemption: string,
  tax_collector: string,
  billing_contact:string,
  ntn:string
) => {
  const token = sessionStorage.getItem('token') || '';
  // var bodyFormData = new FormData();
  // bodyFormData.append('email', email);
  // bodyFormData.append('amc_code', amc_code);
  // bodyFormData.append('amc_name', amc_name);
  // bodyFormData.append('focal_person_email', focal_person_email);
  // bodyFormData.append('focal_person_name', focal_person_name);
  // bodyFormData.append('amc_signatories', amcsig);
  // bodyFormData.append('concerned_officer', concerned_officer);
  // bodyFormData.append('subsitute_concerned_officer',subsitute_concerned_officer);
  // bodyFormData.append('exemption_from', from);
  // bodyFormData.append('exemption_to', to);
  // bodyFormData.append('txn_creator_field', txn_field);
  // bodyFormData.append('evidence', evidence);
  // bodyFormData.append('account_title', account_title);
  // bodyFormData.append('account_no', account_no);
  // bodyFormData.append('bank', bank);
  // bodyFormData.append('branch', branch);
  // bodyFormData.append('focal_person_phone', focal_person_phone);
  // bodyFormData.append('focal_person_mobile', focal_person_mobile);
  // bodyFormData.append('tax_exemption', tax_exemption);
  // bodyFormData.append('tax_collector', tax_collector);
  // bodyFormData.append("billing_contact",billing_contact)
  // bodyFormData.append("ntn",ntn)

  const data = {
    email,
    amc_code,
    amc_name,
    focal_person_email,
    focal_person_name,
    amc_signatories: amcsig,
    concerned_officer,
    subsitute_concerned_officer,
    exemption_from: from,
    exemption_to: to,
    txn_creator_field: txn_field,
    evidence,
    account_title,
    account_no,
    bank,
    branch,
    focal_person_phone,
    focal_person_mobile,
    tax_exemption,
    tax_collector,
    billing_contact,
    ntn,
  };

  const url = `${Config.baseUrl}/amc/`;
  try{
  const result: any = await axios.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
  let responseToHandle = err.response.data
  let result = await RefreshTokenHandler.handleIt(responseToHandle)
  if(result.status) {
   return await addAmc(email,
    amc_code,
    amc_name,
    focal_person_email,
    focal_person_name,
    amcsig,
    concerned_officer,
    subsitute_concerned_officer,
    from,
    to,
    txn_field,
    evidence,
    account_title,
    account_no,
    bank,
    branch,
    focal_person_phone,
    focal_person_mobile,
    tax_exemption,
    tax_collector,
    billing_contact,
    ntn
    )
  }else {
    throw err;
    // return result
  }
}else {
  throw err
}
}
};

/**
 *
 * @param email
 */
const getAmc = async (
  email: string,
  page_number: string = '',
  page_size: string = '',
  filter_email: string = ''
) => {
  
  const url = `${Config.baseUrl}/amc?email=${email}&page_number=${page_number}&page_size=${page_size}&filter_email=${filter_email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAmc(email,page_number,page_size, filter_email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 *
 * @param search_value
 */
const searchAPI = async (email: string, search_value: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/amc?email=${email}&search_value=${search_value}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchAPI(email,search_value)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param amc_code
 * @param status
 */
const updateAmcStatus = async (
  email: string,
  amc_code: string,
  status: string
) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/amc/`;
  try{
  const result: any = await axios.put(
    url,
    { email, amc_code, status },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateAmcStatus(email,amc_code,status)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

/**
 *
 * @param email
 * @param amc_code
 * @param amc_name
 * @param focal_person_email
 * @param focal_persan_name
 * @param amc_signatories
 * @param tax_advisor
 * @param concerned_officer
 * @param subsitute_concerned_officer
 * @param amc_auditor
 * @param from
 * @param to
 * @param txn_creator_field
 * @param evidence
 * @param list_of_authorized_signatories
 * @param amc_br
 */
const editAmcData = async (
  email: string,
  amc_code: string,
  amc_name: string,
  focal_person_email: string,
  focal_person_name: string,
  amcsig: any,
  concerned_officer: string,
  subsitute_concerned_officer: string,
  from: string,
  to: string,
  txn_field: any,
  evidence: any,
  account_title: string,
  account_no: string,
  bank: string,
  branch: string,
  focal_person_phone: string,
  focal_person_mobile: string,
  tax_exemption: string,
  tax_collector: string,
  billing_contact:string,
  ntn:string
) => {
  const token = sessionStorage.getItem('token') || '';
//   var bodyFormData = new FormData();
//   bodyFormData.append('email', email);
//   bodyFormData.append('amc_code', amc_code);
//   bodyFormData.append('amc_name', amc_name);
//   bodyFormData.append('focal_person_email', focal_person_email);
//   bodyFormData.append('focal_person_name', focal_person_name);
//   bodyFormData.append('amc_signatories', amcsig);
//   bodyFormData.append('concerned_officer', concerned_officer);
//   bodyFormData.append(
//     'subsitute_concerned_officer',
//     subsitute_concerned_officer
//   );
//   bodyFormData.append('exemption_from', from);
//   bodyFormData.append('exemption_to', to);
//   bodyFormData.append('txn_creator_field', txn_field);
//   bodyFormData.append('evidence', evidence);
//   bodyFormData.append('account_title', account_title);
//   bodyFormData.append('account_no', account_no);
//   bodyFormData.append('bank', bank);
//   bodyFormData.append('branch', branch);
//   bodyFormData.append('focal_person_phone', focal_person_phone);
//   bodyFormData.append('focal_person_mobile', focal_person_mobile);
//   bodyFormData.append('tax_exemption', tax_exemption);
//   bodyFormData.append('tax_collector', tax_collector);
//  bodyFormData.append("billing_contact",billing_contact)
//  bodyFormData.append("ntn",ntn)
const data = {
  email,
  amc_code,
  amc_name,
  focal_person_email,
  focal_person_name,
  amc_signatories: amcsig,
  concerned_officer,
  subsitute_concerned_officer,
  exemption_from: from,
  exemption_to: to,
  txn_creator_field: txn_field,
  evidence,
  account_title,
  account_no,
  bank,
  branch,
  focal_person_phone,
  focal_person_mobile,
  tax_exemption,
  tax_collector,
  billing_contact,
  ntn,
};

  const url = `${Config.baseUrl}/amc/update`;
  try{
  const result: any = await axios.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
  let responseToHandle = err.response.data
  let result = await RefreshTokenHandler.handleIt(responseToHandle)
  if(result.status) {
   return await editAmcData( email,
    amc_code,
    amc_name,
    focal_person_email,
    focal_person_name,
    amcsig,
    concerned_officer,
    subsitute_concerned_officer,
    from,
    to,
    txn_field,
    evidence,
    account_title,
    account_no,
    bank,
    branch,
    focal_person_phone,
    focal_person_mobile,
    tax_exemption,
    tax_collector,
    billing_contact,
    ntn
    )
  }else {
    throw err;
    // return result
  }
}else {
  throw err
}
}
};

/**
 *
 * @param email
 * @param code
 */
const getAmcByCode = async (email: string, code: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/amc/by-code?email=${email}&code=${code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAmcByCode(email,code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};
/**
 * @param email
 */
const getBrokersByAmc = async (email: string, code: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/amc/get-brokers?email=${email}&code=${code}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getBrokersByAmc(email,code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export {
  addAmc,
  getAmc,
  searchAPI,
  updateAmcStatus,
  getAmcByCode,
  getBrokersByAmc,
  editAmcData,
};
