import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const getDashboardData = async (email: string) => {
  const url = `${Config.baseUrl}/dashboard/admin-dashboard?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
    return await getDashboardData(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getDashboardTransactions = async (
  email: string,
  start_date: string,
  end_date: string
) => {
  const url = `${Config.baseUrl}/dashboard/dashboard-txn?email=${email}&start_date=${start_date}&end_date=${end_date}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getDashboardTransactions(email,start_date,end_date)
    }else {
      throw err;
     
      // return result
    }
  }else {
        throw err
  }
}
};
const getDashboardTransactionsByAmc = async (
  email: string,
  start_date: string,
  end_date: string,
  amc_code:string,
  fund_code:string
) => {
  const url = `${Config.baseUrl}/transaction/txn-by-date-amc-fund?email=${email}&start_date=${start_date}&end_date=${end_date}&amc_code=${amc_code}&fund_code=${fund_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getDashboardTransactionsByAmc(email,start_date,end_date,amc_code, fund_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const ConvertToExponential = (num, digits) => {
  if (num == 0) {
    return 0;
  } else {
    const symbols = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const numToCheck = Math.abs(num);
    for (let i = symbols.length - 1; i >= 0; i--) {
      if (numToCheck >= symbols[i].value) {
        const newNumber = (num / symbols[i].value).toFixed(digits);
        return `${newNumber}${symbols[i].symbol}`;
      }
    }
    return num.toExponential(digits);
  }
};

const getDashboardCountData = async (email: string) => {
  const url = `${Config.baseUrl}/dashboard/dashboard-all-count?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getDashboardCountData(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export { getDashboardData, getDashboardTransactions, ConvertToExponential,getDashboardTransactionsByAmc,
  getDashboardCountData,
 };
