import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import { getFunds } from './../../stores/services/funds.service';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAccountByFund } from '../../stores/services/account.service';
import { getBankStatement, getBankStatementOpeningBalance } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { numberWithCommas, sortAMCS } from '../../utils/customFunction';

const EquityHoldingReport = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [reportdate, setReportDate] = useState(moment(date).format('DD-MM-YYYY'));
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState('');
  const [fund, setFund] = useState('');
  const [fundStockMarketData, setFundStockMarketData] = useState<any>([]);
  const [participantID, setParticipantID] = useState('');
  const [participantAccNo, setParticipantAccNo] = useState('');

  const [resAmc, setResAmc] = useState('');

  const [totalAmount, setTotalAmount] = useState('');
  const [totalQty, setTotalQty] = useState('');
  const [totalAvgRate, setTotalAvgRate] = useState('');

  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };

  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }

    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  // const  numberWithCommas = (amount:any) => {
  //     return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  // }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Equity Holding Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFunds(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setTotalAmount('')
                              setTotalQty('')
                              setTotalAvgRate('')
                              setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              let filterFund = allFunds.filter((item, any) => {
                                return (item.symbol_code === e.target.value);
                              });
                              setResAmc(Amc);
                              setFundStockMarketData(JSON.parse(filterFund[0].stock_market))
                              setParticipantID(filterFund[0].cdc_participant_id)
                              setParticipantAccNo(filterFund[0].cdc_account_number);
                              let data: any = [];
                              data = JSON.parse(filterFund[0].stock_market);
                              let amount = 0, qty = 0, avgrate = 0;
                              data.map((item: any, index: number) => {
                                amount += parseFloat(item.amount);
                                qty += parseFloat(item.unit);
                                avgrate += parseFloat(item.close_rate);
                              })
                              setTotalAmount(amount.toFixed(2))
                              setTotalQty(qty.toString())
                              setTotalAvgRate(avgrate.toFixed(2))
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>


                      {/* <Col md="2">
            <div className=" ">
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col> */}
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Equity-Holding-Report-" + date + ".pdf"} ref={pdfExportComponent}>
                        {fundStockMarketData.length > 0 ? (
                          <>
                            <ReportHeader title="Equity Holding Report" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Equity Holding Report</h3>
                  </div> */}
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{fund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Participant ID:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{participantID}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Participant Acc No:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{participantAccNo}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            {/* <Row>

                  <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                           AMC: 
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{resAmc}</span>
                        </Col>
                      </Row>
                    </Col>

                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            Fund: 
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{fund}</span>
                        </Col>
                      </Row>
                    </Col>

                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            Participant ID: 
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{participantID}</span>
                        </Col>
                      </Row>
                    </Col>

                      <Col md="5" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Participant Acc No: 
                            </span>
                          </Col>
                          <Col md="6">
                          <span className="text-nowrap">{participantAccNo}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                             Report Date: 
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{reportdate}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row> */}

                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >

                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr>
                                    <th>Market</th>
                                    <th>Symbol</th>
                                    <th className="text-right">Quantity</th>
                                    <th className="text-right">Average Rate</th>
                                    <th className="text-right">Amount</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {fundStockMarketData.map((item: any, index: number) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{item.market}</td>
                                          <td>{item.code}</td>
                                          <td className="text-right">{numberWithCommas(item.unit)}</td>
                                          <td className="text-right">{numberWithCommas(item.close_rate)}</td>
                                          <td className="text-right">{numberWithCommas(item.amount)}</td>
                                        </tr>
                                      </>
                                    );
                                  })}

                                  <tr>
                                    <th>Total</th>
                                    <th></th>
                                    <th className="text-right">{numberWithCommas(totalQty)}</th>
                                    <th className="text-right">{numberWithCommas(totalAvgRate)}</th>
                                    <th className="text-right">{numberWithCommas(totalAmount)}</th>
                                  </tr>

                                </tbody>
                              </table>
                            </div>

                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EquityHoldingReport;
