import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { getBankSummary } from '../../stores/services/report.service';
import { isNumber, numberWithCommas, sortAMCS } from '../../utils/customFunction';
import { getAllBanks } from '../../stores/services/bank.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAmc, getAmcByCode } from '../../stores/services/amc.service';
// import { itemFromArray } from 'tsparticles/Utils';
import { getAccountByAmc, getAccountByFund, getAccountByFundAndAmcCode } from '../../stores/services/account.service';

const BankSummary = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email: any = sessionStorage.getItem('email') || '';
  let fulldate = new Date();
  let date = moment(fulldate).format('YYYY-MM-DD')
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [Amc, setAmc] = useState('');
  const [amcCode, setAmcCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [fundCode, setFundCode] = useState('');
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [bankCompleteName, setBankCompleteName] = useState<any>([]);
  const [amcError, setAmcError] = useState('');
  const [fundError, setFundError] = useState('');
  const [bankError, setBankError] = useState('');


  const [getTotalBalance, setGetTotalBalance] = useState('0');

  const [bankRecords, setBankRecords] = useState<any>([]);



  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        let temp: any;
        temp = { name: 'All', amc_code: 'All' };
        sortAMCSByName.unshift(temp);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllAMC();
  }, [])


  const getFunds = async (code: any) => {
    try {
      // allFundAccounts.length = 0;
      // setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      if (code && code == 'All') {
        let temp: any;
        temp = [{ fund_name: 'Select Fund', symbol_code: 'Select Fund' },{ fund_name: 'All', symbol_code: 'All' }];
        setAllFunds([...temp]);
      } else {
        const response = await getFundByAmc(email, code);
        if (response.data.data.length > 0) {
          let temp: any;
          temp = { fund_name: 'All', symbol_code: 'All' };
          response.data.data.unshift(temp);
          setAllFunds(response.data.data);
        }
      }

    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }

    }
  }

  const renderFundsDropDown = () => {
    return allFunds && allFunds.map((item: any, index: number) => {
      if (item.symbol_code === 'All' || item.symbol_code==="Select Fund") {
        return (
            <option key={0} value={item.symbol_code}>
              {item.fund_name}
            </option>
        );
      } else {
        return (

          <option key={index} value={item.symbol_code}>
            {item.fund_name} - {item.symbol_code}
          </option>
        );
      }
    });
  }
  const getAllBanksData = async () => {
    try {
      const response = await getAllBanks(email, '', '');
      let temp: any = '';
      temp = { bank_name: 'All', bank_code: 'All' };
      response.data.data.unshift(temp);
      setAllBanks(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }
  useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(email);
        setBankCompleteName(bankResponse.data.data)
      } catch (error) {
      }
    };
    fetchBank();
    getAllBanksData();
  }, [])


  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}
        </option>
      );
    });
  };




  const renderBankDropdown = () => {
    return allBanks.map((item: any, index: number) => {
      if(fundCode!=='' && fundCode!=='Select Fund')
      return (
        <option key={index} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_name}
        </option>
      );
    });
  };


  // const getAccountDataByAmc = async (code:any) => {
  //   try {
  //     const response = await getAccountByAmc(email, code);
  //     let temp: any = '';
  //     temp = { bank_name: 'All', bank_code: 'All' };
  //     response.data.data.unshift(temp);
  //     setAllBanks(response.data.data);
  //   } catch (err: any) {
  //     if (err.response !== undefined) {
  //       toast.error(err.response.data.message);
  //     } else {
  //       toast.error(err.message)
  //     }
  //   }
  // }

  const getAccountData = async (codeAmc: any, Codefund: any) => {
    try {
      const response = await getAccountByFundAndAmcCode(email, Codefund, codeAmc);
      if (response.data.status === 200) {
        let temp: any = '';
        temp = { bank_name: 'All', bank_code: 'All' };
        response.data.data.unshift(temp);
        setAllBanks(response.data.data);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
  }

  const searchData = async () => {
    let flag = false;
    if (amcCode === '') {
      flag = true;
      setAmcError('required-border');
    }
    if (fundCode === '' || fundCode==="Select Fund" ) {
      flag = true;
      setFundError('required-border');
    }
    if (bankCode === '') {
      flag = true;
      setBankError('required-border');
    }
    if (flag === false) {
      try {
        setLoading(true);
        const response = await getBankSummary(email, amcCode, fundCode, bankCode);
        if (response.data.status === 200) {
          let bank_records = response.data.data;
          let total_Balance: any = 0;
          bank_records.map((item: any) => {
            let bName: any;
            // if (item.balance_amount === '' || item.balance_amount === 'NaN' || item.balance_amount === NaN || item.balance_amount === undefined) {
            //   item.balance_amount = '0'
            // }
            bankCompleteName.filter((elem) => {
              if (item.bank_name === elem.bank_code) {
                bName = elem.bank_name;
              }
            })
            item.bank_name = bName;
            total_Balance += parseFloat(isNumber(item.balance_amount) || '0');
          })
          setGetTotalBalance(total_Balance);
          setBankRecords(bank_records);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }

      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
        setLoading(false);
      }
    }
  }

  const customStyle = () => {
    if ((getTotalBalance.toString()).length <= 18) {
      return { fontSize: "10px" }
    } else {
      return { fontSize: "7.5px" }
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Bank Summary Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select

                            className={"form-control " + amcError}
                            onChange={(e) => {
                              allFunds.length = 0;
                              setAllFunds(allFunds);
                              // if (e.target.value === 'All') {

                              //   setFundCode('');
                              //   bankRecords.length = 0;
                              //   allBanks.length = 0;

                              //   setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              //   let temp: any;
                              //   temp = { fund_name: 'All', symbol_code: 'All' };

                              //   getFunds("All")

                              //   setAmcCode(e.target.value);
                              //   getAllBanksData();
                              // } else {
                                bankRecords.length = 0;
                                allBanks.length = 0;
                                setFundCode('');
                                setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                                getFunds(e.target.value);
                                setAmcCode(e.target.value);
                                getAccountData(e.target.value, '');
                             // }
                              setAmcError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className={"form-control " + fundError}

                            onChange={(e) => {
                              if (amcCode === 'All') {
                                bankRecords.length = 0;
                                allBanks.length = 0;
                                setBankCode('');
                                setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text);
                                getAllBanksData();
                                setFundCode(e.target.value);
                                setBankCode('');
                              } else {
                                if (e.target.value === 'All') {
                                  bankRecords.length = 0;
                                  allBanks.length = 0;
                                  setBankCode('');
                                  setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text);
                                  setFundCode(e.target.value);
                                  getAccountData(amcCode, '');
                                } else {
                                  bankRecords.length = 0;
                                  allBanks.length = 0;
                                  setBankCode('');
                                  setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                                  setFundCode(e.target.value);
                                  getAccountData(amcCode, e.target.value);
                                }
                              }
                              setFundError('');

                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select Bank Name</label>
                          <select
                            className={"form-control " + bankError}
                            onChange={(e) => {
                              bankRecords.length = 0;
                              setBankCode(e.target.value);
                              setBankName(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              setBankError('')
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Bank
                            </option>
                            {renderBankDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="0.5cm" scale={0.6} repeatHeaders={true} fileName={"Bank-Statement-" + bankCode + ".pdf"} ref={pdfExportComponent}>
                        {bankRecords.length > 0 ? (
                          <>
                            <ReportHeader title="Bank Summary" />
                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{Amc === '' ? 'All' : Amc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{fund === '' ? 'All' : fund}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{marginLeft:'80px'}}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                Bank:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{bankName}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col md="2"></Col>
                              <Col md="1">
                                <span className="text-nowrap pr-5" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap pl-2">{Amc === '' ? 'All' : Amc}</span>
                              </Col>
                              <Col md='2'></Col>
                              <Col md="1">
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Fund:</span>
                              </Col>
                              <Col md="2">
                                <span className="text-nowrap pl-2">{fund === '' ? 'All' : fund}</span>
                              </Col>
                              <Col md="2"></Col>
                              <Col md="2"></Col>
                              <Col md="1">
                                <span className="text-nowrap pr-5" style={{ fontWeight: 'bold' }}>
                                  Bank:
                                </span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap pl-2">{bankName}</span>
                              </Col>
                              <Col md='2'></Col>
                              <Col md="1">
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                              </Col>
                              <Col md="2">
                                <span className="text-nowrap pl-2">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md="2"></Col>
                            </Row> */}



                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              {/* <div className="float-right"><b>Opening Balance</b> : </div> */}
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr key={0} style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                                    <th>Amc</th>
                                    <th>Fund</th>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                                    <th>Account No</th>
                                    <th>Account Title</th>
                                    <th>City</th>
                                    <th className="text-right">Balance</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {bankRecords.map((item: any, index: number) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{item.amc_code}</td>
                                          <td>{item.fund_code}</td>
                                          <td>{item.bank_name}</td>
                                          <td>{item.branch_name}</td>
                                          <td>{item.account_no}</td>
                                          <td>{item.account_title}</td>
                                          <td>{item.bank_city}</td>
                                          <td className="text-right">{numberWithCommas(item.balance_amount)}</td>
                                        </tr>
                                      </>
                                    );
                                  })
                                  }
                                </tbody>
                                <tfoot >
                                  <tr>
                                    <th>Total:</th>
                                    <th ></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-right" style={customStyle()} >{numberWithCommas(parseFloat(getTotalBalance).toFixed(2))}</th>
                                  </tr>

                                </tfoot>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BankSummary;
